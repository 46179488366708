import React, { useMemo, useState } from 'react';
import groupRowStyles from './GroupRow.module.scss';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EmptyState from '../composites/EmptyState';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import SelectMembersDialog from './SelectMembersDialog';
import GroupRow from './GroupRow';
import ListContent from '../composites/ListContent';
import EditGroup from './EditGroup';
import {
  createNewGroup,
  removeGroup,
  updateGroupData,
  assignProgramToMembers,
  createNewProgram,
} from '../../api';
import {
  useLoginData,
  useRefreshState,
  useSelectedGymData,
} from '../../providers';

import Programs from '../Programs/Programs';

import type { Group, WithOptionalId, AssignedProgram } from '../../types';

const groupListTitles = ['Group Name', 'Members', 'Created By', 'Options'];

const getEmptyGroup = (trainerId: string) => ({
  name: '',
  memberIds: [],
  managerIds: [],
  trainerIds: [trainerId],
});

const Groups: React.FC = () => {
  const {
    groups,
    trainers: allGymTrainers,
    members: allGymMembers,
  } = useSelectedGymData();
  const refreshState = useRefreshState();
  const { trainerData } = useLoginData();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [editedGroup, setEditedGroup] = useState<WithOptionalId<Group> | null>(
    null,
  );
  const [editedProgram, setEditedProgram] =
    useState<WithOptionalId<AssignedProgram> | null>(null);
  const [isProgramsDrillInOpen, setIsProgramsDrillInOpen] = useState(false);
  const [isEditGroupDrillInOpen, setIsEditGroupDrillInOpen] = useState(false);
  const [isSelectMembersModalOpen, setIsSelectMembersModalOpen] =
    useState(false);

  const trainer = trainerData?.trainer;

  const filteredGroups = useMemo(() => {
    if (!groups) return [];
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return groups
      .filter(({ name }) => searchQueryRegex.test(name.toLowerCase()))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [searchQuery, groups]);

  if (!trainer) {
    return (
      <EmptyState
        icon={<GroupsOutlinedIcon />}
        text="No Groups for logged in role."
      />
    );
  }

  const renderEmptyState = () => (
    <EmptyState
      icon={<GroupsOutlinedIcon />}
      cta={{
        icon: <AddBoxOutlinedIcon color="primary" />,
        label: 'Add A Group',
        onClick: () => handleOpenEditGroupDrillIn(getEmptyGroup(trainer.id)),
      }}
      text="No Groups Have Been Added"
    />
  );

  const deleteGroup = async (group: Group) => {
    await removeGroup(group.id);
    await refreshState();
  };

  const handleEditGroup = async (
    currentGroupData: WithOptionalId<Group>,
    newGroupData: WithOptionalId<Group>,
  ) => {
    const { success, errorMessage } = newGroupData.id
      ? await updateGroupData(currentGroupData as Group, newGroupData as Group)
      : await createNewGroup(
          newGroupData,
          [trainer.id],
          newGroupData.memberIds,
        );
    if (!success) {
      return { success, errorMessage };
    }
    return { success: true };
  };

  const assignProgramToSelectedMembers = async (memberIds: string[]) => {
    if (!editedProgram || !editedGroup) {
      setIsSelectMembersModalOpen(false);
      setEditedProgram(null);
      setEditedGroup(null);
      await refreshState();
      return;
    }
    await assignProgramToMembers(memberIds, editedProgram as AssignedProgram);
    const updatedGroup = {
      ...editedGroup,
      program: editedProgram as AssignedProgram,
    };
    await handleEditGroup(editedGroup as Group, updatedGroup as Group);
    setIsSelectMembersModalOpen(false);
    setEditedProgram(null);
    setEditedGroup(null);
    await refreshState();
  };

  const handleCloseSelectMembersDialog = () => {
    setEditedProgram(null);
    setEditedGroup(null);
    setIsSelectMembersModalOpen(false);
  };

  const renderSelectMembersDialog = () => (
    <SelectMembersDialog
      onSubmit={assignProgramToSelectedMembers}
      isOpen={isSelectMembersModalOpen}
      onClose={handleCloseSelectMembersDialog}
      memberIds={editedGroup?.memberIds || []}
    />
  );

  const handleEditGroupProgram = async (group: Group) => {
    const currentProgram = group?.program;
    if (!currentProgram?.id) {
      return;
    } else {
      setEditedGroup(group);
      setEditedProgram(currentProgram);
      setIsProgramsDrillInOpen(true);
    }
  };

  const handleCreateGroupProgram = async (group: Group) => {
    setEditedGroup(group);
    setEditedProgram({
      id: undefined,
      name: '',
      trainerId: trainer.id,
      level: 0,
      fitnessGoal: '',
      programItems: [],
      isPrivate: true,
    });
    setIsProgramsDrillInOpen(true);
  };

  const handleSubmitEditedGroup = async (
    currentGroupData: WithOptionalId<Group>,
    newGroupData: WithOptionalId<Group>,
  ) => {
    const { success, errorMessage } = await handleEditGroup(
      currentGroupData,
      newGroupData,
    );
    await refreshState();
    if (success) {
      return { success: true };
    }
    return { success, errorMessage };
  };

  const handleOpenEditGroupDrillIn = (group: WithOptionalId<Group> | null) => {
    setEditedGroup(group);
    setIsEditGroupDrillInOpen(true);
  };

  const renderContent = () => {
    if (isEditGroupDrillInOpen || isProgramsDrillInOpen) return null;
    return (
      <ListContent
        items={filteredGroups}
        listHeaderClassName={groupRowStyles.listGrid}
        EmptyStateComponent={renderEmptyState}
        titles={groupListTitles}
        ItemComponent={({ data }) => (
          <GroupRow
            group={data}
            deleteGroup={deleteGroup}
            openEditGroupModal={() => handleOpenEditGroupDrillIn(data)}
            selected={selectedGroup === data.id}
            onSelect={() => setSelectedGroup(data.id)}
            key={data.id}
            trainers={allGymTrainers}
            onEditProgram={() => handleEditGroupProgram(data)}
            onCreateProgram={() => handleCreateGroupProgram(data)}
            onSubmitEditedGroup={handleSubmitEditedGroup}
          />
        )}
      />
    );
  };

  const handleSubmitEditedProgram = async (
    newProgramData: WithOptionalId<AssignedProgram>,
  ) => {
    const { success, errorMessage, content } = !newProgramData.id
      ? await createNewProgram(newProgramData, trainer.id)
      : { success: true, content: newProgramData, errorMessage: '' };
    if (!success) {
      return { success, errorMessage };
    } else if (content.id) {
      setEditedProgram(content);
      setIsProgramsDrillInOpen(false);
      setIsSelectMembersModalOpen(true);
    }
  };

  const handleCloseProgramsDrillIn = () => {
    setIsProgramsDrillInOpen(false);
  };

  const handleCloseEditGroupDrillIn = () => {
    setIsEditGroupDrillInOpen(false);
  };

  const getDrillInContent = () => {
    if (editedProgram && isProgramsDrillInOpen) {
      return (
        <Programs
          isEditMode={true}
          program={editedProgram}
          onClose={() => handleCloseProgramsDrillIn()}
          onSubmit={handleSubmitEditedProgram}
          isContentWrapped={true}
        />
      );
    } else if (editedGroup && isEditGroupDrillInOpen) {
      return (
        <EditGroup
          allGymTrainers={allGymTrainers}
          members={allGymMembers}
          onSubmit={(newGroupData) =>
            handleSubmitEditedGroup(editedGroup, newGroupData)
          }
          group={editedGroup}
          setGroup={
            setEditedGroup as React.Dispatch<
              React.SetStateAction<WithOptionalId<Group>>
            >
          }
          onClose={() => handleCloseEditGroupDrillIn()}
        />
      );
    }

    return null;
  };

  const handleHeaderBack = () => {
    setEditedProgram(null);
    setIsProgramsDrillInOpen(false);
    setEditedGroup(null);
    setIsEditGroupDrillInOpen(false);
  };

  return (
    <DashboardContentWrapper
      drillInContent={getDrillInContent()}
      searchPlaceholder="Search for Groups"
      title={editedGroup ? `Edit Group ${editedGroup.name}` : 'Group'}
      setSearchQuery={setSearchQuery}
      isEditMode={!!editedProgram}
      actions={[
        {
          icon: <AddBoxOutlinedIcon />,
          label: 'Add A Group',
          onClick: () => handleOpenEditGroupDrillIn(getEmptyGroup(trainer.id)),
        },
      ]}
      onBack={handleHeaderBack}
    >
      {renderContent()}
      {renderSelectMembersDialog()}
    </DashboardContentWrapper>
  );
};

export default Groups;
