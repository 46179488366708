export const locales = {
  en: {
    components: {
      workouts: {
        editTemplate: {
          titlePlaceholder: 'Input workout name',
          descriptionPlaceholder: 'Add Description Here...',
        },
        exerciseGroup: {
          superset: 'Superset',
          circuit: 'Circuit',
          exerciseGroupReorderDropZone: 'DROP HERE TO CHANGE ORDER OF EXERCISE',
          exerciseGroup: 'Exercise Group',
        },
        groupSet: {
          groupSetReorderDropZone: 'DROP HERE TO ADD EXERCISE TO SUPERSET',
        },
      },
      programs: {
        editProgram: {
          titlePlaceholder: 'Input program name',
        },
      },
    },
  },
};
