import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appState/stateReducer';
import {
  GlobalDataProvider,
  RefreshStateProvider,
  LoginDataProvider,
  SelectedGymDataProvider,
} from './providers';

const store = configureStore({
  reducer: appReducer,
});

export type AppState = ReturnType<typeof store.getState>;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SelectedGymDataProvider>
        <GlobalDataProvider>
          <LoginDataProvider>
            <RefreshStateProvider>
              <App />
            </RefreshStateProvider>
          </LoginDataProvider>
        </GlobalDataProvider>
      </SelectedGymDataProvider>
    </Provider>
  </React.StrictMode>,
);
