import React from 'react';
import { Menu as MuiMenu, MenuItem } from '@mui/material';
import styles from './Menu.module.scss';

interface MenuOption {
  onClick: () => void;
  label: string;
  icon?: React.ReactNode;
}

interface MenuProps {
  options: MenuOption[];
  anchorEl: HTMLElement | null;
  onClose: () => void;
  isOpen: boolean;
}

const Menu: React.FC<MenuProps> = ({ options, onClose, anchorEl, isOpen }) => {
  return (
    <MuiMenu
      className={styles.menu}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'options-button',
      }}
    >
      {options.map(({ onClick, icon, label }) => (
        <MenuItem
          key={`menuItem-${label}`}
          className={styles.menuItem}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {icon}
          <span className={styles.optionsMenuText}>{label}</span>
        </MenuItem>
      ))}
    </MuiMenu>
  );
};

export default Menu;
