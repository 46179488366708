import React from 'react';
import styles from './LoginPage.module.scss';
import { Typography } from '@mui/material';

export const LoginPageTitle: React.FC<{ text: string }> = ({ text }) => (
  <Typography
    className={styles.loginTitle}
    component="h1"
    variant="h5"
    sx={{
      fontWeight: 'bold',
      fontSize: '2rem',
      marginBottom: 1,
    }}
  >
    {text}
  </Typography>
);
