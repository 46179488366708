import { apiBaseUrl } from '../apiConsts';

const refundApiKey = 'Icp73mNDj6qsu9mjCrx85CGZwyOIaLULt/IIaZCz/3yA+5tw9Wv1/w==';

export const updateMemberSubscription = async (
  subscriptionId: string,
  status: string,
): Promise<void> => {
  return fetch(`${apiBaseUrl}payment/subscription`, {
    headers: { 'content-type': 'application/json' },
    method: 'PATCH',
    body: JSON.stringify({
      subscriptionId: subscriptionId,
      status: status,
    }),
  })
    .then((x) => x.json())
    .catch((e) => {
      console.error(e);
    });
};

export const refundSubscription = async (
  subscriptionId: string,
): Promise<void> => {
  return fetch(`${apiBaseUrl}payment/transaction/refund`, {
    headers: { 'content-type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({
      subscriptionId: subscriptionId,
      apiKey: refundApiKey,
    }),
  })
    .then((x) => x.json())
    .catch((e) => {
      console.error(e);
    });
};
