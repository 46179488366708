import React, { useState } from 'react';
import styles from './TemplateRow.module.scss';
import classNames from 'classnames';
import Menu from '../atoms/Menu';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { getFullName } from '../../utils/dashboardUtils';
import ConfirmationDialog from '../DialogWrappers/ConfirmationDialog';
import { getNumOfRoutineExercises } from '../../utils/dashboardUtils';
import ContentCopy from '@mui/icons-material/ContentCopy';

import type { TrainingRoutineTemplate, Trainer } from '../../types';

interface TemplateRowProps {
  template: TrainingRoutineTemplate;
  selected: boolean;
  onSelect?: () => void;
  trainer: Trainer | undefined;
  openEditTemplateModal?: () => void;
  duplicateTemplate?: (template: TrainingRoutineTemplate) => void;
  deleteTemplate?: (template: TrainingRoutineTemplate) => Promise<void>;
}

const TemplateRow: React.FC<TemplateRowProps> = ({
  template,
  trainer,
  onSelect,
  selected,
  deleteTemplate,
  duplicateTemplate,
  openEditTemplateModal,
}) => {
  const [
    isDeleteTemplateConfirmationOpen,
    setIsDeleteTemplateConfirmationOpen,
  ] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const getMenuItems = () => {
    const menuItems: Array<{
      onClick: () => void;
      label: string;
      icon: React.ReactNode;
    }> = [];
    if (openEditTemplateModal) {
      menuItems.push({
        onClick: () => {
          openEditTemplateModal();
          handleCloseMenuOptions();
        },
        label: 'Edit Workout Details',
        icon: <BorderColorOutlinedIcon color="primary" />,
      });
      menuItems.push({
        onClick: () => {
          handleCloseMenuOptions();
          duplicateTemplate?.(template);
        },
        label: 'Duplicate Workout',
        icon: <ContentCopy color="primary" />,
      });
    }
    if (deleteTemplate) {
      menuItems.push({
        onClick: () => setIsDeleteTemplateConfirmationOpen(true),
        label: 'Delete Workout',
        icon: <DeleteForeverOutlinedIcon color="primary" />,
      });
    }
    return menuItems;
  };

  const handleDeleteTemplate = async () => {
    handleCloseMenuOptions();
    await deleteTemplate?.(template);
    setIsDeleteTemplateConfirmationOpen(false);
  };

  const renderTemplateName = () => (
    <div
      className={classNames(styles.templateColumn, styles.nameAndDescription)}
    >
      <div className={styles.templateName}>{template.name}</div>
      {template.routine.description && (
        <div className={styles.templateDescription}>
          {template.routine.description}
        </div>
      )}
    </div>
  );
  const renderExercises = () => (
    <div className={styles.templateColumn}>
      {getNumOfRoutineExercises(template.routine)}
    </div>
  );

  const renderTrainerName = () => {
    const trainerName = getFullName(trainer);
    return (
      <div className={classNames(styles.templateColumn)}>{trainerName}</div>
    );
  };

  const renderDeleteTemplateConfirmationDialog = () => (
    <ConfirmationDialog
      text="Are you sure you want to delete this workout?"
      onSubmit={handleDeleteTemplate}
      submitText="Yes"
      cancelText="No"
      isOpen={isDeleteTemplateConfirmationOpen}
      onCancel={() => setIsDeleteTemplateConfirmationOpen(false)}
      symbol={<DeleteForeverOutlinedIcon />}
    />
  );

  const renderMoreOptions = () => (
    <div className={classNames(styles.templateColumn, styles.options)}>
      <PendingOutlinedIcon
        // @ts-ignore
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={styles.optionsBtn}
        color="primary"
      />
      <Menu
        anchorEl={anchorEl}
        isOpen={open}
        onClose={handleCloseMenuOptions}
        options={getMenuItems()}
      />
    </div>
  );

  return (
    <div
      onClick={onSelect}
      className={classNames(styles.templateRow, styles.listGrid, {
        [styles.selected]: selected,
      })}
    >
      {renderTemplateName()}
      {renderExercises()}
      {renderTrainerName()}
      {renderMoreOptions()}
      {renderDeleteTemplateConfirmationDialog()}
    </div>
  );
};

export default TemplateRow;
