import React, { useMemo, useState } from 'react';
import exerciseRowStyles from './ExerciseRow.module.scss';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EmptyState from '../composites/EmptyState';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import ExerciseRow from './ExerciseRow';
import {
  createGlobalExercise,
  createPersonalizedExercise,
  removeExercise,
  updateExerciseData,
} from '../../api';
import ListContent from '../composites/ListContent';
import BaseExerciseDialog from './BaseExerciseDialog';
import {
  getTrainerMergedExercises,
  isMotivisionTrainer,
} from '../../utils/dashboardUtils';
import {
  useGlobalData,
  useLoginData,
  useRefreshState,
  useSelectedGymData,
} from '../../providers';

import type { Exercise, WithOptionalId } from '../../types';

const exerciseListTitles = [
  'Exercise Name',
  'Equipment Type',
  'Muscle Group',
  'Movement Pattern',
  'Created By',
  'Options',
];

export interface AddExerciseModalData {
  baseExercise: Exercise | null;
  isCreatingPersonalizedExercise?: boolean;
}

const TrainerExercisesContent: React.FC = () => {
  const { trainers } = useSelectedGymData();
  const { exercises: globalExercises } = useGlobalData();
  const { trainerData } = useLoginData();
  const refreshState = useRefreshState();
  const trainerExercises = getTrainerMergedExercises(
    Object.values(globalExercises),
    trainerData?.personalizedExercises || [],
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedExercise, setSelectedExercise] = useState<string>('');
  const [addExerciseModalData, setAddExerciseModalData] =
    useState<AddExerciseModalData | null>(null);
  const [editedExercise, setEditedExercise] = useState<Exercise | null>(null);
  const trainer = trainerData?.trainer;
  const trainerId = trainer?.id;

  const filteredExercises = useMemo(() => {
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return trainerExercises
      .filter(({ name }) => searchQueryRegex.test(name.toLowerCase()))
      .sort((a, b) => {
        if (a.trainerId === trainerId && b.trainerId !== trainerId) return -1;
        if (a.trainerId !== trainerId && b.trainerId === trainerId) return 1;
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
  }, [searchQuery, trainerExercises, trainerId]);

  if (!trainer) {
    return (
      <EmptyState
        icon={<AccountCircleRoundedIcon />}
        text="No Exercises For Your Role"
      />
    );
  }

  const handleAddExercise = async (
    newExercise: WithOptionalId<Exercise>,
    options: AddExerciseModalData,
  ) => {
    if (newExercise.id) {
      return {
        success: false,
        errorMessage: 'Cannot create an existing exercise (id already exist).',
      };
    }
    if (
      options.baseExercise?.id &&
      options.isCreatingPersonalizedExercise &&
      !isMotivisionTrainer
    ) {
      newExercise.originalExerciseId = options.baseExercise.id;
    }
    const { success, errorMessage } = isMotivisionTrainer(trainer)
      ? await createGlobalExercise(newExercise)
      : await createPersonalizedExercise(newExercise);
    if (!success) {
      return { success, errorMessage };
    }
    await refreshState();
    setAddExerciseModalData(null);
    return { success: true };
  };

  const handleEditExercise = async (
    oldExerciseData: Exercise,
    newExerciseData: Exercise,
  ) => {
    const { success, errorMessage } = await updateExerciseData(
      oldExerciseData,
      newExerciseData,
    );
    if (!success) {
      return { success, errorMessage };
    }
    await refreshState();
    setAddExerciseModalData(null);
    return { success: true };
  };

  const renderAddExerciseDialog = () => {
    if (!addExerciseModalData) return null;
    return (
      <BaseExerciseDialog
        isPersonalized={!isMotivisionTrainer}
        title="Create An Exercise"
        trainerId={trainer.id}
        originalExercise={addExerciseModalData.baseExercise}
        onSubmit={({ id, ...newExercise }: WithOptionalId<Exercise>) =>
          handleAddExercise(newExercise, addExerciseModalData)
        }
        onClose={() => setAddExerciseModalData(null)}
        isOpen={Boolean(addExerciseModalData)}
      />
    );
  };

  const renderEditExerciseDialog = () => {
    if (!editedExercise?.id) return null;
    return (
      <BaseExerciseDialog
        isPersonalized={!isMotivisionTrainer}
        title="Edit Exercise"
        trainerId={trainer.id}
        originalExercise={editedExercise}
        onSubmit={(updatedExercise) =>
          handleEditExercise(editedExercise, {
            ...updatedExercise,
            id: editedExercise.id,
          })
        }
        onClose={() => setEditedExercise(null)}
        isOpen={!!editedExercise}
      />
    );
  };

  const deleteExercise = async (exercise: Exercise) => {
    await removeExercise(exercise.id);
    await refreshState();
  };

  const renderEmptyState = () => (
    <EmptyState
      icon={<AccountCircleRoundedIcon />}
      cta={{
        icon: <AddBoxOutlinedIcon color="primary" />,
        label: 'Add An Exercise',
        onClick: () => setAddExerciseModalData({ baseExercise: null }),
      }}
      text="No Exercises Have Been Added"
    />
  );

  if (!trainer) {
    return renderEmptyState();
  }

  const renderContent = () => {
    return (
      <ListContent
        items={filteredExercises}
        listHeaderClassName={exerciseRowStyles.listGrid}
        EmptyStateComponent={renderEmptyState}
        titles={exerciseListTitles}
        ItemComponent={({ data }) => (
          <ExerciseRow
            exercise={data}
            deleteExercise={deleteExercise}
            openEditExerciseModal={() => setEditedExercise(data)}
            selected={selectedExercise === data.id}
            onSelect={() => setSelectedExercise(data.id)}
            key={data.id}
            exerciseTrainer={trainers.find(({ id }) => data.trainerId === id)}
            addExerciseFromBaseExercise={(baseExercise) =>
              setAddExerciseModalData({
                baseExercise: baseExercise,
                isCreatingPersonalizedExercise: true,
              })
            }
          />
        )}
      />
    );
  };

  return (
    <DashboardContentWrapper
      searchPlaceholder="Search for Exercises"
      title="Exercise List"
      setSearchQuery={setSearchQuery}
      actions={[
        {
          icon: <AddBoxOutlinedIcon />,
          label: 'Add An Exercise',
          onClick: () => setAddExerciseModalData({ baseExercise: null }),
        },
      ]}
    >
      {renderContent()}
      {renderAddExerciseDialog()}
      {renderEditExerciseDialog()}
    </DashboardContentWrapper>
  );
};

export default TrainerExercisesContent;
