export const GOD_IDS = [
  'e3571eb6-a08e-495a-b3e3-3f84a19544df',
  '72f02087-b47d-43e6-89b0-5ba215180f12',
  '9b6d8213-b64d-4129-b348-3a8f8e7d29b3',
  'b178e927-be71-40fb-9c3d-7a96c4e9e10c',
  '392396aa-2ae1-4082-86f2-6c330f8c9299',
  '8f8b08ee-0cbb-4dbb-82d9-ad3b3b93a858',
  'd73dc1ea-5842-458d-9f47-370c1dd79ccd',
  '08fead3f-5cca-4d99-a782-101d3848980f',
  'dca7ca2c-27e8-4c88-ad57-42ea1ea811c1',
  'd73dc1ea-5842-458d-9f47-370c1dd79ccd',
  '392396aa-2ae1-4082-86f2-6c330f8c9299',
  '9b6d8213-b64d-4129-b348-3a8f8e7d29b3',
  'f86b426c-9f60-45d1-8698-c3306b04ea31',
  'e3571eb6-a08e-495a-b3e3-3f84a19544df',
];
