import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    info: {
      main: '#fa5723',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            color: '#ffffff',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ffffff',
            },
            '&:hover fieldset': {
              borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#ffffff',
            },
            '& input': {
              color: '#ffffff',
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            backgroundColor: '#252d3c',
            color: '#ffffff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&': {
            backgroundColor: '#fa5723',
            color: '#ffffff',
          },
          '&.Mui-disabled': {
            backgroundColor: '#D2D2D2', // Grey color
            color: '#000000', // Black color for contrast text
          },
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: 'info',
      },
    },
  },
});
