import {
  Gym,
  Exercise,
  Member,
  Trainer,
  Station,
  Muscle,
  TrainingRoutineTemplate,
  Manager,
  GymProduct,
  Program,
  Group,
} from '../types';

export interface TrainerStateData {
  trainer: Trainer;
  programs: Program[];
  templates: TrainingRoutineTemplate[];
  personalizedExercises: Exercise[];
}

export interface ManagerStateData {
  manager: Manager;
}

export interface MemberStateData {
  member: Member;
}

export interface GymDashboardData {
  gymProducts: GymProduct[];
  members: Member[];
  managers: Manager[];
  trainers: Trainer[];
  groups: Group[];
}

export interface AppState {
  relatedGyms: Gym[];
  selectedGymId: string;
  exercises: Record<string, Exercise>;
  mergedExercises: Record<string, Exercise>;
  templates: TrainingRoutineTemplate[];
  muscles: Record<string, Muscle>;
  stations: Record<string, Station>;
  loginMember: MemberStateData | null;
  loginTrainer: TrainerStateData | null;
  loginManager: ManagerStateData | null;
  isAppLoadingData: boolean;
  gymDataMap: Record<string, GymDashboardData>;
}

export const getAppInitialState = (): AppState => ({
  loginManager: null,
  selectedGymId: '',
  loginMember: null,
  loginTrainer: null,
  mergedExercises: {},
  exercises: {},
  stations: {},
  muscles: {},
  templates: [],
  relatedGyms: [],
  isAppLoadingData: false,
  gymDataMap: {},
});
