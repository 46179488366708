import _ from 'lodash';

export const storageKeys = {
  gym: '@gym-storage-key',
  gymId: '@gym-id-storage-key',
  station: '@station-storage-key',
  cameraRtsp: '@camera-rtsp-storage-key',
  serverIp: '@server-ip-storage-key',
  serverPort: '@server-port-storage-key',
  stationApproach: '@station-approach-storage-key',
};

export enum Status {
  Invalid = 'Invalid',
  Inactive = 'Inactive',
  Valid = 'Valid',
  Unplanned = 'Unplanned',
  Pending = 'Pending',
  Skipped = 'Skipped',
  Active = 'Active',
  Complete = 'Complete',
}

export const Messages = {
  loginStatus: {
    [Status.Valid]: 'Login Successful',
    [Status.Invalid]: 'Oops! Wrong PIN',
    [Status.Inactive]: 'In-Active User: Contact the Front Desk',
    [Status.Unplanned]: 'No workout planned',
    [Status.Pending]: '',
    [Status.Skipped]: '',
    [Status.Active]: '',
    [Status.Complete]: '',
  },
};

export const InputValidationStatus = {
  Invalid: 'Invalid',
  Exists: 'Exists',
};

export const APPLICATION_TEXTS = {
  staticLogoJoin: {
    title: 'Log Effort, See Gains',
    subtitle: 'Seamlessly track your workouts',
    buttonText: 'GENERATE YOUR PIN',
  },
  signUp: {
    title: 'Welcome to Motivision!',
    subtitle: 'Start tracking your workout progress',
    subtitleFromPin: 'Sign up to get your PIN for free',
    subtitleFromGetStarted: 'Sign up to try this workout for free',
    nameInputPlaceholder: 'Enter your name',
    firstNameInputPlaceholder: 'First Name',
    lastNameInputPlaceholder: 'Last Name',
    phoneInputPlaceholder: 'Enter your phone number',
    emailInputPlaceholder: 'Enter your email address',
    confirmEmailInputPlaceholder: 'Confirm email address',
    termsCheckbox: 'I agree to the Motivision Terms and Privacy Policy',
    trainerCheckbox:
      'I want a gymname... trainer to follow-up on my workout progress',
    signUpButton: 'Generate PIN',
    nameInputErrorMessage: 'Invalid name',
    firstNameInputErrorMessage: 'Invalid name',
    lastNameInputErrorMessage: 'Invalid name',
    phoneInputExistsErrorMessage: 'Phone number already exists',
    phoneInputInvalidErrorMessage: 'Phone number Invalid',
    emailInputExistsErrorMessage: 'Email already exists',
    emailInputInvalidErrorMessage: 'Email Invalid',
    confirmEmailInputErrorMessage: `Confirmation doesn't match`,
  },
  newPinExposer: {
    title: 'PIN Successfully Generated!',
    subtitle: 'Check your email for your login PIN',
    buttonText: 'Go To PIN Screen',
  },
  exercisePicker: {
    title: 'Select an Exercise to Track',
  },
  entrance: {
    firstTimePreferences: {
      nextButton: 'NEXT',
      startButton: 'START NOW',
      product: {
        title: 'Welcome to Motivision!',
        subtitle: 'How can we help you?',
        firstButtonTitle: 'Track My Progress',
        firstButtonSubtitle:
          'Add exercises and sets to log weights and reps, allowing you to monitor and visualize your fitness progress over time.',
        secondButtonTitle: 'Access Unlimited Guided Workouts',
        secondButtonSubtitle:
          'Access an unlimited library of workouts suitable for any fitness goal or level, giving you a variety of options to choose from.',
        thirdButtonTitle: 'Get a Personalized Workout Plan',
        thirdButtonSubtitle:
          'Receive a tailored workout program designed by a trainer, customized to meet your specific fitness needs and goals.',
      },
      fitnessGoal: {
        title: 'Perfect, lets get you started!',
        subtitle: 'What’s your fitness goal?',
        firstButtonTitle: 'Build Muscle',
        firstButtonSubtitle:
          'Ideal for increasing muscle mass and strength with intensive resistance training.',
        secondButtonTitle: 'Lean and Tone',
        secondButtonSubtitle:
          'Focused on sculpting the body to enhance muscle definition and achieve a leaner look.',
        thirdButtonTitle: 'Lose Weight',
        thirdButtonSubtitle:
          'Aimed at reducing body weight through a combination of calorie-burning workouts and aerobic exercises.',
      },
      level: {
        title: 'One Last Step Before We Begin',
        subtitle: 'What’s your fitness level?',
        firstButtonTitle: 'Beginner',
        firstButtonSubtitle:
          'I am new to exercising and need workouts tailored to help me learn the basics and build fitness at a comfortable pace.',
        secondButtonTitle: 'Intermediate',
        secondButtonSubtitle:
          'I exercise regularly and am looking for a more challenging workouts to develop my strength and endurance further.',
        thirdButtonTitle: 'Advanced',
        thirdButtonSubtitle:
          'I am an experienced exerciser seeking high-intensity workouts to maximize my athletic performance and push my physical limits.',
      },
    },
    pin: {
      title: 'Enter your user PIN',
      titleAfterSignUp: 'Check your email for your login PIN',
      adminCodeTitle: 'Enter Admin Code',
      adminOverrideCodeTitle: 'Enter Admin Override Code',
    },
    getStarted: {
      title: 'Get Started Now',
      buttonText: 'TRY WORKOUT',
    },
    StaticLogoActionable: {
      subtitle: 'Powered by Motivision',
      buttonText: 'LOG IN',
    },
  },
  home: {
    programmed: 'Programmed for you',
    library: 'Choose from library',
    tracking: 'Track your session',
    modals: {
      firstTime: {
        homeProgrammed: {
          title: 'Welcome to Motivision!',
          subtitlePart1: 'In this section you’ll find the workout routines ',
          subtitlePart2: ' programmed especially for you.',
          description:
            'In addition you can freely access our workout library and tracking sections.',
          buttonText: 'Start Now',
        },
        templateLibrary: {
          title: 'Welcome to Motivision!',
          subtitle:
            'All-in-one tracking, programming and coaching app to help you achieve your fitness goals!',
          buttonText: 'Start Now',
        },
        trackSession: {
          title: 'Track Your Workouts Seamlessly',
          subtitle:
            'Easily add exercises and sets from any device on the gym floor to track your progress. Happy training!',
          buttonText: 'Got it!',
        },
      },
      payment: {
        homeProgrammed: {
          title: 'Unlock Personalized Programming!',
          subtitle: `Get exercises, reps, sets and weights custom-fit to you and your goals.

          Learn more and subscribe for a tailored workout plan.`,
        },
        templateLibraryForFreeTrialEnded: {
          title: 'Unlock Unlimited Workouts!',
          subtitle: `You’ve reached the free workout limit. Use the 
          QR code below to unlock unlimited workouts.`,
        },
        trackSessionForFreeTrialEnded: {
          title: 'Unlock Unlimited Tracking!',
          subtitle: `You’ve reached the free workout limit. Use the 
          QR code below to unlock unlimited tracking.`,
        },
        templateLibrary: {
          title: 'Unlock Unlimited Workouts!',
          subtitle: `Gain access to an unlimited workouts library. Use the 
          QR code below to unlock unlimited workouts.`,
        },
        trackSession: {
          title: 'Unlock Unlimited Tracking!',
          subtitle: `Gain access to unlimited workouts tracking. Use the 
          QR code below to unlock unlimited tracking.`,
        },
      },
      paymentLoading: {
        title: 'Waiting for you to complete payment',
        subtitle: `Once you complete your payment we’ll automatically
        unlock the workout library section for you!`,
      },
    },
  },
  homeProgrammed: {
    title: `Today's Workout`,
    subtitlePart1: 'Created by ',
    subtitlePart2: ' especially for you',
    viewWorkoutButton: 'View Workout',
    descriptionSubTitle: 'By ',
  },
  drawerProgressChart: {
    title: 'Max Volume',
    subtitle: 'Last 10 logged sets',
    format: 'lb',
    footerText: 'Most Recent Performances',
  },
  modals: {
    weighInModal: {
      title: 'Weekly Weigh-In',
      leftButton: 'Skip for Today',
      rightButton: 'Done',
      format: 'lb',
    },
    firstTimeQRModal: {
      title: 'Your First Workout is Ready!',
      subtitle:
        'Before we start - claim your free consultation with a trainer by scanning the QR code below',
      subtitleP1: 'Before we start - claim your free consultation with a',
      subtitleP2: 'trainer by scanning the QR code below',
      button: 'Exit and view workout',
    },
    weighInConsentModal: {
      title: 'Weekly Weigh-In',
      subtitle: `This enables informed adjustments to your fitness 
      routines and tracking overall progress.`,
      topButton: 'I’m in!',
      bottomButton: 'Please Don’t Ask Me For This',
    },
  },
};

export enum ExertionRate {
  EASY = 'EASY',
  HARD = 'HARD',
}

export enum ScreenRoutes {
  Home = 'Home',
  Entrance = 'Entrance',
  Workout = 'Workout',
  GymInstallations = 'GymInstallations',
  TapToStart = 'TapToStart',
  TestScreen = 'TestScreen',
}

export const TRAINING_LEVELS = ['Beginner', 'Intermediate', 'Advanced'];

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export const hardcodedDescriptions = [
  'Stay mindful during your workout. Pay attention to your form, breathing, and body signals to optimize each exercise.',
  'Take breaks as needed. Rest is a valuable tool to prevent burnout and keep your energy levels steady throughout your session.',
  "Listen to your body's cues. If something doesn't feel right, it's okay to adjust or modify the exercise to ensure safety.",
  'Control your breathing. Deep and rhythmic breaths can help you stay focused and maintain endurance during your workout.',
  'Challenge yourself but stay within your limits. Gradually progress to heavier weights or higher intensity to build confidence over time.',
  'Use proper technique to avoid injury and maximize results. Quality repetitions are more effective than quantity.',
  'Stay hydrated. Sip water as needed to stay refreshed and maintain peak performance.',
  'Keep a positive mindset. Self-doubt can hinder progress, so stay confident and believe in your abilities.',
  'Stay present. Your workout is an opportunity to connect with your body and build self-assurance through action.',
  'Remember, consistency is key. Regular workouts build confidence through the gradual improvement of strength and endurance.',
];

export const FitnessGoalOptions = [
  { label: 'Build Muscle', value: 'buildMuscle' },
  { label: 'Lean and Tone', value: 'leanAndTone' },
  { label: 'Lose Weight', value: 'loseWeight' },
];

export const fitnessGoalValueToLabelMap = {
  buildMuscle: 'Build Muscle',
  leanAndTone: 'Lean and Tone',
  loseWeight: 'Lose Weight',
};

export const gymsSubTitle = 'Powered by Motivision';

export const totalWeights = 400 / 0.5; // Total number of weights we want
export const bodyWeightOptions: number[] = Array.from({
  length: totalWeights,
}).reduce((acc: number[], _, index) => {
  acc.push(0.5 * (index + 1));
  return acc;
}, []);

export const repsOptions = Array.from({ length: 100 }).reduce(
  (acc: number[], _, index) => {
    acc.push(index + 1);
    return acc;
  },
  [],
);

const exerciseWeightLimit = 300;
const hardCodedExerciseWeightOptions = [7];
export const exerciseWeightOptions: number[] = [
  ...hardCodedExerciseWeightOptions,
  ...Array.from({
    length: exerciseWeightLimit / 5 + 1,
  }).reduce((acc: number[], _, index) => {
    acc.push(index * 5);
    return acc;
  }, []),
].sort((a, b) => a - b);

export const GOD_KEYS = {
  ENTER_ADMIN_MODE_PIN: '9999',
  RESET_APP_PIN: '0000',
};

export const GOD_ADMIN_KEYS = {
  ADMIN_GOD_1: '0611',
  ADMIN_GOD_2: '2905',
  ADMIN_GOD_3: '1305',
};

export enum APPROACH_TYPES {
  AUTOMATIC = 'Automatic',
  MANUAL = 'Manual',
}

export enum InstallationSteps {
  gym = 'gym',
  station = 'station',
  stationType = 'stationType',
  loading = 'loading',
  done = 'done',
}

export enum EMAIL_TEMPLATE {
  E2_NEW_TRACKING_MEMBER = 'E2_newTrackingMember',
  E4_FIRST_TRACKING_MEMBER_COMPLETE_WORKOUT = 'E4_firstTrackingMemberCompleteWorkout',
  E5_FIRST_GUIDED_MEMBER_COMPLETE_WORKOUT = 'E5_firstGuidedMemberCompleteWorkout',
  E6_COMPLETE_TRACKING_WORKOUT = 'E6_completeTrackingWorkout',
  E7_COMPLETE_GUIDED_WORKOUT = 'E7_completeGuidedWorkout',
}

export const EmailSubjects: Record<EMAIL_TEMPLATE, string> = {
  [EMAIL_TEMPLATE.E2_NEW_TRACKING_MEMBER]: 'Welcome to Motivision!',
  [EMAIL_TEMPLATE.E4_FIRST_TRACKING_MEMBER_COMPLETE_WORKOUT]:
    'Congrats on your First Motivision Workout!',
  [EMAIL_TEMPLATE.E5_FIRST_GUIDED_MEMBER_COMPLETE_WORKOUT]:
    'Congrats on your First Motivision Workout!',
  [EMAIL_TEMPLATE.E6_COMPLETE_TRACKING_WORKOUT]: 'Motivision Workout Summary',
  [EMAIL_TEMPLATE.E7_COMPLETE_GUIDED_WORKOUT]: 'Motivision Workout Summary',
};

export const MOTIVISION_LINK = 'https://www.motivision.us/members-app';

export enum HomeContentRoute {
  ProgrammedWorkout = 'Programmed for you',
  Library = 'Choose from library',
  Tracking = 'Track your session',
}

export enum FirstTimePreferencesStep {
  Product = 'Product',
  FitnessGoal = 'FintessGoal',
  Level = 'Level',
}

export enum Product {
  Tracking = 'Tracking',
  Library = 'Library',
  Programmed = 'Programmed',
}

export enum GymType {
  School = 'School',
}

export enum SubscriptionSource {
  Gym = 'Gym',
  Motivision = 'Motivision',
}

export enum Currency {
  USD = 'USD',
}

export const routeToProductMap: Record<HomeContentRoute, Product> = {
  [HomeContentRoute.ProgrammedWorkout]: Product.Programmed,
  [HomeContentRoute.Library]: Product.Library,
  [HomeContentRoute.Tracking]: Product.Tracking,
};

export const productToRouteMap: Record<Product, HomeContentRoute> = _.invert(
  routeToProductMap,
) as Record<Product, HomeContentRoute>;

export enum PaymentStatus {
  Initialize = 'Initialize',
  TransactionApproved = 'TransactionApproved',
  TransactionDeclined = 'TransactionDeclined',
  TransactionRefund = 'TransactionRefund',
  TransactionFailed = 'TransactionFailed',
  TransactionTimeout = 'TransactionTimeout',
  TransactionCancel = 'TransactionCancel',
}

export const MOTIVISION_GYM_ID = 'ef107691-29d7-4184-8513-d3abea1415e8';

export const MOTIVISION_TRAINERS_IDS: string[] = [
  // '980f5e70-74d1-4af3-bdda-37c95b008371', // Tess
  '4f578312-6598-45ee-8456-4720891fe881', // Bianca
  'cfa5853c-e88c-415d-9fc2-09b04cece66c', // Matt Lupi
  'ff6a931a-7002-4aed-a858-79a1d782e340', // Wu Gulliver
];

export const MOTIVISION_TRAINERS_IDS_TO_FILTER_OUT: string[] = [
  '7d52fdb9-2dbc-4f25-a91a-4be5430cdf2a', // templatesTrainer@motivision.us
  '37e47300-3109-4e69-856b-65ef944c6f2f', // demoTrainer@motivision.us
  'f72233a2-8361-43f2-a098-f4b6f8ee433a', // seanmartinob@gmail.com
  'f7274bed-1337-41e9-a2e1-148fea750faa', // neta.gindes@gmail.com
  'e3571eb6-a08e-495a-b3e3-3f84a19544df', // omri.melamed@gmail.com
  '9b6d8213-b64d-4129-b348-3a8f8e7d29b3', // noam
  '6df90155-f400-4fc0-b4f1-145da391f76f', // tamir
  '4f2163f4-195c-4a5c-bee6-bf25f356140f', // Yinon
];

export const MOTIVISION_DEFAULT_LOGO_URL =
  'https://static.wixstatic.com/media/cf9e47_80691dd3ad7c41eab113ffb064921c0d~mv2.png';

export const workoutCategoriesMap: Record<string, string> = {
  fullBody1: 'Full Body',
  'Full Body 2': 'Full Body 2',
  'Full Body 3': 'Full Body 3',
  'Full Body 5': 'Full Body 4',
  fullBody5: 'Full Body 5',
  pull: 'Pull',
  push: 'Push',
  upperBody: 'Upper Body',
  lowerBody: 'Lower Body',
  upperBodyPush: 'Upper Body Push',
  upperBodyPull: 'Upper Body Pull',
  legDay: 'Leg Day',
  mobility: 'Mobility',
  cardio: 'Cardio',
  core: 'Core',
};

export const GYMS_TO_FREE_TRAIAL_FOR_LIBRARY_ON_TRACKING_SUBSCRIPTION = [
  '8e130fe0-64ff-4c14-b7db-932e244f6dd9',
  'ef107691-29d7-4184-8513-d3abea1415e8',
];

export enum UserFilters {
  ThisStation = 'This Station',
  AllExercises = 'All Exercises',
  MyExercises = 'My Exercises',
}

export enum MovementPatterns {
  LowerBodyPush = 'Lower Body Push',
  VerticalPull = 'Vertical Pull',
  HorizontalPush = 'Horizontal Push',
  VerticalPush = 'Vertical Push',
  Compound = 'Compound',
  Cardio = 'Cardio',
  Squat = 'Squat',
  HorizontalPull = 'Horizontal Pull',
  Hinge = 'Hinge',
  LowerBodyPull = 'Lower Body Pull',
  DynamicExtension = 'Dynamic Extension',
  Core = 'Core',
  Row = 'Row',
  Mobility = 'Mobility',
  Isometric = 'Isometric',
  Lunge = 'Lunge',
  Balance = 'Balance',
  Deadlift = 'Deadlift',
  VericalPush = 'Vertical Push', // Corrected typo
  HipAbduction = 'Hip Abduction',
  HipAdduction = 'Hip Adduction',
  UpperBodyPull = 'Upper Body Pull',
  Abdominals = 'Abdominals',
}

export enum UserType {
  Client = 'Client',
  Athlete = 'Athlete',
}

export enum Day {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}