import _ from 'lodash';
import { apiBaseUrl } from '../apiConsts';

import type { Group, WithOptionalId } from '../../types';
import type { BaseApiResponse } from '../apiTypes';

export const createNewGroup = async (
  group: WithOptionalId<Group>,
  trainerIds: string[],
  memberIds: string[],
): Promise<BaseApiResponse> => {
  try {
    const createdGroup = await fetch(`${apiBaseUrl}groups`, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(group),
    }).then((x) => x.json());

    await fetch(`${apiBaseUrl}groups/${createdGroup.id}/associate/trainers`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(trainerIds),
    });

    await fetch(`${apiBaseUrl}groups/${createdGroup.id}/associate/members`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(memberIds),
    });

    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    const errorMessage = `Error in createNewGroup: ${e?.message}`;
    console.error(errorMessage);
    return { success: false, errorMessage };
  }
};

const unassociateToGroup = async (
  groupId: string,
  tableToRef: string,
  ids: string[],
) => {
  await fetch(`${apiBaseUrl}groups/${groupId}/unassociate/${tableToRef}`, {
    headers: { 'content-type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(ids),
  });
};

const associateToGroup = async (
  groupId: string,
  tableToRef: string,
  ids: string[],
) => {
  await fetch(`${apiBaseUrl}groups/${groupId}/associate/${tableToRef}`, {
    headers: { 'content-type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(ids),
  });
};

export const updateGroupData = async (
  oldGroupData: Group,
  newGroupData: Group,
): Promise<BaseApiResponse> => {
  const groupId = newGroupData.id;

  try {
    await fetch(`${apiBaseUrl}groups`, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(newGroupData),
    });

    const trainerIdsToUnassociate = _.difference(
      oldGroupData.trainerIds,
      newGroupData.trainerIds,
    );
    const trainerIdsToAssociate = _.difference(
      newGroupData.trainerIds,
      oldGroupData.trainerIds,
    );
    const memberIdsToUnassociate = _.difference(
      oldGroupData.memberIds,
      newGroupData.memberIds,
    );
    const memberIdsToAssociate = _.difference(
      newGroupData.memberIds,
      oldGroupData.memberIds,
    );
    const managerIdsToUnassociate = _.difference(
      oldGroupData.managerIds,
      newGroupData.managerIds,
    );
    const managerIdsToAssociate = _.difference(
      newGroupData.managerIds,
      oldGroupData.managerIds,
    );

    if (trainerIdsToUnassociate.length > 0) {
      await unassociateToGroup(groupId, 'trainers', trainerIdsToUnassociate);
    }
    if (trainerIdsToAssociate.length > 0) {
      await associateToGroup(groupId, 'trainers', trainerIdsToAssociate);
    }
    if (memberIdsToUnassociate.length > 0) {
      await unassociateToGroup(groupId, 'members', memberIdsToUnassociate);
    }
    if (memberIdsToAssociate.length > 0) {
      await associateToGroup(groupId, 'members', memberIdsToAssociate);
    }
    if (managerIdsToUnassociate.length > 0) {
      await unassociateToGroup(groupId, 'managers', managerIdsToUnassociate);
    }
    if (managerIdsToAssociate.length > 0) {
      await associateToGroup(groupId, 'managers', managerIdsToAssociate);
    }

    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    const errorMessage = `Error in updateGroupData: ${e?.message}`;
    console.error(errorMessage);
    return { success: false, errorMessage };
  }
};

export const removeGroup = async (
  groupId: string,
): Promise<BaseApiResponse> => {
  if (!groupId)
    return {
      success: false,
      errorMessage: 'Group not found',
    };
  try {
    await fetch(`${apiBaseUrl}groups/${groupId}`, {
      method: 'DELETE',
    });
    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    const errorMessage = `Error in removeGroup: ${e?.message}`;
    console.error(errorMessage);
    return { success: false, errorMessage };
  }
};

export const fetchGroupsByIds = async (
  groupIds: string[],
): Promise<Group[]> => {
  try {
    if (groupIds.length === 0) return [];
    const response = await fetch(
      `${apiBaseUrl}groups/getByIds?page=0&size=1000&sort=name`,
      {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(groupIds),
      },
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const { content } = await response.json();
    return content;
  } catch (error) {
    console.error('Error in fetchGroupsByIds:', error);
    return [];
  }
};
