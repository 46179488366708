import {
  isExercise,
  isGym,
  isMember,
  isTrainer,
  isMuscle,
  isStation,
  isTemplate,
  isTrainingRoutineSession,
  isGymProduct,
  isManager,
  isProgram,
} from '../utils/typeUtils';

export const isValidGymMembersResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isMember(content[0]);
};

export const isValidGymTrainersResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isTrainer(content[0]);
};

export const isValidGymProductResponse = (data: any): boolean => {
  if (!Array.isArray(data)) return false;
  if (data.length === 0) return true;
  return isGymProduct(data[0]);
};

export const isValidMuscleGroupResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isMuscle(content[0]);
};

export const isValidMemberResponse = (data: any): boolean => {
  return isMember(data);
};

export const isValidTrainerResponse = (data: any): boolean => {
  return isTrainer(data);
};

export const isValidManagerResponse = (data: any): boolean => {
  return isManager(data);
};

export const isValidManagersResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isManager(content[0]);
};

export const isValidStationsResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isStation(content[0]);
};

export const isValidTemplateResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isTemplate(content[0]);
};

export const isValidProgramResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isProgram(content[0]);
};

export const isValidExercisesResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isExercise(content[0]);
};

export const isValidGymsResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isGym(content[0]);
};

export const isValidTrainingRoutineSessionsResponse = (data: any): boolean => {
  const { content } = data || {};
  if (!Array.isArray(content)) return false;
  if (content.length === 0) return true;
  return isTrainingRoutineSession(content[0]);
};
