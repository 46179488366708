import React, { useState } from 'react';
import { Button, DialogContent } from '@mui/material';
import styles from './AssignProgramOptionsDialog.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

export interface AssignProgramOptionsDialogProps {
  onClose: () => void;
  onCreate: () => void;
  onAdd: () => void;
}

enum Option {
  Create = 'Create',
  Add = 'Add',
}

enum OptionLabels {
  Create = 'Create Program from Scratch',
  Add = 'Add Program from Template',
}

enum ButtonLabels {
  Create = 'Create Program',
  Add = 'Add Existing Program',
}

const AssignProgramOptionsDialog: React.FC<AssignProgramOptionsDialogProps> = ({
  onClose,
  onCreate,
  onAdd,
}) => {
  const [option, setOption] = useState<Option | null>(null);

  const renderOption = (buttonOption: Option) => {
    const getOptionLabel = (buttonOption: Option) => {
      switch (buttonOption) {
        case Option.Create:
          return OptionLabels.Create;
        case Option.Add:
          return OptionLabels.Add;
        default:
          return 'Select Option';
      }
    };

    const getOptionIcon = (buttonOption: Option) => {
      const getIconStyle = (buttonOption: Option) => {
        const differentStyle =
          option === buttonOption
            ? {
                color: 'var(--info-color)',
                fontSize: '3rem',
              }
            : { color: '#D2D2D2', fontSize: '2rem' };
        const style = {
          ...differentStyle,
          marginBottom: '1rem',
        };
        return style;
      };
      switch (buttonOption) {
        case Option.Create:
          return <AddCircleOutlineIcon style={getIconStyle(buttonOption)} />;

        case Option.Add:
          return <CorporateFareIcon style={getIconStyle(buttonOption)} />;

        default:
          return null;
      }
    };

    const getOptionStyle = (buttonOption: Option) => {
      return option === buttonOption
        ? {
            border: '1px solid var(--info-color) !important',
          }
        : {};
    };

    const getOptionTextStyle = (buttonOption: Option) => {
      return option === buttonOption
        ? {}
        : {
            color: '#D2D2D2',
            opacity: 0.7,
          };
    };

    return (
      <Button
        className={styles.option}
        style={getOptionStyle(buttonOption)}
        onClick={() => setOption(buttonOption)}
      >
        {getOptionIcon(buttonOption)}
        <span
          className={styles.optionText}
          style={getOptionTextStyle(buttonOption)}
        >
          {getOptionLabel(buttonOption)}
        </span>
      </Button>
    );
  };

  const renderSubmitButton = () => {
    const getSubmitButtonLabel = () => {
      switch (option) {
        case Option.Create:
          return ButtonLabels.Create;
        case Option.Add:
          return ButtonLabels.Add;
        default:
          return 'Select Option';
      }
    };
    return (
      <Button
        disabled={!option}
        className={styles.submit}
        onClick={() => {
          switch (option) {
            case Option.Create:
              onCreate();
              break;
            case Option.Add:
              onAdd();
              break;
            default:
              return;
          }
        }}
      >
        <span className={styles.submitText}>{getSubmitButtonLabel()}</span>
      </Button>
    );
  };

  return (
    <BaseDialog
      onClose={onClose}
      isOpen={true}
      title="Add a Program"
      actions={renderSubmitButton()}
    >
      <DialogContent className={styles.assignProgramOptionsDialog}>
        {renderOption(Option.Create)}
        {renderOption(Option.Add)}
      </DialogContent>
    </BaseDialog>
  );
};

export default AssignProgramOptionsDialog;
