import React from 'react';
import styles from './EmptyState.module.scss';
import { Button } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

export interface EmptyStateProps {
  text: string;
  description?: string;
  icon?: React.ReactNode;
  cta?: {
    onClick: () => void;
    label: string;
    icon?: React.ReactNode;
  };
}

const EmptyState: React.FC<EmptyStateProps> = ({
  text,
  icon,
  cta,
  description,
}) => {
  return (
    <div className={styles.emptyStateContainer}>
      <div className={styles.iconContainer}>{icon}</div>
      <span className={styles.emptyStateText}>{text}</span>
      {description && (
        <span className={styles.emptyStateDescription}>{description}</span>
      )}
      {cta && (
        <Button className={styles.action} onClick={cta.onClick}>
          <>
            {cta.icon && <AddBoxOutlinedIcon color="primary" />}
            <span className={styles.actionText}>{cta.label}</span>
          </>
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
