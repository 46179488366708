import React, { useState, useMemo, useEffect } from 'react';
import { Button, DialogContent } from '@mui/material';
import styles from './SelectMembersDialog.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import SearchBar from '../atoms/SearchBar';
import MultiSelectList from '../composites/MultiSelectList';
import { getFormalDateString, getFullName } from '../../utils/dashboardUtils';
import { useSelectedGymData } from '../../providers';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import type { Member } from '../../types';

export interface SelectMembersDialogProps {
  memberIds: string[];
  isOpen: boolean;
  onSubmit: (memberIds: string[]) => void;
  onClose: () => void;
}

const SelectMembersDialog: React.FC<SelectMembersDialogProps> = ({
  onClose,
  onSubmit,
  memberIds,
  isOpen,
}) => {
  const { members, trainers } = useSelectedGymData();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredMembers = useMemo(() => {
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return members
      .filter((member) =>
        searchQueryRegex.test(getFullName(member).toLowerCase()),
      )
      .filter((member) => memberIds.includes(member.id))
      .sort(
        (a, b) =>
          a.firstName
            ?.toLowerCase()
            .localeCompare(b.firstName?.toLowerCase() || '') || -1,
      );
  }, [searchQuery, members, memberIds]);

  const [selectedMembers, setSelectedMembers] =
    useState<Member[]>(filteredMembers);

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
    }
  }, [isOpen]);

  return (
    <BaseDialog
      onClose={onClose}
      isOpen={isOpen}
      title="Assign Program to All Group Members"
      actions={
        <Button
          className={styles.submit}
          onClick={() => onSubmit(selectedMembers.map(({ id }) => id))}
        >
          <span className={styles.submitText}>Assign Program</span>
        </Button>
      }
    >
      <DialogContent className={styles.selectMembersDialog}>
        <SearchBar
          className={styles.search}
          placeholder="Search Members"
          setSearchQuery={setSearchQuery}
        />
        <MultiSelectList
          listClassName={styles.list}
          items={filteredMembers}
          selectedItems={selectedMembers}
          setSelectedItems={setSelectedMembers}
          EmptyStateComponent={() => null}
          ItemComponent={({ data }) => (
            <div className={styles.memberMultiSelectItem}>
              <div className={styles.memberNameWrapper}>
                <PersonOutlineOutlinedIcon color="primary" />
                <div className={styles.memberName}>{getFullName(data)}</div>
              </div>
              <div>
                {`${data.createdDate ? getFormalDateString(new Date(data.createdDate)) : ''}`}
              </div>
              <div>
                {getFullName(
                  trainers.find(({ id }) => id === data.trainerIds[0]),
                )}
              </div>
            </div>
          )}
        />
      </DialogContent>
    </BaseDialog>
  );
};

export default SelectMembersDialog;
