import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import styles from './ConfirmationDialog.module.scss';

export interface BaseDialogProps {
  onSubmit: () => Promise<void> | void;
  submitText: string;
  isOpen: boolean;
  text: string;
  onCancel: () => void;
  cancelText: string;
  symbol?: React.ReactNode;
}

const AddClientDialog: React.FC<BaseDialogProps> = ({
  text,
  submitText,
  onSubmit,
  isOpen,
  cancelText,
  onCancel,
  symbol,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };
  return (
    <Dialog
      className={styles.confirmationDialog}
      open={isOpen}
      onClose={onCancel}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.dialogHeader}>
        <HighlightOffRoundedIcon
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
          className={styles.closeDialog}
          color="primary"
        />
      </div>
      <DialogContent className={styles.dialogContent}>
        {symbol}
        <span className={styles.dialogText}>{text}</span>
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button
          className={styles.submit}
          onClick={(e) => {
            e.stopPropagation();
            handleSubmit();
          }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            <span className={styles.submitText}>{submitText}</span>
          )}
        </Button>
        <Button
          className={styles.cancel}
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
          disabled={loading}
        >
          <span className={styles.cancelText}>{cancelText}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddClientDialog;
