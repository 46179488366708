import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import styles from './AddManagerDialog.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import { isValidEmail } from '../../utils/utils';
import InputField from '../atoms/InputField';
import { useSelectedGymData } from '../../providers';

import type { Manager, WithOptionalId } from '../../types';

export interface AddManagerDialogProps {
  onClose: () => void;
  onSubmit: (
    newManager: WithOptionalId<Manager>,
  ) => Promise<{ success: boolean; errorMessage?: string }>;
  isOpen: boolean;
}

const initialManagerData = {
  firstName: '',
  lastName: '',
  email: '',
  emailVerification: '',
  subscription: '',
};

const AddManagerDialog: React.FC<AddManagerDialogProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const { selectedGymId } = useSelectedGymData();
  const [managerData, setManagerData] = useState(initialManagerData);

  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailVerificationError, setEmailVerificationError] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setManagerData(initialManagerData);
      setFirstNameError(false);
      setLastNameError(false);
      setEmailError(false);
      setEmailVerificationError(false);
      setLoading(false);
      setErrorMessage(null);
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    setErrorMessage(null);
    if (!managerData.firstName) return setFirstNameError(true);
    if (!managerData.lastName) return setLastNameError(true);
    if (!isValidEmail(managerData.email)) return setEmailError(true);
    if (managerData.email !== managerData.emailVerification)
      return setEmailVerificationError(true);
    setEmailVerificationError(false);

    setLoading(true);

    const newManager: WithOptionalId<Manager> = {
      email: managerData.email,
      firstName: managerData.firstName,
      lastName: managerData.lastName,
      gymIds: [selectedGymId],
      isActive: true,
      isAdmin: false,
    };

    try {
      const { success, errorMessage } = await onSubmit(newManager);

      if (success) {
        setManagerData(initialManagerData);
        onClose();
      } else {
        setErrorMessage(errorMessage || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BaseDialog
        onClose={onClose}
        isOpen={isOpen}
        title="Add A Manager"
        actions={
          <Button
            className={styles.submit}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              <>
                <AddBoxOutlinedIcon color="primary" />
                <span className={styles.submitText}>Add Manager</span>
              </>
            )}
          </Button>
        }
      >
        <DialogContent className={styles.managerDataDialog}>
          <InputField
            icon={<PersonOutlineOutlinedIcon color="primary" />}
            title="First Name"
            inputName="firstName"
            autoComplete="firstName"
            value={managerData.firstName}
            onChange={(firstName) => {
              setFirstNameError(false);
              setManagerData((prevManagerData) => ({
                ...prevManagerData,
                firstName,
              }));
            }}
            error={firstNameError}
          />
          <InputField
            icon={<PersonOutlineOutlinedIcon color="primary" />}
            title="Last Name"
            inputName="lastName"
            autoComplete="lastName"
            value={managerData.lastName}
            onChange={(lastName) => {
              setLastNameError(false);
              setManagerData((prevManagerData) => ({
                ...prevManagerData,
                lastName,
              }));
            }}
            error={lastNameError}
          />
          <InputField
            icon={<MailOutlineRoundedIcon color="primary" />}
            title="Email"
            inputName="email"
            autoComplete="email"
            value={managerData.email}
            onChange={(email) => {
              setEmailError(false);
              setEmailVerificationError(false);
              setManagerData((prevManagerData) => ({
                ...prevManagerData,
                email,
              }));
            }}
            onBlur={() => setEmailError(!isValidEmail(managerData.email))}
            error={emailError}
          />
          <InputField
            icon={<MailOutlineRoundedIcon color="primary" />}
            title="Email Verification"
            inputName="emailVerification"
            autoComplete="email"
            value={managerData.emailVerification}
            onChange={(emailVerification) => {
              setEmailVerificationError(false);
              setManagerData((prevManagerData) => ({
                ...prevManagerData,
                emailVerification,
              }));
            }}
            onBlur={() =>
              setEmailVerificationError(
                managerData.email !== managerData.emailVerification,
              )
            }
            error={emailVerificationError}
          />
        </DialogContent>
      </BaseDialog>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
        >
          <Alert onClose={() => setErrorMessage(null)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AddManagerDialog;
