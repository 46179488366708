import React, { useState } from 'react';
import styles from './ManagerRow.module.scss';
import classNames from 'classnames';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { getFullName } from '../../utils/dashboardUtils';
import ConfirmationDialog from '../DialogWrappers/ConfirmationDialog';
import Menu from '../atoms/Menu';

import type { Manager } from '../../types';

interface ManagerRowProps {
  manager: Manager;
  selected: boolean;
  onSelect?: () => void;
  deleteManager: (manager: Manager) => Promise<void>;
}

const ManagerRow: React.FC<ManagerRowProps> = ({
  manager,
  onSelect,
  selected,
  deleteManager,
}) => {
  const [isDeleteManagerConfirmationOpen, setIsDeleteManagerConfirmationOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const renderManagerName = () => {
    const managerName = getFullName(manager);
    return (
      <div className={classNames(styles.managerColumn, styles.managerName)}>
        {managerName}
      </div>
    );
  };

  const handleDeleteManager = async () => {
    handleCloseMenuOptions();
    await deleteManager(manager);
    setIsDeleteManagerConfirmationOpen(false);
  };

  const renderMoreOptions = () => {
    const menuOptions = [
      {
        onClick: () => setIsDeleteManagerConfirmationOpen(true),
        label: 'Delete Manager',
        icon: <DeleteForeverOutlinedIcon color="primary" />,
      },
    ];
    return (
      <div className={classNames(styles.managerColumn, styles.options)}>
        <PendingOutlinedIcon
          // @ts-ignore
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={styles.optionsBtn}
          color="primary"
        />
        <Menu
          anchorEl={anchorEl}
          isOpen={open}
          onClose={handleCloseMenuOptions}
          options={menuOptions}
        />
      </div>
    );
  };

  const renderDeleteManagerConfirmationDialog = () => (
    <ConfirmationDialog
      text="Are you sure you want to delete this manager?"
      onSubmit={handleDeleteManager}
      submitText="Yes"
      cancelText="No"
      isOpen={isDeleteManagerConfirmationOpen}
      onCancel={() => setIsDeleteManagerConfirmationOpen(false)}
      symbol={<DeleteForeverOutlinedIcon />}
    />
  );

  return (
    <div
      onClick={onSelect}
      className={classNames(styles.managerRow, styles.listGrid, {
        [styles.selected]: selected,
      })}
    >
      {renderManagerName()}
      {renderMoreOptions()}
      {renderDeleteManagerConfirmationDialog()}
    </div>
  );
};

export default ManagerRow;
