import React from 'react';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import styles from './BaseDialog.module.scss';

export interface BaseDialogProps {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  actions: React.ReactNode;
}

const AddClientDialog: React.FC<BaseDialogProps> = ({
  title,
  children,
  isOpen,
  actions,
  onClose,
}) => (
  <Dialog className={styles.baseDialog} open={isOpen} onClose={onClose}>
    <div className={styles.dialogHeader}>
      <DialogTitle className={styles.title}>{title}</DialogTitle>
      <HighlightOffRoundedIcon
        onClick={onClose}
        className={styles.closeDialog}
        color="primary"
      />
    </div>
    {children}
    <DialogActions className={styles.dialogActions}>{actions}</DialogActions>
  </Dialog>
);

export default AddClientDialog;
