import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import styles from './ClientDataDialogCommon.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import { isValidEmail } from '../../utils/utils';
import { Product, UserType } from '../../constants';
import { getActiveSubscription } from '../../api';
import InputField from '../atoms/InputField';
import { getSubscriptionOptions } from '../../utils/dashboardUtils';

import type { Member } from '../../types';

export interface EditClientDialogProps {
  userType: UserType;
  onClose: () => void;
  onSubmit: (
    newMemberData: Member,
    selectedProduct: Product | '',
  ) => Promise<{ success: boolean; errorMessage?: string }>;
  isOpen: boolean;
  trainers: { id: string; name: string }[];
  member: Member;
}

const initialClientData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  trainerId: '',
  subscription: '',
};

const EditClientDialog: React.FC<EditClientDialogProps> = ({
  userType,
  member,
  isOpen,
  onSubmit,
  onClose,
  trainers,
}) => {
  const [clientData, setClientData] = useState(initialClientData);

  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen && member) {
      setClientData({
        firstName: member.firstName || '',
        lastName: member.lastName || '',
        email: member.email || '',
        phoneNumber: member.phoneNumber || '',
        trainerId: member.trainerIds[0] || '',
        subscription:
          getActiveSubscription(member?.subscriptions)?.product || '',
      });
      setFirstNameError(false);
      setLastNameError(false);
      setPhoneNumberError(false);
      setEmailError(false);
      setLoading(false);
      setErrorMessage(null);
    }
  }, [isOpen, member]);

  const handleSubmit = async () => {
    setErrorMessage(null);
    if (!clientData.firstName) return setFirstNameError(true);
    if (!clientData.lastName) return setLastNameError(true);
    if (!isValidEmail(clientData.email)) return setEmailError(true);
    // if (!clientData.phoneNumber) return setPhoneNumberError(true);
    setLoading(true);

    const newMemberData = _.cloneDeep(member);
    newMemberData.firstName = clientData.firstName;
    newMemberData.lastName = clientData.lastName;
    newMemberData.email = clientData.email;
    newMemberData.phoneNumber = clientData.phoneNumber;
    newMemberData.trainerIds = clientData.trainerId
      ? [clientData.trainerId]
      : [];

    try {
      const { success, errorMessage } = await onSubmit(
        newMemberData,
        clientData.subscription as Product,
      );

      if (success) {
        setClientData(initialClientData);
        onClose();
      } else {
        setErrorMessage(errorMessage || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BaseDialog
        onClose={onClose}
        isOpen={isOpen}
        title={`Edit ${userType} Information`}
        actions={
          <Button
            className={styles.submit}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              <span className={styles.submitText}>{`Save ${userType}`}</span>
            )}
          </Button>
        }
      >
        <DialogContent className={styles.clientDataDialog}>
          <div className={styles.row}>
            <div className={styles.rowColumn}>
              <InputField
                icon={<PersonOutlineOutlinedIcon color="primary" />}
                title="First Name"
                inputName="firstName"
                autoComplete="firstName"
                value={clientData.firstName}
                onChange={(firstName) => {
                  setFirstNameError(false);
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    firstName,
                  }));
                }}
                error={firstNameError}
              />
            </div>
            <div className={styles.rowColumn}>
              <InputField
                icon={<PersonOutlineOutlinedIcon color="primary" />}
                title="Last Name"
                inputName="lastName"
                autoComplete="lastName"
                value={clientData.lastName}
                onChange={(lastName) => {
                  setLastNameError(false);
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    lastName,
                  }));
                }}
                error={lastNameError}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowColumn}>
              <InputField
                icon={<MailOutlineRoundedIcon color="primary" />}
                title="Email"
                inputName="email"
                autoComplete="email"
                value={clientData.email}
                onChange={(email) => {
                  setEmailError(false);
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    email,
                  }));
                }}
                onBlur={() => setEmailError(!isValidEmail(clientData.email))}
                error={emailError}
              />
            </div>
            <div className={styles.rowColumn}>
              <InputField
                icon={<SupervisorAccountOutlinedIcon color="primary" />}
                title="Subscription"
                inputName="subscription"
                value={clientData.subscription}
                options={getSubscriptionOptions()}
                onChange={(subscription) => {
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    subscription,
                  }));
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowColumn}>
              <InputField
                icon={<LocalPhoneOutlinedIcon color="primary" />}
                title="Phone Number"
                inputName="phoneNumber"
                autoComplete="phone"
                value={clientData.phoneNumber}
                onChange={(phoneNumber) => {
                  setPhoneNumberError(false);
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    phoneNumber,
                  }));
                }}
                error={phoneNumberError}
              />
            </div>
            <div className={styles.rowColumn}>
              <InputField
                icon={<PersonOutlineOutlinedIcon color="primary" />}
                title="Trainer"
                inputName="trainerId"
                value={clientData.trainerId}
                options={trainers.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                onChange={(trainerId) => {
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    trainerId,
                  }));
                }}
              />
            </div>
          </div>
        </DialogContent>
      </BaseDialog>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
        >
          <Alert onClose={() => setErrorMessage(null)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default EditClientDialog;
