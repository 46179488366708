import React, { useState } from 'react';
import { Container, Box } from '@mui/material';
import styles from './LoginPage.module.scss';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginSuccessAnimation from '../LoginSuccessAnimation';
import { DashboardRoute } from '../../router/routes';
import {
  fetchAndSetStateData,
  setLoginStorageData,
} from '../../api/loginApiHelper';
import { LoginPageTitle } from './LoginPageTitle';
import { LoginPageDescription } from './LoginPageDescription';
import { LoginPageButton } from './LoginPageButton';
import { LoginPageVerificationCode } from './LoginPageVerificationCode';
import { LoginPageMain } from './LoginPageMain';

import type { Dispatch } from 'redux';
import type { VerificationCodeResponse } from '../../api';

export interface LoginPageDispatchProps {
  onLogin: (verifyResponse: VerificationCodeResponse) => Promise<void>;
}

export interface LoginPageStateProps {}

export interface LoginPageProps
  extends LoginPageDispatchProps,
    LoginPageStateProps {}

const LoginPagePure: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [email, setEmail] = useState<string>('');
  const [emailVerifyError, setEmailVerifyError] = useState<boolean>(false);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const renderLoginContent = () => {
    if (emailVerifyError) {
      return (
        <Box sx={{ width: '100%', marginBottom: 2 }}>
          <LoginPageTitle text="Oops! Email not found" />
          <LoginPageDescription
            text="Seems like we can't find your email. Try entering your email again
            or contact your administrator for help."
          />
          <LoginPageButton
            onClick={() => {
              setEmail('');
              setEmailVerifyError(false);
            }}
          >
            Try Again
          </LoginPageButton>
        </Box>
      );
    } else if (loginSuccess) {
      return <LoginSuccessAnimation />;
    } else if (isCodeSent) {
      return (
        <LoginPageVerificationCode
          email={email}
          onVerified={async (verifyResponse) => {
            setLoginSuccess(true);
            await onLogin(verifyResponse);
            navigate(DashboardRoute.Dashboard);
          }}
        />
      );
    }
    return (
      <LoginPageMain
        email={email}
        setEmail={setEmail}
        setEmailVerifyError={setEmailVerifyError}
        setCodeSent={() => setIsCodeSent(true)}
      />
    );
  };

  return (
    <Container
      component="main"
      className={styles.container}
      style={{ padding: 0, display: 'flex', margin: 0, maxWidth: '100vw' }}
    >
      <Box className={styles.leftSide} />
      <Box className={styles.rightSide}>
        <Box className={styles.rightSideContentWrapper}>
          {renderLoginContent()}
        </Box>
      </Box>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): LoginPageDispatchProps => ({
  onLogin: async ({
    memberId,
    managerId,
    trainerId,
    loginExpiration,
  }: VerificationCodeResponse) => {
    const loginData = { managerId, trainerId, memberId, loginExpiration };
    setLoginStorageData(loginData);
    await fetchAndSetStateData(
      dispatch,
      loginData.managerId,
      loginData.trainerId,
      loginData.memberId,
    );
  },
});

const LoginPage = connect(null, mapDispatchToProps)(LoginPagePure);

export default LoginPage;
