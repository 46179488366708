import React, { useMemo, useState } from 'react';
import trainerRowStyles from './TrainerRow.module.scss';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import EmptyState from '../composites/EmptyState';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import TrainerRow from './TrainerRow';
import ListContent from '../composites/ListContent';
import { createNewTrainer, updateTrainerData } from '../../api';
import { getFullName } from '../../utils/dashboardUtils';
import AddTrainerDialog from './AddTrainerDialog';
import {
  useLoginData,
  useRefreshState,
  useSelectedGymData,
} from '../../providers';

import type { AppState } from '../../appState/appInitialState';
import type { Trainer, Manager, WithOptionalId } from '../../types';

const trainerListTitles = ['Trainer Name', 'Clients', 'Options'];

export interface TrainersContentStateProps {
  manager?: Manager;
  allGymTrainers: Trainer[];
  gymId: AppState['selectedGymId'];
}

export interface TrainersContentProps extends TrainersContentStateProps {}

const Trainers: React.FC = () => {
  const { selectedGymId, trainers: allGymTrainers } = useSelectedGymData();
  const refreshState = useRefreshState();
  const { managerData } = useLoginData();
  const manager = managerData?.manager;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedTrainer, setSelectedTrainer] = useState<string>('');
  const [isAddTrainerModalOpened, setIsAddTrainerModalOpened] = useState(false);

  const filteredTrainers = useMemo(() => {
    if (!allGymTrainers) return [];
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return allGymTrainers
      .filter((trainer) =>
        searchQueryRegex.test(getFullName(trainer).toLowerCase()),
      )
      .sort(
        (a, b) =>
          a.firstName
            ?.toLowerCase()
            .localeCompare(b.firstName?.toLowerCase() || '') || 0,
      );
  }, [searchQuery, allGymTrainers]);

  if (!manager || !selectedGymId) {
    return (
      <EmptyState icon={<Person4OutlinedIcon />} text="No trainers found." />
    );
  }

  const renderEmptyState = () => (
    <EmptyState
      icon={<Person4OutlinedIcon />}
      cta={{
        icon: <Person4OutlinedIcon color="primary" />,
        label: 'Add A Trainer',
        onClick: () => setIsAddTrainerModalOpened(true),
      }}
      text="No Trainers Have Been Added"
    />
  );

  const addTrainer = async (trainer: WithOptionalId<Trainer>) => {
    const { success, errorMessage } = await createNewTrainer(
      trainer,
      selectedGymId,
    );
    if (!success) {
      return { success, errorMessage };
    }
    await refreshState();
    setIsAddTrainerModalOpened(false);
    return { success: true };
  };

  const renderAddProgramDialog = () => (
    <AddTrainerDialog
      onSubmit={addTrainer}
      onClose={() => setIsAddTrainerModalOpened(false)}
      isOpen={isAddTrainerModalOpened}
    />
  );

  const deleteTrainer = async (trainer: Trainer) => {
    await updateTrainerData({ ...trainer, isActive: false });
    await refreshState();
  };

  const renderContent = () => {
    return (
      <ListContent
        items={filteredTrainers}
        listHeaderClassName={trainerRowStyles.listGrid}
        EmptyStateComponent={renderEmptyState}
        titles={trainerListTitles}
        ItemComponent={({ data }) => (
          <TrainerRow
            trainer={data}
            deleteTrainer={deleteTrainer}
            selected={selectedTrainer === data.id}
            onSelect={() => setSelectedTrainer(data.id)}
            key={data.id}
          />
        )}
      />
    );
  };

  return (
    <DashboardContentWrapper
      searchPlaceholder="Search for Trainers"
      title="Trainers"
      setSearchQuery={setSearchQuery}
      actions={[
        {
          icon: <AddBoxOutlinedIcon />,
          label: 'Add A Trainer',
          onClick: () => setIsAddTrainerModalOpened(true),
        },
      ]}
    >
      {renderContent()}
      {renderAddProgramDialog()}
    </DashboardContentWrapper>
  );
};

export default Trainers;
