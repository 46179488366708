import React, { useState } from 'react';
import { Button, Menu, Box, MenuItem, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import styles from './NavigationBar.module.scss';
import { NavigationRoutes } from '../constants/navigationConsts';
import { connect } from 'react-redux';
import { resetAllState } from '../appState/stateActions';
import { clearLoginStorageData } from '../api/loginApiHelper';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import type { Dispatch } from 'redux';

interface NavigationBarDispatchProps {
  logout: () => void;
}

export interface NavigationBarProps extends NavigationBarDispatchProps {
  navigationRoute: NavigationRoutes | null;
  navigationRoutes: Array<{ navigation: NavigationRoutes; title: string }>;
  setNavigationRoute: (navigationRoutes: NavigationRoutes) => void;
}

const routeIconsMap: Record<NavigationRoutes, () => React.JSX.Element> = {
  [NavigationRoutes.Exercises]: () => <DirectionsRunOutlinedIcon />,
  [NavigationRoutes.Clients]: () => <PersonOutlineOutlinedIcon />,
  [NavigationRoutes.Managers]: () => <Groups3OutlinedIcon />,
  [NavigationRoutes.Workouts]: () => <FitnessCenterOutlinedIcon />,
  [NavigationRoutes.Programs]: () => <ListOutlinedIcon />,
  [NavigationRoutes.Groups]: () => <GroupsOutlinedIcon />,
  [NavigationRoutes.Trainers]: () => <Person4OutlinedIcon />,
};

const NavigationBarPure: React.FC<NavigationBarProps> = ({
  navigationRoutes,
  navigationRoute,
  setNavigationRoute,
  logout,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderLogOut = () => {
    return (
      <Button
        variant="text"
        startIcon={
          <ExitToAppIcon sx={{ color: 'primary.main', marginRight: 3 }} />
        }
        onClick={logout}
        sx={{
          backgroundColor: 'transparent !important',
          color: 'primary.main',
          textTransform: 'none',
          justifyContent: 'flex-start',
          fontWeight: 'bold',
        }}
      >
        Log Out
      </Button>
    );
  };

  const renderNavigationContents = () => {
    return (
      <Box sx={{ width: '100%' }}>
        {navigationRoutes.map(({ navigation, title }) => (
          <label
            key={navigation}
            className={`${styles.navItem} ${
              navigation === navigationRoute
                ? styles.selectedNav
                : styles.unselectedNav
            }`}
          >
            <input
              type="radio"
              value={navigation}
              checked={navigation === navigationRoute}
              onChange={() => setNavigationRoute(navigation)}
              style={{ display: 'none' }}
            />
            {routeIconsMap[navigation]()}
            <span className={styles.navLabel}>{title}</span>
          </label>
        ))}
      </Box>
    );
  };

  return (
    <div className={styles.navigationBar}>
      <div className={styles.logo}>
        <img
          className={styles.logoImage}
          src="/motivision-logo.png"
          alt="Motivision"
        />
      </div>
      <div className={styles.navigationBarContent}>
        {renderNavigationContents()}
      </div>
      <div className={styles.navigationLogoutWrapper}>{renderLogOut()}</div>
      <IconButton
        className={styles.menuButton}
        aria-label="menu"
        size="large"
        onClick={handleMenuOpen}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={styles.responsiveMenu}
      >
        <MenuItem onClick={handleMenuClose}>
          {renderNavigationContents()}
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>{renderLogOut()}</MenuItem>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
): NavigationBarDispatchProps => ({
  logout: () => {
    dispatch(resetAllState());
    clearLoginStorageData();
  },
});

const NavigationBar = connect(null, mapDispatchToProps)(NavigationBarPure);

export default NavigationBar;
