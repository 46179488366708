import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import PatternOutlinedIcon from '@mui/icons-material/PatternOutlined';
import SportsGymnasticsOutlinedIcon from '@mui/icons-material/SportsGymnasticsOutlined';
import styles from './BaseExerciseDialog.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import { MovementPatterns } from '../../constants';
import InputField from '../atoms/InputField';
import FileUpload from '../atoms/FileUpload';
import { uploadExerciseImages, uploadExerciseVideos } from '../../api';
import { strToOptionObj } from '../../utils/utils';
import MultiSelectInputField from '../atoms/MultiSelectInputField';
import { useGlobalData } from '../../providers';

import type { Exercise, WithOptionalId } from '../../types';
import type { AppState } from '../../appState/appInitialState';

export interface AddExerciseDialogProps {
  originalExercise?: Exercise | null;
  title: string;
  onClose: () => void;
  onSubmit: (
    newExercise: WithOptionalId<Exercise>,
  ) => Promise<{ success: boolean; errorMessage?: string }>;
  isOpen: boolean;
  isPersonalized: boolean;
  trainerId: string;
}

const getMuscleGroupOptions = (muscles: AppState['muscles']) =>
  Object.values(muscles)
    .map(({ name }) => strToOptionObj(name))
    .sort((a, b) => a.value.localeCompare(b.value));

const getEquipmentTypeOptions = (exercises: AppState['exercises']) =>
  [...new Set(Object.values(exercises).map(({ modality }) => modality))]
    .map((modality) => strToOptionObj(modality))
    .sort((a, b) => a.value.localeCompare(b.value));

const BaseExerciseDialog: React.FC<AddExerciseDialogProps> = ({
  title,
  isOpen,
  onSubmit,
  onClose,
  trainerId,
  isPersonalized,
  originalExercise,
}) => {
  const { muscles, exercises } = useGlobalData();
  const [name, setName] = useState('');
  const [movementPattern, setMovementPattern] = useState<string>('');
  const [primaryMuscleGroupIds, setPrimaryMuscleGroupIds] = useState<string[]>(
    [],
  );
  const [secondaryMuscleGroupIds, setSecondaryMuscleGroupIds] = useState<
    string[]
  >([]);
  const [modality, setModality] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [isUnilateral, setIsUnilateral] = useState<boolean | undefined>(
    undefined,
  );
  const [isWeightsPerSide, setIsWeightsPerSide] = useState<boolean | undefined>(
    undefined,
  );

  const [nameError, setNameError] = useState<boolean>(false);
  const [movementPatternError, setMovementPatternError] =
    useState<boolean>(false);
  const [primaryMuscleGroupIdsError, setPrimaryMuscleGroupIdsError] =
    useState<boolean>(false);
  const [modalityError, setModalityError] = useState<boolean>(false);
  const [imageUrlError, setImageUrlError] = useState<boolean>(false);
  const [isUnilateralError, setIsUnilateralError] = useState<boolean>(false);
  const [isWeightsPerSideError, setIsWeightsPerSideError] =
    useState<boolean>(false);
  const [videoUrlError, setVideoUrlError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setName(originalExercise?.name || '');
      setMovementPattern(originalExercise?.movementPattern || '');
      setPrimaryMuscleGroupIds(originalExercise?.primaryMuscleGroupIds || []);
      setSecondaryMuscleGroupIds(
        originalExercise?.secondaryMuscleGroupIds || [],
      );
      setIsUnilateral(
        _.isBoolean(originalExercise?.isUnilateral)
          ? originalExercise?.isUnilateral
          : undefined,
      );
      setIsWeightsPerSide(
        _.isBoolean(originalExercise?.isWeightsPerSide)
          ? originalExercise?.isWeightsPerSide
          : undefined,
      );
      setModality(originalExercise?.modality || '');
      setImageUrl(originalExercise?.imageUrl || '');
      setVideoUrl(originalExercise?.videoUrl || '');
      setImageFile(null);
      setVideoFile(null);
      setNameError(false);
      setMovementPatternError(false);
      setPrimaryMuscleGroupIdsError(false);
      setModalityError(false);
      setImageUrlError(false);
      setVideoUrlError(false);
      setLoading(false);
      setErrorMessage(null);
    }
  }, [isOpen, originalExercise]);

  const handleSubmit = async () => {
    setErrorMessage(null);
    if (!name) return setNameError(true);
    if (!modality) return setModalityError(true);
    if (!movementPattern) return setMovementPatternError(true);
    if (!primaryMuscleGroupIds.length)
      return setPrimaryMuscleGroupIdsError(true);
    if (!_.isBoolean(isUnilateral)) return setIsUnilateralError(true);
    if (!_.isBoolean(isWeightsPerSide)) return setIsWeightsPerSideError(true);
    if (!imageFile && !imageUrl) return setImageUrlError(true);
    if (!videoFile && !videoUrl) return setVideoUrlError(true);

    setLoading(true);

    const [uploadedImageUrl, uploadedVideoUrl] = await Promise.all([
      imageUrl ||
        uploadExerciseImages([imageFile!], trainerId).then(
          ([{ fileUrl }]) => fileUrl,
        ),
      videoUrl ||
        uploadExerciseVideos([videoFile!], trainerId).then(
          ([{ fileUrl }]) => fileUrl,
        ),
    ]);

    if (!uploadedImageUrl || !_.isString(uploadedImageUrl)) {
      setLoading(false);
      setImageUrlError(true);
      return;
    }
    setImageUrl(uploadedImageUrl);
    if (!uploadedVideoUrl || !_.isString(uploadedVideoUrl)) {
      setLoading(false);
      setVideoUrlError(true);
      return;
    }
    setVideoUrl(uploadedVideoUrl);

    const newExercise: WithOptionalId<Exercise> = {
      canBeAutomatic: false,
      planeOfMotion: '',

      ...originalExercise,

      name,
      modality,
      isUnilateral,
      isWeightsPerSide,
      movementPattern,
      imageUrl: uploadedImageUrl,
      videoUrl: uploadedVideoUrl,
      trainerId,
      isPersonalized,
      secondaryMuscleGroupIds,
      primaryMuscleGroupIds,
      preferredStationIds: [],
    };

    try {
      const { success, errorMessage } = await onSubmit(newExercise);

      if (success) {
        onClose();
      } else {
        setErrorMessage(errorMessage || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BaseDialog
        onClose={onClose}
        isOpen={isOpen}
        title={title}
        actions={
          <Button
            className={styles.submit}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              <span className={styles.submitText}>Save Exercise</span>
            )}
          </Button>
        }
      >
        <DialogContent className={styles.exerciseDataDialog}>
          <div className={styles.leftContent}>
            <InputField
              placeholder="Add An Exercise Name"
              icon={<EditNoteOutlinedIcon color="primary" />}
              title="Exercise name"
              inputName="exerciseName"
              value={name}
              onChange={(value) => {
                setName(value);
                setNameError(false);
              }}
              error={nameError}
            />
            <InputField
              placeholder="Select Equipment Type"
              icon={<EditNoteOutlinedIcon color="primary" />}
              title="Equipment Type"
              inputName="modality"
              value={modality}
              options={getEquipmentTypeOptions(exercises)}
              onChange={(value) => {
                setModality(value);
                setModalityError(false);
              }}
              error={modalityError}
            />
            <InputField
              icon={<PatternOutlinedIcon color="primary" />}
              placeholder="Select Movement Pattern"
              title="Movement Pattern"
              inputName="movementPattern"
              value={movementPattern}
              options={Object.values(MovementPatterns)
                .map((value) => ({
                  label: value,
                  value,
                }))
                .sort((a, b) => a.value.localeCompare(b.value))}
              onChange={(value) => {
                setMovementPattern(value);
                setMovementPatternError(false);
              }}
              error={movementPatternError}
            />
            <MultiSelectInputField
              placeholder="Select Main Muscle Group"
              title="Main Muscle Group"
              inputName="mainMuscleGroup"
              icon={<SportsGymnasticsOutlinedIcon color="primary" />}
              error={primaryMuscleGroupIdsError}
              value={primaryMuscleGroupIds.map((id) => muscles[id].name)}
              options={getMuscleGroupOptions(muscles)}
              onChange={(names) => {
                setPrimaryMuscleGroupIds(
                  names.map(
                    (name) =>
                      Object.values(muscles).find(
                        (muscle) => muscle.name === name,
                      )?.id || '',
                  ),
                );
                setPrimaryMuscleGroupIdsError(false);
              }}
            />
            <MultiSelectInputField
              placeholder="Select Secondary Muscle Group"
              title="Secondary Muscle Group"
              inputName="secondaryMuscleGroup"
              icon={<SportsGymnasticsOutlinedIcon color="primary" />}
              value={secondaryMuscleGroupIds.map((id) => muscles[id].name)}
              options={getMuscleGroupOptions(muscles)}
              onChange={(names) => {
                setSecondaryMuscleGroupIds(
                  names.map(
                    (name) =>
                      Object.values(muscles).find(
                        (muscle) => muscle.name === name,
                      )?.id || '',
                  ),
                );
              }}
            />
            <InputField
              placeholder="Yes / No"
              title="Is Unilateral?"
              inputName="isUnilateral"
              icon={<SportsGymnasticsOutlinedIcon color="primary" />}
              value={
                _.isBoolean(isUnilateral) ? (isUnilateral ? 'Yes' : 'No') : ''
              }
              options={['No', 'Yes'].map((val) => strToOptionObj(val))}
              onChange={(value) => {
                setIsUnilateral(value === 'Yes');
                setIsUnilateralError(false);
              }}
              error={isUnilateralError}
            />
            <InputField
              placeholder="Yes / No"
              title="Is Weights Per Side?"
              inputName="isisWeightsPerSide"
              icon={<SportsGymnasticsOutlinedIcon color="primary" />}
              value={
                _.isBoolean(isWeightsPerSide)
                  ? isWeightsPerSide
                    ? 'Yes'
                    : 'No'
                  : ''
              }
              options={['No', 'Yes'].map((val) => strToOptionObj(val))}
              onChange={(value) => {
                setIsWeightsPerSide(value === 'Yes');
                setIsWeightsPerSideError(false);
              }}
              error={isWeightsPerSideError}
            />
          </div>
          <div className={styles.rightContent}>
            <FileUpload
              type="Video"
              title="Upload Video"
              url={videoUrl}
              description="Upload a landscape video. Make sure your body is in the middle third."
              label="Upload Video"
              onChange={(file) => {
                setVideoUrl('');
                setVideoUrlError(false);
                setVideoFile(file);
              }}
              error={videoUrlError}
            />
            <FileUpload
              type="Image"
              title="Upload Image"
              url={imageUrl}
              description="Upload a landscape image. Make sure your body is in the middle third."
              label="Upload Thumbnail"
              onChange={(file) => {
                setImageUrl('');
                setImageUrlError(false);
                setImageFile(file);
              }}
              error={imageUrlError}
            />
          </div>
        </DialogContent>
      </BaseDialog>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
        >
          <Alert onClose={() => setErrorMessage(null)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default BaseExerciseDialog;
