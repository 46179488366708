import React, { useState } from 'react';
import styles from './GroupRow.module.scss';
import classNames from 'classnames';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import { getFullName } from '../../utils/dashboardUtils';
import ConfirmationDialog from '../DialogWrappers/ConfirmationDialog';
import Menu from '../atoms/Menu';
import AssignProgramDialog from '../Programs/AssignProgramDialog';
import SelectMembersDialog from './SelectMembersDialog';
import { assignProgramToMembers } from '../../api';
import { useRefreshState } from '../../providers';
import AssignProgramOptionsDialog from '../Programs/AssignProgramOptionsDialog';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import type {
  Group,
  Trainer,
  AssignedProgram,
  WithOptionalId,
} from '../../types';

interface GroupRowProps {
  group: Group;
  selected: boolean;
  onSelect?: () => void;
  trainers: Trainer[];
  openEditGroupModal: () => void;
  deleteGroup: (group: Group) => Promise<void>;
  onEditProgram: () => void;
  onCreateProgram: () => void;
  onSubmitEditedGroup: (
    currentGroupData: WithOptionalId<Group>,
    newGroupData: WithOptionalId<Group>,
  ) => Promise<
    | { success: false; errorMessage: string | undefined }
    | { success: boolean; errorMessage?: undefined }
  >;
}

const GroupRow: React.FC<GroupRowProps> = ({
  group,
  trainers,
  onSelect,
  selected,
  deleteGroup,
  openEditGroupModal,
  onEditProgram,
  onCreateProgram,
  onSubmitEditedGroup,
}) => {
  const refreshState = useRefreshState();
  const [isDeleteGroupConfirmationOpen, setIsDeleteGroupConfirmationOpen] =
    useState(false);
  const [isAssignProgramOptionsModalOpen, setIsAssignProgramOptionsModalOpen] =
    useState(false);
  const [isAddProgramTemplateModalOpen, setIsAddProgramTemplateModalOpen] =
    useState(false);
  const [isSelectMembersModalOpen, setIsSelectMembersModalOpen] =
    useState(false);
  const [programFromTemplate, setProgramFromTemplate] =
    useState<AssignedProgram | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const renderGroupName = () => (
    <div className={classNames(styles.groupColumn, styles.groupName)}>
      {group.name}
    </div>
  );

  const renderTrainerName = () => {
    const trainer = trainers.find(({ id }) => id === group.trainerIds[0]);
    const trainerName = getFullName(trainer);
    return <div className={classNames(styles.groupColumn)}>{trainerName}</div>;
  };

  const renderClients = () => {
    return (
      <div
        className={classNames(styles.groupColumn)}
      >{`${group.memberIds.length} Members`}</div>
    );
  };

  const handleEditGroup = () => {
    openEditGroupModal();
    handleCloseMenuOptions();
  };

  const handleDeleteGroup = async () => {
    handleCloseMenuOptions();
    await deleteGroup(group);
    setIsDeleteGroupConfirmationOpen(false);
  };

  const handleSubmitProgramTemplate = async (program: AssignedProgram) => {
    setProgramFromTemplate(program);
    setIsAddProgramTemplateModalOpen(false);
    setIsSelectMembersModalOpen(true);
  };

  const renderProgramTemplateDialog = () => (
    <AssignProgramDialog
      onSubmit={handleSubmitProgramTemplate}
      isOpen={isAddProgramTemplateModalOpen}
      onClose={() => setIsAddProgramTemplateModalOpen(false)}
    />
  );

  const assignProgramToSelectedMembers = async (memberIds: string[]) => {
    if (!programFromTemplate) {
      setIsSelectMembersModalOpen(false);
      await refreshState();
      return;
    }
    const updatedGroup = {
      ...group,
      program: programFromTemplate,
    };
    await onSubmitEditedGroup(group as Group, updatedGroup as Group);
    await assignProgramToMembers(memberIds, programFromTemplate);
    setProgramFromTemplate(null);
    setIsSelectMembersModalOpen(false);
    await refreshState();
  };

  const handleCloseSelectMembersDialog = () => {
    setProgramFromTemplate(null);
    setIsSelectMembersModalOpen(false);
  };

  const renderSelectMembersDialog = () => (
    <SelectMembersDialog
      onSubmit={assignProgramToSelectedMembers}
      isOpen={isSelectMembersModalOpen}
      onClose={handleCloseSelectMembersDialog}
      memberIds={group.memberIds}
    />
  );

  const renderAssignProgramOptionsDialog = () => {
    if (!isAssignProgramOptionsModalOpen) return null;
    return (
      <AssignProgramOptionsDialog
        onCreate={() => {
          setIsAssignProgramOptionsModalOpen(false);
          onCreateProgram();
        }}
        onAdd={() => {
          setIsAssignProgramOptionsModalOpen(false);
          setIsAddProgramTemplateModalOpen(true);
        }}
        onClose={() => setIsAssignProgramOptionsModalOpen(false)}
      />
    );
  };

  const renderMoreOptions = () => {
    const menuOptions = [
      {
        onClick: handleEditGroup,
        label: 'Edit Group Details',
        icon: <BorderColorOutlinedIcon color="primary" />,
      },
      {
        onClick: () => setIsDeleteGroupConfirmationOpen(true),
        label: 'Delete Group',
        icon: <DeleteForeverOutlinedIcon color="primary" />,
      },
      {
        onClick: () => setIsAssignProgramOptionsModalOpen(true),
        label: 'Assign Program',
        icon: <PersonAddAlt1OutlinedIcon color="primary" />,
      },
    ];

    if (group.program) {
      menuOptions.push({
        onClick: onEditProgram,
        label: 'Edit Program',
        icon: <AppRegistrationIcon color="primary" />,
      });
    }

    return (
      <div className={classNames(styles.groupColumn, styles.options)}>
        <PendingOutlinedIcon
          // @ts-ignore
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={styles.optionsBtn}
          color="primary"
        />
        <Menu
          anchorEl={anchorEl}
          isOpen={open}
          onClose={handleCloseMenuOptions}
          options={menuOptions}
        />
      </div>
    );
  };

  const renderDeleteGroupConfirmationDialog = () => (
    <ConfirmationDialog
      text="Are you sure you want to delete this group?"
      onSubmit={handleDeleteGroup}
      submitText="Yes"
      cancelText="No"
      isOpen={isDeleteGroupConfirmationOpen}
      onCancel={() => setIsDeleteGroupConfirmationOpen(false)}
      symbol={<DeleteForeverOutlinedIcon />}
    />
  );

  return (
    <div
      onClick={onSelect}
      className={classNames(styles.groupRow, styles.listGrid, {
        [styles.selected]: selected,
      })}
    >
      {renderGroupName()}
      {renderClients()}
      {renderTrainerName()}
      {renderMoreOptions()}
      {renderDeleteGroupConfirmationDialog()}
      {renderAssignProgramOptionsDialog()}
      {renderProgramTemplateDialog()}
      {renderSelectMembersDialog()}
    </div>
  );
};

export default GroupRow;
