import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { LoginPageTitle } from './LoginPageTitle';
import { LoginPageDescription } from './LoginPageDescription';
import { LoginPageButton } from './LoginPageButton';
import { sendVerificationCode } from '../../api';
import { Email as EmailIcon, Google as GoogleIcon } from '@mui/icons-material';
import styles from './LoginPage.module.scss';
import { isValidEmail } from '../../utils/utils';

export interface LoginPageMainProps {
  setEmailVerifyError: (isEmailVerifyError: boolean) => void;
  setCodeSent: () => void;
  setEmail: (email: string) => void;
  email: string;
}

const hasGoogleLogin = false;

export const LoginPageMain: React.FC<LoginPageMainProps> = ({
  email,
  setEmail,
  setCodeSent,
  setEmailVerifyError,
}) => {
  const [emailError, setEmailError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSendVerificationCode = async () => {
    if (!isValidEmail(email)) {
      setEmailError('email is not valid.');
      return;
    }
    setLoading(true);
    const { success } = await sendVerificationCode(email);
    setLoading(false);
    if (success) {
      setCodeSent();
      setEmailError('');
    } else {
      setEmailVerifyError(true);
    }
  };

  useEffect(() => {
    const handleEnterKey = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSendVerificationCode();
      }
    };

    document.addEventListener('keydown', handleEnterKey);
    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, [email]);

  const renderGoogleLogin = () => {
    if (!hasGoogleLogin) return null;
    return (
      <Button
        variant="contained"
        fullWidth
        startIcon={<GoogleIcon />}
        onClick={() => {}}
      >
        Sign in with Google
      </Button>
    );
  };

  const renderLoginsDivider = () => {
    if (!hasGoogleLogin) return null;
    return <Divider sx={{ my: 2 }}>or</Divider>;
  };

  const renderSendVerificationCodeButton = () => {
    return (
      <LoginPageButton
        onClick={handleSendVerificationCode}
        className={styles.sendVerificationCode}
      >
        {loading ? (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        ) : (
          'Send Verification Code'
        )}
      </LoginPageButton>
    );
  };

  const renderEmailLogin = () => {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <EmailIcon sx={{ marginRight: 2 }} color="primary" />
          <Typography color="primary">Email Address</Typography>
        </Box>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          color="primary"
          placeholder="your@email.com"
          name="email"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '15px',
            },
          }}
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError('');
          }}
          error={Boolean(emailError)}
          helperText={emailError ? emailError : ''}
        />
        {renderSendVerificationCodeButton()}
      </>
    );
  };

  return (
    <>
      <Box sx={{ width: '100%', marginBottom: 2 }}>
        <LoginPageTitle text="Welcome to Motivision" />
        <LoginPageDescription text="Enter your email to log in." />
      </Box>
      <Box sx={{ mt: 1, width: '100%' }}>
        {renderGoogleLogin()}
        {renderLoginsDivider()}
        {renderEmailLogin()}
      </Box>
      <div id="recaptcha-container"></div>
    </>
  );
};
