import { getDataWithRetry } from '../fetchUtils';
import { isValidManagerResponse, isValidManagersResponse } from '../apiValidators';
import { apiBaseUrl } from '../apiConsts';

import type { BaseApiResponse } from '../apiTypes';
import type { Manager, WithOptionalId } from '../../types';

export const fetchAllManagers = async (): Promise<Manager[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}managers`,
      {},
      isValidManagersResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchAllManagers:', error);
    return [];
  }
};

export const createNewManager = async (
  manager: WithOptionalId<Manager>,
  gymId: string,
): Promise<BaseApiResponse> => {
  try {
    if (!gymId) throw new Error('No Gym Provided');
    const createdManager = await fetch(`${apiBaseUrl}managers`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(manager),
    }).then((x) => x.json());
    if (createdManager.error) {
      throw new Error(createdManager.message);
    }
    await fetch(`${apiBaseUrl}gyms/${gymId}/associate/managers`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify([createdManager.id]),
    });
    return {
      success: true,
    };
  } catch (e) {
    const error = e as unknown as Error;
    console.error('Error in createNewManager:', error);
    return {
      success: false,
      errorMessage: error?.message || 'Something went wrong',
    };
  }
};

export const updateManagerData = async (
  manager: Manager,
): Promise<BaseApiResponse> => {
  try {
    if (!manager?.id) {
      throw new Error('No manager id provided');
    }
    const response = await fetch(`${apiBaseUrl}managers`, {
      headers: { 'content-type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify(manager),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    console.error('Error in updateManagerData:', e);
    return {
      success: false,
      errorMessage: e?.message || 'Something went wrong',
    };
  }
};

export const fetchManagerById = async (
  managerId: string,
): Promise<Manager | null> => {
  try {
    const manager = await getDataWithRetry(
      `${apiBaseUrl}managers/${managerId}`,
      {},
      isValidManagerResponse,
    );
    return manager;
  } catch (error) {
    console.error('Error in fetchManagerById:', error);
    return null;
  }
};

export const loadGymManagers = async (gymId: string): Promise<Manager[]> => {
  try {
    const { content } = await fetch(
      `${apiBaseUrl}managers/gym/${gymId}`,
      {},
    ).then((x) => x.json());
    return content;
  } catch (error) {
    console.error('Error in loadGymManagers:', error);
    return [];
  }
};
