import _ from 'lodash';
import {
  GYMS_TO_FREE_TRAIAL_FOR_LIBRARY_ON_TRACKING_SUBSCRIPTION,
  hardcodedDescriptions,
  HomeContentRoute,
  Product,
  Status,
  Day,
  daysOrder,
} from '../constants';
import type {
  Exercise,
  ExerciseGroup,
  GymProduct,
  Member,
  Muscle,
  PerformedExerciseGroup,
  PerformedRoutine,
  PerformedSet,
  Rest,
  Station,
  TrainingRoutine,
  TrainingRoutineSession,
  TrainingSet,
  InitialPreferences,
  SignUpPreferences,
  SetGroup,
  PerformedCircuit,
  PerformedSetGroup,
  ProgramItem,
} from '../types';
import { isPerformedRoutine } from './typeUtils';

export const setToNewPerformedSet = (set: TrainingSet): PerformedSet => {
  const { exerciseId, reps, rest, duration, weight } = set;
  return {
    exerciseId,
    rest,
    reps,
    weight,
    duration: duration || NaN,
    startedTimestamp: NaN,
    finishedTimestamp: NaN,
    performedRest: NaN,
    performedDuration: NaN,
    performedWeight: NaN,
    performedReps: NaN,
    performedMaxPaceMS: NaN,
    performedMinPaceMS: NaN,
    performedRangeSuccess: NaN,
    metrics: null,
    analyzedMetrics: [],
    status: Status.Pending,
    paces: [],
  };
};

export const performedRoutineToNewRoutine = (
  performedRoutine: PerformedRoutine,
): TrainingRoutine => {
  return {
    name: performedRoutine.name,
    estimatedDuration: performedRoutine.performedDuration,
    exerciseGroups: performedRoutine.performedExerciseGroups.map(
      createExerciseGroupFromPerformedExerciseGroup,
    ),
  };
};

export const performedSetToSet = (performedSet: PerformedSet): TrainingSet => {
  return {
    exerciseId: performedSet.exerciseId,
    rest: performedSet.rest,
    reps: performedSet.reps,
    weight: performedSet.weight,
    duration: performedSet.duration,
    metrics: performedSet.metrics,
  };
};

export const performedSetToNewPerformedSet = (
  set: PerformedSet,
): PerformedSet => {
  const {
    exerciseId,
    reps,
    rest,
    duration,
    weight,
    performedDuration,
    performedRest,
    performedReps,
    performedWeight,
  } = set;
  return {
    exerciseId,
    rest: rest === 0 ? performedRest || rest : rest,
    reps: reps === 0 ? performedReps || reps : reps,
    weight: weight === 0 ? performedWeight || weight : weight,
    duration: duration === 0 ? performedDuration || duration : duration || NaN,
    startedTimestamp: NaN,
    finishedTimestamp: NaN,
    performedRest: NaN,
    performedDuration: NaN,
    performedWeight: NaN,
    performedReps: NaN,
    performedMaxPaceMS: NaN,
    performedMinPaceMS: NaN,
    performedRangeSuccess: NaN,
    metrics: null,
    analyzedMetrics: [],
    status: Status.Pending,
    paces: [],
  };
};

export const exerciseToNewPerformedSet = (exercise: Exercise): PerformedSet => {
  const { id } = exercise;
  return {
    exerciseId: id,
    rest: 60,
    reps: 10,
    weight: 0,
    duration: NaN,
    startedTimestamp: NaN,
    finishedTimestamp: NaN,
    performedRest: NaN,
    performedDuration: NaN,
    performedWeight: NaN,
    performedReps: NaN,
    performedMaxPaceMS: NaN,
    performedMinPaceMS: NaN,
    performedRangeSuccess: NaN,
    metrics: null,
    analyzedMetrics: [],
    status: Status.Pending,
    paces: [],
  };
};

export const createPerformedRoutineFromTrainingRoutine = (
  trainingRoutine: TrainingRoutine,
  historyTrainingSessions: TrainingRoutineSession[],
): PerformedRoutine => {
  const performedExerciseGroups = trainingRoutine.exerciseGroups.map(
    ({ name, sets }) => ({
      name,
      // use here history to use best performed set in last time performed the exercise (using "performedSetToNewPerformedSet")
      sets: sets.map((circuitSets) => circuitSets.map(setToNewPerformedSet)),
    }),
  );

  return {
    name: trainingRoutine.name,
    exerciseExertionRates: [],
    performedDuration: NaN,
    currentExerciseGroupIndex: 0,
    currentGroupSetIndex: 0,
    performedExerciseGroups,
  };
};

export const isActiveSet = (set: PerformedSet): boolean =>
  set.status === Status.Active;

export const isPendingSet = (set: PerformedSet): boolean =>
  set.status === Status.Pending;

export const isPendingSets = (sets: PerformedSetGroup): boolean =>
  sets.every(isPendingSet);

export const isCompleteSet = (set: PerformedSet): boolean =>
  set.status === Status.Complete;

export const isCompleteGroupSet = (
  performedSetGroup: PerformedSetGroup,
): boolean => performedSetGroup.every(isCompleteSet);

export const isCompleteTrainingRoutine = (
  trainingRoutineSession: TrainingRoutineSession,
): boolean => {
  return trainingRoutineSession.status === Status.Complete;
};

export const isGroupSetActive = (
  performedSetGroup: PerformedSetGroup,
): boolean => {
  return performedSetGroup.some(isActiveSet);
};

export const hasPendingSet = (sets: PerformedSetGroup): boolean => {
  return sets.some(isPendingSet);
};

export const hasPendingSetInGroup = (
  performedCircuit: PerformedCircuit,
): boolean => {
  return performedCircuit.some(hasPendingSet);
};

export const startExerciseGroup = ({ sets }: PerformedExerciseGroup): void => {
  const firstPendingGroupSet = sets.find((setGroup) =>
    setGroup.some(isPendingSet),
  );
  if (!firstPendingGroupSet) {
    return;
  }
  const firstPendingSet = firstPendingGroupSet.find(isPendingSet);
  if (!firstPendingSet) {
    return;
  }
  firstPendingSet.status = Status.Active;
  firstPendingSet.startedTimestamp = Date.now();
};

export const isCompletedGroupSets = (
  performedCircuit: PerformedCircuit,
): boolean => {
  return performedCircuit.every(isCompleteGroupSet);
};

export const isCompleteExerciseGroup = (
  exerciseGroup: PerformedExerciseGroup,
): boolean => {
  return isCompletedGroupSets(exerciseGroup.sets);
};

export const pauseExerciseGroup = (
  performedExerciseGroup: PerformedExerciseGroup,
): void => {
  const allActiveSets = getAllSets([performedExerciseGroup]).filter(
    isActiveSet,
  );
  allActiveSets.forEach((set) => {
    set.status = Status.Pending;
    set.startedTimestamp = NaN;
  });
};

const getNextExerciseGroupIndex = ({
  performedExerciseGroups,
  currentExerciseGroupIndex,
}: PerformedRoutine): number => {
  const nextIndexGroupCandidate =
    performedExerciseGroups[currentExerciseGroupIndex + 1];
  if (nextIndexGroupCandidate) {
    return currentExerciseGroupIndex + 1;
  }
  return performedExerciseGroups.findIndex(isPerformedExerciseGroupPending);
};

export const applyNextSetExerciseGroupActive = (
  trainingRoutineSession: TrainingRoutineSession,
): TrainingRoutineSession | null => {
  const clonedTrainingRoutineSession = _.cloneDeep(trainingRoutineSession);
  const newCurrentExerciseGroupIndex = getNextExerciseGroupIndex(
    clonedTrainingRoutineSession.performedRoutine,
  );
  const nextExerciseGroupCandidate =
    clonedTrainingRoutineSession.performedRoutine.performedExerciseGroups[
      newCurrentExerciseGroupIndex
    ];

  if (!nextExerciseGroupCandidate) {
    return null;
  }

  startExerciseGroup(nextExerciseGroupCandidate);
  clonedTrainingRoutineSession.performedRoutine.currentExerciseGroupIndex =
    newCurrentExerciseGroupIndex;
  clonedTrainingRoutineSession.performedRoutine.currentGroupSetIndex =
    getNextActiveGroupSetIndex(nextExerciseGroupCandidate.sets);
  return clonedTrainingRoutineSession;
};

export const isActiveExerciseGroup = ({
  sets,
}: PerformedExerciseGroup): boolean => {
  return sets.some((superSet) => superSet.some(isActiveSet));
};

export const isLoggedGroupSets = (sets: PerformedSetGroup): boolean => {
  return sets.some(isCompleteSet);
};

export const isPerformedExerciseGroupPending = ({
  sets,
}: PerformedExerciseGroup): boolean => {
  return sets.some(isPendingSets);
};

export const getExerciseIdFromSets = (
  sets: SetGroup | PerformedSetGroup,
): string => {
  return sets[0]?.exerciseId || '';
};

export const getAllSets = (
  performedExerciseGroups: PerformedExerciseGroup[],
): PerformedSetGroup => {
  return performedExerciseGroups
    .map(({ sets }) => sets)
    .flat()
    .flat();
};

export const getAllTrainingSets = (
  exerciseGroups: ExerciseGroup[],
): SetGroup => {
  return exerciseGroups
    .map(({ sets }) => sets)
    .flat()
    .flat();
};

export const getTrainingSessionExerciseCount = ({
  performedRoutine,
}: TrainingRoutineSession): string => {
  const finishedExerciseGroups =
    performedRoutine.performedExerciseGroups.filter(({ sets }) =>
      sets.some((superSet) => superSet.some(isCompleteSet)),
    );
  return finishedExerciseGroups.length.toString();
};

export const getRandomDescription = (): string => {
  return _.sample(hardcodedDescriptions) as string;
};

export const getActiveSetIndex = (sets: PerformedSetGroup): number => {
  return sets.findIndex(isActiveSet);
};

export const getActiveGroupSetIndex = (
  performedCircuit: PerformedCircuit,
): number => {
  return performedCircuit.findIndex(isGroupSetActive);
};

export const makeAllActiveSetsPending = (
  performedCircuit: PerformedCircuit,
): void => {
  performedCircuit.forEach((sets) =>
    sets.forEach((set) => {
      if (isActiveSet(set)) {
        set.status = Status.Pending;
      }
    }),
  );
};

export const getNextActiveGroupSetIndex = (
  performedCircuit: PerformedCircuit,
): number => {
  if (performedCircuit.length === 0) return 0;

  let minCompletedSets = Infinity;
  let resultIndex = 0;

  for (let i = 0; i < performedCircuit.length; i++) {
    const completedCount = performedCircuit[i].filter(isCompleteSet).length;
    const hasNonCompletedSet = performedCircuit[i].some(
      (set) => !isCompleteSet(set),
    );

    if (hasNonCompletedSet && completedCount < minCompletedSets) {
      minCompletedSets = completedCount;
      resultIndex = i;
    }
  }

  return resultIndex;
};

export const getStationByExerciseId = (
  stations: Record<string, Station>,
  exercises: Record<string, Exercise>,
  exerciseId: string,
): Station | null => {
  const preferredStationId = exercises[exerciseId]?.preferredStationIds?.[0];
  const preferedStation = stations[preferredStationId];
  if (preferedStation) {
    return preferedStation;
  }
  return (
    Object.values(stations).find((station) =>
      station?.exerciseIds?.includes(exerciseId),
    ) || null
  );
};

export const getNextPendingExerciseGroupIndex = ({
  performedRoutine,
}: TrainingRoutineSession): number => {
  return performedRoutine.performedExerciseGroups.findIndex(
    (performedExerciseGroup) =>
      performedExerciseGroup.sets.every(isPendingSets),
  );
};

const getLastCompletedSet = ({
  sets,
}: PerformedExerciseGroup): PerformedSet | null => {
  const lastCompletedGroupSet = sets.find((performedSetGroup) =>
    performedSetGroup.some(isCompleteSet),
  );
  if (!lastCompletedGroupSet) {
    return null;
  }
  return lastCompletedGroupSet.find(isCompleteSet) || null;
};

const shouldShowRest = (performedRoutine: PerformedRoutine): boolean => {
  const currentExerciseGroup = getCurrentExerciseGroup(performedRoutine);
  if (!currentExerciseGroup) {
    return false;
  }

  const lastCompletedSet = getLastCompletedSet(currentExerciseGroup);
  if (!lastCompletedSet) {
    return false;
  }
  const restDurationInMs = lastCompletedSet.rest * 1000;
  return Date.now() - lastCompletedSet.finishedTimestamp < restDurationInMs;
};

const getCurrentExerciseGroup = ({
  performedExerciseGroups,
  currentExerciseGroupIndex,
}: PerformedRoutine) => performedExerciseGroups[currentExerciseGroupIndex];

export const getSyncedTrainingSession = (
  trainingRoutineSession: TrainingRoutineSession,
): TrainingRoutineSession => {
  const clonedTrainingRoutineSession = _.cloneDeep(trainingRoutineSession);
  const { performedRoutine } = clonedTrainingRoutineSession;
  const currentExerciseGroup = getCurrentExerciseGroup(performedRoutine);
  if (!_.isNumber(performedRoutine.currentGroupSetIndex)) {
    let nextCurrentGroupSetIndex = 0;
    if (currentExerciseGroup) {
      nextCurrentGroupSetIndex = getNextActiveGroupSetIndex(
        currentExerciseGroup.sets,
      );
    }
    performedRoutine.currentGroupSetIndex = nextCurrentGroupSetIndex;
  }
  if (shouldShowRest(performedRoutine)) return clonedTrainingRoutineSession;

  if (isActiveExerciseGroup(currentExerciseGroup))
    return clonedTrainingRoutineSession;

  if (isCompleteExerciseGroup(currentExerciseGroup)) {
    const nextPendingExerciseGroupIndex = getNextPendingExerciseGroupIndex(
      clonedTrainingRoutineSession,
    );
    if (nextPendingExerciseGroupIndex === -1) {
      return clonedTrainingRoutineSession;
    }
    performedRoutine.currentExerciseGroupIndex = nextPendingExerciseGroupIndex;
    startExerciseGroup(currentExerciseGroup);
    return clonedTrainingRoutineSession;
  }

  const currentGroupSet = currentExerciseGroup.sets.find(hasPendingSet);
  if (currentGroupSet) {
    const nextSetToStart = currentGroupSet.find(isPendingSet);
    if (nextSetToStart) {
      nextSetToStart.status = Status.Active;
      nextSetToStart.startedTimestamp = Date.now();
    }
  }
  return clonedTrainingRoutineSession;
};

export const getInitialRest = ({
  performedRoutine,
}: TrainingRoutineSession): Rest | null => {
  const currentExerciseGroup =
    performedRoutine.performedExerciseGroups[
      performedRoutine.currentExerciseGroupIndex
    ];
  if (!currentExerciseGroup || !shouldShowRest(performedRoutine)) {
    return null;
  }

  // TODO: fix impl for super set
  const groupSetIndex = 0;

  const lastCompleteSetIndex = _.findLastIndex(
    currentExerciseGroup.sets[groupSetIndex],
    isCompleteSet,
  );
  if (lastCompleteSetIndex < 0) {
    return null;
  }
  const lastCompleteSet =
    currentExerciseGroup.sets[groupSetIndex][lastCompleteSetIndex];
  const restDurationInMs = lastCompleteSet.rest * 1000;
  return {
    groupSetIndex,
    setIndex: lastCompleteSetIndex,
    isRunning: true,
    currentRestInMs:
      restDurationInMs - (Date.now() - lastCompleteSet.finishedTimestamp),
    totalRestInMs: restDurationInMs,
  };
};

export const getUniqueExerciseIdsListFromRoutine = (
  routine: TrainingRoutine | PerformedRoutine,
): string[] => {
  const exerciseGroups =
    (routine as TrainingRoutine).exerciseGroups ||
    (routine as PerformedRoutine).performedExerciseGroups;
  return [
    ...new Set(
      exerciseGroups
        .map((exerciseGroup) =>
          exerciseGroup.sets
            .map((performedCircuit) =>
              performedCircuit.map(({ exerciseId }) => exerciseId),
            )
            .flat(),
        )
        .flat(),
    ),
  ];
};

export const shiftMemberRoutines = (
  trainingRoutines: TrainingRoutine[],
): void => {
  const firstRoutine = trainingRoutines.shift();
  if (firstRoutine) {
    trainingRoutines.push(firstRoutine);
  }
};

export const getParentMuscleGroups = (
  exerciseIds: string[],
  exercises: Record<string, Exercise>,
  muscles: Record<string, Muscle>,
): string[] => {
  return [
    ...new Set(
      exerciseIds
        .map((id) =>
          exercises[id]?.primaryMuscleGroupIds?.map(
            (id) => muscles[id]?.parentGroup,
          ),
        )
        .flat(),
    ),
  ];
};

export const isNumeric = (value: any): value is number =>
  typeof value === 'number' && !isNaN(value);

export const hasWeekPassed = (lastDate: string | null | undefined): boolean => {
  if (lastDate === null || lastDate === undefined || lastDate === '') {
    return false;
  }
  const pastDate = new Date(lastDate);
  const currentDate = new Date();
  const differenceInMilliseconds = currentDate.getTime() - pastDate.getTime();
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const isWeekPassed = differenceInMilliseconds >= oneWeekInMilliseconds;
  return isWeekPassed;
};

export const capitalizeString = (input: string): string => {
  if (!input) return input;
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export const generateNewPin = (members: Member[]): string => {
  const existingPins = members.map(({ pin }) => pin);
  const newPin = Math.floor(Math.random() * 9000) + 1000;
  if (existingPins.includes(newPin.toString())) {
    return generateNewPin(members);
  }
  return newPin.toString();
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
};

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const regex = /^(?:\+\d{1,3})?[0-9]\d{9}$/;
  return regex.test(phoneNumber.replace(/[\s-]/g, ''));
};

const findLastPerformedExerciseInHistory = (
  exerciseId: string,
  historyTrainingSessions: TrainingRoutineSession[],
): PerformedSetGroup | null => {
  const sortedHistoryTrainingSessions = historyTrainingSessions.sort(
    (a, b) =>
      new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime(),
  );
  for (const trainingRoutineSession of sortedHistoryTrainingSessions) {
    const { performedExerciseGroups } = trainingRoutineSession.performedRoutine;
    for (const performedExerciseGroup of performedExerciseGroups) {
      for (const performedCircuit of performedExerciseGroup.sets) {
        if (performedCircuit.some((set) => set.exerciseId === exerciseId)) {
          return performedCircuit;
        }
      }
    }
  }
  return null;
};

const findBestPerformedExerciseInHistoryTrainingSessions = (
  exerciseId: string,
  historyTrainingSessions: TrainingRoutineSession[],
): PerformedSet | null => {
  const sortedHistoryTrainingSessions = historyTrainingSessions.sort(
    (a, b) =>
      new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime(),
  );
  const lastPerformedSet = findLastPerformedExerciseInHistory(
    exerciseId,
    sortedHistoryTrainingSessions,
  )?.sort((a, b) => b.performedWeight! - a.performedWeight!)?.[0];
  return lastPerformedSet || null;
};

export const createPerformedExerciseGroupFromExerciseAndHistory = (
  exercise: Exercise,
  historyTrainingSessions: TrainingRoutineSession[],
  shouldSetActive = false,
  numberOfInternalSets = 1,
): PerformedExerciseGroup => {
  const bestPerformedExercise =
    findBestPerformedExerciseInHistoryTrainingSessions(
      exercise.id,
      historyTrainingSessions,
    );
  const performedSet = bestPerformedExercise
    ? performedSetToNewPerformedSet(bestPerformedExercise)
    : exerciseToNewPerformedSet(exercise);

  const performedSets = Array(numberOfInternalSets)
    .fill({})
    .map(() => ({ ...performedSet }));

  if (shouldSetActive) {
    performedSets[0].status = Status.Active;
    performedSets[0].startedTimestamp = Date.now();
  }

  return {
    name: exercise.name,
    sets: [[...performedSets]],
  };
};

export const createExerciseGroupFromPerformedExerciseGroup = (
  performedExerciseGroup: PerformedExerciseGroup,
): ExerciseGroup => {
  return {
    name: performedExerciseGroup.name,
    sets: performedExerciseGroup.sets.map((sets) =>
      sets.map(performedSetToSet),
    ),
  };
};

export const createTrainingRoutineSessionFromExerciseAndHistory = (
  member: Member,
  exercise: Exercise,
  historyTrainingSessions: TrainingRoutineSession[],
): TrainingRoutineSession => {
  const performedExerciseGroup =
    createPerformedExerciseGroupFromExerciseAndHistory(
      exercise,
      historyTrainingSessions,
    );
  const name = member.firstName + ' Training Day';
  const estimatedDuration = 60;
  return {
    name,
    id: '',
    memberId: member.id,
    status: Status.Active,
    routine: {
      name,
      description: '',
      exerciseGroups: [],
      estimatedDuration,
    },
    performedRoutine: {
      name,
      performedDuration: NaN,
      exerciseExertionRates: [],
      currentExerciseGroupIndex: 0,
      currentGroupSetIndex: 0,
      performedExerciseGroups: [performedExerciseGroup],
    },
    createdDate: new Date().toISOString(),
  };
};

export const getExerciseIdsFromTrainingRoutineSessions = (
  trainingRoutineSessions: TrainingRoutineSession[],
): string[] => {
  const allExerciseIds = trainingRoutineSessions.reduce(
    (acc, { performedRoutine }) => [
      ...acc,
      ...getUniqueExerciseIdsListFromRoutine(performedRoutine),
    ],
    [] as string[],
  );
  return [...new Set(allExerciseIds)];
};

export const memberAllowedToSendEmail = (member: Member): boolean => {
  return !member.preferences?.unsubscribeFromAllEmails;
};

type NewMemberRequiredValues = {
  firstName: Member['firstName'];
  lastName: Member['lastName'];
  pin: Member['pin'];
  email: Member['email'];
  phoneNumber: Member['phoneNumber'];
};

export const getNewMember = (
  memberOverrides: NewMemberRequiredValues &
    Partial<Omit<Member, keyof NewMemberRequiredValues>>,
  signUpPreferences: SignUpPreferences = {},
  initialPreferences: InitialPreferences = {},
): Member => {
  return {
    id: '',
    dateOfBirth: '',
    signedDate: new Date(),
    trainerIds: [],
    gender: null,
    weeklyWorkouts: 0,
    isActive: true,
    fitnessGoal: '',
    hasAgreedToWeightIn: false,
    preferences: {
      hasAgreedToWeightIn: false,
      firstTimeWeighIn: true,
      hasSeenLibraryWelcomeModal: false,
      hasSeenTrackingWelcomeModal: false,
      hasSeenProgrammedWelcomeModal: false,
      initialPreferences: { ...initialPreferences },
      signUp: { ...signUpPreferences, version: '1.0.104' },
    },
    targetWeight: 0,
    weighIn: [],
    height: 0,
    gymIds: [],
    groupIds: [],
    trainingRoutines: [],
    hasAgreedToTerms: false,
    leadStatus: 'awaiting',
    ...memberOverrides,
  };
};

export const getUniqueMuscleGroupsNames = (
  exercises: Exercise[],
  muscles: Record<string, Muscle>,
): string[] => {
  const muscleGroupsNames = exercises
    .reduce(
      (acc, { primaryMuscleGroupIds }) => [
        ...acc,
        ...primaryMuscleGroupIds.map((id) => muscles[id]?.name),
      ],
      [] as (string | undefined)[],
    )
    .filter(Boolean) as string[];
  return [...new Set(muscleGroupsNames)];
};

export const strToOptionObj = (value: string, placeholder?: string) => ({
  label: value || placeholder || '',
  value,
});

export const getExerciseGroupsFromRoutine = (
  routine: TrainingRoutine | PerformedRoutine,
): PerformedExerciseGroup[] | ExerciseGroup[] => {
  if (isPerformedRoutine(routine)) {
    return routine.performedExerciseGroups;
  }
  return (routine as TrainingRoutine).exerciseGroups;
};

export const getWorkoutImageUrl = (
  exercises: Record<string, Exercise>,
  routine: TrainingRoutine | PerformedRoutine,
  shouldRandomizeImage: boolean = false,
): string => {
  const exerciseGroups = getExerciseGroupsFromRoutine(routine);
  const randomExerciseIndex = shouldRandomizeImage
    ? Math.floor(Math.random() * exerciseGroups.length)
    : 0;
  const { sets } = exerciseGroups[randomExerciseIndex];
  if (!sets) {
    const firstGroup = exerciseGroups[0]?.sets;
    return exercises[firstGroup[0][0]?.exerciseId]?.imageUrl;
  }
  return exercises[sets[0][0]?.exerciseId]?.imageUrl;
};

export const getWorkoutVideoUrl = (
  exercises: Record<string, Exercise>,
  routine: TrainingRoutine | PerformedRoutine,
  shouldRandomizeVideo: boolean = false,
): string => {
  const exerciseGroups = getExerciseGroupsFromRoutine(routine);
  const randomExerciseIndex = shouldRandomizeVideo
    ? Math.floor(Math.random() * exerciseGroups.length)
    : 0;
  const { sets } = exerciseGroups[randomExerciseIndex];
  if (!sets) {
    const firstGroup = exerciseGroups[0]?.sets;
    return exercises[firstGroup[0][0]?.exerciseId]?.videoUrl;
  }
  return exercises[sets[0][0]?.exerciseId]?.videoUrl;
};

export const getWorkoutContentUrls = (
  exercises: Record<string, Exercise>,
  routine: TrainingRoutine | PerformedRoutine,
  shouldRandomize: boolean = false,
): { imageUrl: string; videoUrls: string[] } => {
  const filteredExercises = Object.fromEntries(
    Object.entries(exercises).filter(([key, value]) => value.isPersonalized),
  );
  const exerciseGroups = getExerciseGroupsFromRoutine(routine);

  let exercise;
  let tries = 0;
  const maxTries = 3;

  while (tries < maxTries) {
    const randomExerciseIndex = shouldRandomize
      ? Math.floor(Math.random() * exerciseGroups.length)
      : 0;

    const { sets } = exerciseGroups[randomExerciseIndex];

    exercise = filteredExercises[sets[0][0]?.exerciseId];

    if (exercise) {
      break;
    }

    tries += 1;
  }

  if (!exercise) {
    const randomExerciseIndex = shouldRandomize
      ? Math.floor(Math.random() * exerciseGroups.length)
      : 0;

    const { sets } = exerciseGroups[randomExerciseIndex];
    exercise = exercises[sets[0][0]?.exerciseId];
  }

  const videoUrls = [exercise?.videoUrl];

  return {
    videoUrls: videoUrls.filter(Boolean) as string[],
    imageUrl: exercise?.imageUrl,
  };
};

export function getDayNameFromDate(dateString: string): string {
  // Array of day names, corresponding to the getDay() method's return values (0-6)
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Get the day of the week as a number (0-6) and use it to retrieve the corresponding day name
  const dayName = dayNames[date.getDay()];

  // Return the day name
  return dayName;
}

// Main function to process an array of objects with createdDate properties
export function extractDayNamesFromDates(dates: string[]): string[] {
  // Map over the array of objects, extracting and transforming each createdDate to its day name
  return dates.map((date) => getDayNameFromDate(date));
}

export function filterDatesLast7Days(dates: string[]): string[] {
  // Get today's date and time
  const today = new Date();

  // Get the date and time 7 days ago from today
  const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

  // Filter the array to only include dates within the last 7 days
  return dates.filter((dateString) => {
    // Convert the dateString to a Date object
    const date = new Date(dateString);

    // Check if the date is within the last 7 days
    return date >= sevenDaysAgo && date <= today;
  });
}

export function filterDatesSinceLastMonday(dates: string[]): string[] {
  // Get today's date
  const today = new Date();

  // Calculate the difference between today and the last Monday
  // Note: getDay() returns 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  const dayOfWeek = today.getDay();
  const daysSinceMonday = (dayOfWeek + 6) % 7; // Adjusting Sunday's value and ensuring positive result

  // Calculate last Monday's date
  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - daysSinceMonday);
  lastMonday.setHours(0, 0, 0, 0); // Set time to 00:00:00 for comparison

  // Filter the array to only include dates on or after last Monday
  return dates.filter((dateString) => {
    const date = new Date(dateString);
    return date >= lastMonday;
  });
}

export function formatDate(dateString: string): string {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const monthsOfYear = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Convert the ISO string to a Date object
  const date = new Date(dateString);

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Function to format the day of the month with the correct suffix
  function getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return 'th'; // for teens
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  // Combine the parts into the final string
  return `${dayOfWeek}, ${month} ${dayOfMonth}${getDaySuffix(dayOfMonth)}`;
}

const getDifferenceInDays = (date1: Date, date2: Date): number => {
  return (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24);
};

export const shouldAllowBySubscriptionFreeTrial = (
  currentGymProduct: GymProduct | null,
  gymId: string,
  homeContentRoute: HomeContentRoute,
  member: Member,
): boolean => {
  const currentDate = new Date();
  if (
    homeContentRoute === HomeContentRoute.Library &&
    GYMS_TO_FREE_TRAIAL_FOR_LIBRARY_ON_TRACKING_SUBSCRIPTION.includes(gymId)
  ) {
    const trackingSubscription = member.subscriptions?.find(
      ({ product }) => product === Product.Tracking,
    );
    if (
      // trackingSubscription?.startDate ||
      trackingSubscription?.source === 'Gym'
    ) {
      const trackingStartDate = new Date(
        trackingSubscription.startDate || member.createdDate || '',
      );
      const differenceInDays = getDifferenceInDays(
        currentDate,
        trackingStartDate,
      );
      return differenceInDays < 14;
    }
  }
  if (currentGymProduct?.freeTrialDays) {
    const productCreatedDate = new Date(currentGymProduct.createdDate);
    const differenceInDays = getDifferenceInDays(
      currentDate,
      productCreatedDate,
    );
    return differenceInDays < currentGymProduct.freeTrialDays;
  }
  return false;
};

export const findMiddleNumbers = (
  start: number,
  end: number,
): [number, number] => {
  const thirdOfDistance = (end - start) / 3;
  const firstMiddle = start + thirdOfDistance;
  const secondMiddle = start + 2 * thirdOfDistance;
  return [Math.floor(firstMiddle), Math.floor(secondMiddle)];
};

export const isProductWithFreeTrialOption = (
  currentGymProduct: GymProduct | null,
): boolean => {
  return !!currentGymProduct?.freeTrialDays;
};

function getWeeksInMonthWithRealDays(
  year: number,
  month: number,
  startDate: Date,
): number[][] {
  const weeks: number[][] = [];
  const firstDayOfMonth = new Date(year, month, 1);
  let currentDate = new Date(firstDayOfMonth);

  const calendarStartDate = new Date(startDate) || new Date();

  // Adjust the first day of the week to Monday (if Sunday, shift it to the end)
  const firstDayOfWeek =
    currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1;
  let currentWeek: number[] = [];

  // Handle the days from the previous month if needed
  if (firstDayOfWeek > 0) {
    const previousMonthLastDay = new Date(year, month, 0).getDate(); // Last day of the previous month
    for (let i = firstDayOfWeek - 1; i >= 0; i--) {
      currentWeek.push(previousMonthLastDay - i);
    }
  }

  // Fill the current month days into the weeks
  while (currentDate.getMonth() === month) {
    const day = currentDate.getDate();
    currentWeek.push(day);

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);

    // If the current week has 7 days, push it to the weeks array and start a new week
    if (currentWeek.length === 7) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
  }

  // If there are remaining days in the current week (which means we crossed into the next month)
  if (currentWeek.length > 0) {
    let nextMonthDay = 1;
    while (currentWeek.length < 7) {
      currentWeek.push(nextMonthDay++);
    }
    weeks.push(currentWeek);
  }

  // Remove weeks prior to the current week if the current month matches to start date
  const isStartDateInCurrentMonth =
    calendarStartDate.getMonth() === month &&
    calendarStartDate.getFullYear() === year;

  if (isStartDateInCurrentMonth) {
    let currentWeekIndex = weeks.findIndex((week) =>
      week.includes(calendarStartDate.getDate()),
    );
    const isWeekWithPrevMonthSimilarStartDate =
      currentWeekIndex === 0 && calendarStartDate.getDate() > 6;

    if (isWeekWithPrevMonthSimilarStartDate) {
      weeks.shift();
      currentWeekIndex = weeks.findIndex((week) =>
        week.includes(calendarStartDate.getDate()),
      );
    }
    return weeks.slice(currentWeekIndex);
  }

  return weeks;
}

export function getYearAheadCalendarWithRealDays(
  startDate: Date,
): number[][][] {
  const result: number[][][] = [];
  let calendarStartDate = new Date(startDate);

  // Set the day to 1 to prevent month overflow issues
  calendarStartDate.setDate(1);

  // Generate the weeks for 12 months starting from the given startDate
  for (let i = 0; i < 12; i++) {
    const year = calendarStartDate.getFullYear();
    const month = calendarStartDate.getMonth();
    const weeksInMonth = getWeeksInMonthWithRealDays(year, month, startDate);
    result.push(weeksInMonth);

    // Move to the next month
    calendarStartDate.setMonth(calendarStartDate.getMonth() + 1);
  }

  return result;
}

export const getNextDay = (programItems: ProgramItem[]): Day | null => {
  // Filter out null and undefined days
  const validDays = programItems
    .map((item) => item.day)
    .filter((day): day is Day => day !== null && day !== undefined);

  if (validDays.length === 0) {
    return Day.Monday; // Return Monday if no valid day is found
  }

  // Find the maximum day based on the order in daysOrder
  const biggestDay = validDays.reduce((maxDay, currentDay) => {
    return daysOrder.indexOf(currentDay) > daysOrder.indexOf(maxDay)
      ? currentDay
      : maxDay;
  });

  // If the biggest day is Sunday, return null
  if (biggestDay === Day.Sunday) {
    return null;
  }

  // Return the next day in the daysOrder array
  const nextDayIndex = daysOrder.indexOf(biggestDay) + 1;
  return daysOrder[nextDayIndex];
};
