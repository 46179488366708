import stateTypes from './stateTypes';
import type {
  Exercise,
  Group,
  Gym,
  GymProduct,
  Manager,
  Member,
  Muscle,
  Program,
  Station,
  Trainer,
  TrainingRoutineTemplate,
} from '../types';

const {
  SET_RELATED_GYMS,
  SET_EXERCISES,
  SET_MERGED_EXERCISES,
  SET_TEMPLATES,
  SET_MUSCLES,
  SET_STATIONS,
  RESET_ALL_STATE,
  SET_LOGIN_MEMBER,
  SET_LOGIN_MANAGER,
  SET_LOGIN_TRAINER,
  SET_GYM_DATA,
  SET_SELECTED_GYM_ID,
} = stateTypes;

export const setRelatedGyms = (relatedGyms: Gym[]) => ({
  type: SET_RELATED_GYMS,
  relatedGyms,
});

export const setExercises = (exercises: Record<string, Exercise>) => ({
  type: SET_EXERCISES,
  exercises,
});

export const setMergedExercises = (
  mergedExercises: Record<string, Exercise>,
) => ({
  type: SET_MERGED_EXERCISES,
  mergedExercises,
});

export const setLoginMember = (member: Member) => ({
  type: SET_LOGIN_MEMBER,
  member,
});

export const setLoginTrainer = (
  trainer: Trainer,
  personalizedExercises: Exercise[],
  templates: TrainingRoutineTemplate[],
  programs: Program[],
) => ({
  type: SET_LOGIN_TRAINER,
  trainer,
  programs,
  templates,
  personalizedExercises,
});

export const setLoginManager = (manager: Manager) => ({
  type: SET_LOGIN_MANAGER,
  manager,
});

export const setTemplates = (templates: TrainingRoutineTemplate[]) => ({
  type: SET_TEMPLATES,
  templates,
});

export const setMuscles = (muscles: Record<string, Muscle>) => ({
  type: SET_MUSCLES,
  muscles,
});

export const setStations = (stations: Record<string, Station>) => ({
  type: SET_STATIONS,
  stations,
});

export const setSelectedGymId = (selectedGymId: string | null) => ({
  type: SET_SELECTED_GYM_ID,
  selectedGymId,
});

export const setGymData = (
  gymId: string,
  members: Member[],
  trainers: Trainer[],
  managers: Manager[],
  gymProducts: GymProduct[],
  groups: Group[],
) => ({
  type: SET_GYM_DATA,
  gymId,
  members,
  trainers,
  managers,
  gymProducts,
  groups,
});

export const resetAllState = () => ({
  type: RESET_ALL_STATE,
});
