import React, { useState } from 'react';
import styles from './UserRow.module.scss';
import classNames from 'classnames';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { getFormalDateString, getFullName } from '../../utils/dashboardUtils';
import Menu from '../atoms/Menu';
import ConfirmationDialog from '../DialogWrappers/ConfirmationDialog';
import AssignProgramOptionsDialog from '../Programs/AssignProgramOptionsDialog';
import AssignProgramDialog from '../Programs/AssignProgramDialog';
import { assignProgramToMembers } from '../../api';
import { UserType } from '../../constants';
import { useRefreshState } from '../../providers';

import type { AssignedProgram, Group, Member, Trainer } from '../../types';

interface UserRowProps {
  groups: Group[];
  selected: boolean;
  onSelect: () => void;
  member: Member;
  trainer: Trainer | undefined;
  openEditClientModal: () => void;
  archiveMember: (member: Member) => Promise<void>;
  userType: UserType;
  onEditProgram: () => void;
  onCreateNewProgram: () => void;
}

const UserRow: React.FC<UserRowProps> = ({
  groups,
  userType,
  member,
  trainer,
  onSelect,
  selected,
  archiveMember,
  openEditClientModal,
  onEditProgram,
  onCreateNewProgram,
}) => {
  const refreshState = useRefreshState();
  const [isDeleteClientConfirmationOpen, setIsDeleteClientConfirmationOpen] =
    useState(false);
  const [isAssignProgramModalOpen, setIsAssignProgramModalOpen] =
    useState(false);
  const [isAddProgramTemplateModalOpen, setIsAddProgramTemplateModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const handleEditClient = () => {
    openEditClientModal();
    handleCloseMenuOptions();
  };

  const handleDeleteClient = async () => {
    handleCloseMenuOptions();
    await archiveMember(member);
    setIsDeleteClientConfirmationOpen(false);
  };

  const renderUserName = () => (
    <div className={classNames(styles.userColumn, styles.userName)}>
      <AccountCircleOutlinedIcon className={styles.userIcon} color="primary" />
      {getFullName(member)}
    </div>
  );
  const renderDate = () => {
    if (!member.createdDate || userType === 'Athlete') return null;
    return (
      <div className={styles.userColumn}>
        {`${getFormalDateString(new Date(member.createdDate))}`}
      </div>
    );
  };
  const renderClientProgram = () => (
    <div className={styles.userColumn}>{member.program?.name || ''}</div>
  );

  const renderTrainerName = () => {
    const trainerName = getFullName(trainer);
    return <div className={styles.userColumn}>{trainerName}</div>;
  };

  const renderSubscription = () => {
    if (userType === UserType.Athlete) return null;
    return (
      <div className={styles.userColumn}>
        {member.subscriptions?.[0]?.product && (
          <div className={styles.userColumnBox}>
            {member.subscriptions?.[0]?.product}
          </div>
        )}
      </div>
    );
  };

  const renderMemberGroup = () => {
    if (userType !== UserType.Athlete) return null;
    const groupName =
      groups.find(({ id }) => id === member.groupIds[0])?.name || '';
    return <div className={styles.userColumn}>{groupName}</div>;
  };

  const handleAssignProgramFromTemplate = async (program: AssignedProgram) => {
    await assignProgramToMembers([member.id], program);
    await refreshState();
    setIsAddProgramTemplateModalOpen(false);
  };

  const renderAssignProgramOptionsDialog = () => {
    if (!isAssignProgramModalOpen) return null;
    return (
      <AssignProgramOptionsDialog
        onCreate={() => {
          setIsAssignProgramModalOpen(false);
          onCreateNewProgram();
        }}
        onAdd={() => {
          setIsAssignProgramModalOpen(false);
          setIsAddProgramTemplateModalOpen(true);
        }}
        onClose={() => setIsAssignProgramModalOpen(false)}
      />
    );
  };

  const renderProgramTemplateDialog = () => (
    <AssignProgramDialog
      onSubmit={handleAssignProgramFromTemplate}
      isOpen={isAddProgramTemplateModalOpen}
      onClose={() => setIsAddProgramTemplateModalOpen(false)}
    />
  );

  const renderDeleteClientConfirmationDialog = () => (
    <ConfirmationDialog
      text={`Are you sure you want to delete this ${userType.toLowerCase()}?`}
      onSubmit={handleDeleteClient}
      submitText="Yes"
      cancelText="No"
      isOpen={isDeleteClientConfirmationOpen}
      onCancel={() => setIsDeleteClientConfirmationOpen(false)}
      symbol={<DeleteForeverOutlinedIcon />}
    />
  );

  const getUserOptions = () => {
    return [
      {
        onClick: handleEditClient,
        label: `Edit ${userType} Details`,
        icon: <BorderColorOutlinedIcon color="primary" />,
      },
      {
        onClick: () => setIsDeleteClientConfirmationOpen(true),
        label: `Delete ${userType}`,
        icon: <DeleteForeverOutlinedIcon color="primary" />,
      },
      {
        onClick: () => setIsAssignProgramModalOpen(true),
        label: 'Assign Program',
        icon: <PersonAddAlt1OutlinedIcon color="primary" />,
      },
    ];
  };

  const getEnrichedUserOptions = () => {
    const options = getUserOptions();
    if (member.program) {
      options.push({
        onClick: () => {
          handleCloseMenuOptions();
          onEditProgram();
        },
        label: 'Edit Program',
        icon: <AppRegistrationIcon color="primary" />,
      });
    }
    return options;
  };

  const renderPin = () => <div className={styles.userColumn}>{member.pin}</div>;

  const renderMoreOptions = () => (
    <div className={classNames(styles.userColumn, styles.options)}>
      <PendingOutlinedIcon
        // @ts-ignore
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={styles.optionsBtn}
        color="primary"
      />
      <Menu
        anchorEl={anchorEl}
        isOpen={open}
        onClose={handleCloseMenuOptions}
        options={getEnrichedUserOptions()}
      />
    </div>
  );

  return (
    <div
      onClick={onSelect}
      className={classNames(
        styles.userRow,
        userType === UserType.Athlete
          ? styles.athleteListGrid
          : styles.clientListGrid,
        {
          [styles.selected]: selected,
        },
      )}
    >
      {renderUserName()}
      {renderDate()}
      {renderClientProgram()}
      {renderTrainerName()}
      {renderSubscription()}
      {renderMemberGroup()}
      {renderPin()}
      {renderMoreOptions()}
      {renderDeleteClientConfirmationDialog()}
      {renderAssignProgramOptionsDialog()}
      {renderProgramTemplateDialog()}
    </div>
  );
};

export default UserRow;
