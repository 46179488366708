import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import styles from './DashboardContentWrapper.module.scss';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import AccountIcon from './AccountIcon';
import SearchBar from '../atoms/SearchBar';
import classNames from 'classnames';

export interface DashboardContentProps {
  title: string;
  searchPlaceholder: string;
  setSearchQuery: (query: string) => void;
  actions: Array<{
    label: string;
    icon?: React.ReactNode;
    onClick: () => void;
  }>;
  children?: ReactNode;
  drillInContent?: ReactNode;
  onBack?: () => void;
  isEditMode?: boolean;
  isContentWrapped?: boolean;
}

const DashboardContentWrapper: React.FC<DashboardContentProps> = ({
  title,
  searchPlaceholder,
  setSearchQuery,
  actions,
  children,
  drillInContent,
  onBack,
  isEditMode,
  isContentWrapped,
}) => {
  const renderHeader = () => (
    <div className={styles.dashboardContentHeader}>
      <div className={styles.dashboardTitleWrapper}>
        {drillInContent && (
          <Button onClick={onBack} className={styles.backButton}>
            <ArrowBackIosNewOutlinedIcon color="primary" />
          </Button>
        )}
        <span className={styles.title}>{title}</span>
      </div>
      <AccountIcon />
    </div>
  );

  const renderActionSection = () => (
    <div className={styles.listActionsWrapper}>
      <SearchBar
        placeholder={searchPlaceholder}
        setSearchQuery={setSearchQuery}
      />
      <div className={styles.actionItems}>
        {actions.map(({ label, icon, onClick }) => (
          <Button key={label} className={styles.addClientBtn} onClick={onClick}>
            {icon}
            <span className={styles.addClientBtnText}>{label}</span>
          </Button>
        ))}
      </div>
    </div>
  );

  const renderContent = () => (
    <div className={styles.content}>
      <div
        className={classNames(styles.mainContent, {
          [styles.drilledInMainContent]: drillInContent,
        })}
      >
        {renderActionSection()}
        {children}
      </div>
      <div
        className={classNames(styles.drillInContent, {
          [styles.isDrilled]: drillInContent,
        })}
      >
        {drillInContent}
      </div>
    </div>
  );

  return (
    <div
      className={classNames(styles.dashboardContentWrapper, {
        [styles.isContentWrapped]: isContentWrapped,
      })}
    >
      {!isEditMode && renderHeader()}
      {renderContent()}
    </div>
  );
};

export default DashboardContentWrapper;
