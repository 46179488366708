import stateTypes from './stateTypes';
import { getAppInitialState, type AppState } from './appInitialState';

import type { AnyAction } from 'redux';

export default function stateReducer(
  state: AppState = getAppInitialState(),
  action: AnyAction,
): AppState {
  switch (action.type) {
    case stateTypes.SET_RELATED_GYMS:
      return {
        ...state,
        relatedGyms: action.relatedGyms,
      };
    case stateTypes.SET_EXERCISES:
      return {
        ...state,
        exercises: action.exercises,
      };
    case stateTypes.SET_MERGED_EXERCISES:
      return {
        ...state,
        mergedExercises: action.mergedExercises,
      };
    case stateTypes.SET_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
      };
    case stateTypes.SET_STATIONS:
      return {
        ...state,
        stations: action.stations,
      };
    case stateTypes.SET_MUSCLES:
      return {
        ...state,
        muscles: action.muscles,
      };
    case stateTypes.SET_LOGIN_MANAGER:
      return {
        ...state,
        loginManager: {
          manager: action.manager,
        },
      };
    case stateTypes.SET_LOGIN_TRAINER:
      return {
        ...state,
        loginTrainer: {
          trainer: action.trainer,
          programs: action.programs,
          templates: action.templates,
          personalizedExercises: action.personalizedExercises,
        },
      };
    case stateTypes.SET_LOGIN_MEMBER:
      return {
        ...state,
        loginMember: {
          member: action.member,
        },
      };
    case stateTypes.SET_GYM_DATA:
      return {
        ...state,
        gymDataMap: {
          ...state.gymDataMap,
          [action.gymId]: {
            groups: action.groups,
            members: action.members,
            managers: action.managers,
            trainers: action.trainers,
            gymProducts: action.gymProducts,
          },
        },
      };
    case stateTypes.SET_SELECTED_GYM_ID:
      return {
        ...state,
        selectedGymId: action.selectedGymId,
      };
    case stateTypes.RESET_ALL_STATE:
      return {
        ...getAppInitialState(),
      };

    default:
      return state;
  }
}
