import React, { useState } from 'react';
import styles from './TrainerRow.module.scss';
import classNames from 'classnames';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { getFullName } from '../../utils/dashboardUtils';
import ConfirmationDialog from '../DialogWrappers/ConfirmationDialog';
import Menu from '../atoms/Menu';

import type { Trainer } from '../../types';

interface TrainerRowProps {
  trainer: Trainer;
  selected: boolean;
  onSelect?: () => void;
  deleteTrainer: (trainer: Trainer) => Promise<void>;
}

const TrainerRow: React.FC<TrainerRowProps> = ({
  trainer,
  onSelect,
  selected,
  deleteTrainer,
}) => {
  const [isDeleteTrainerConfirmationOpen, setIsDeleteTrainerConfirmationOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const renderTrainerName = () => {
    const trainerName = getFullName(trainer);
    return (
      <div className={classNames(styles.trainerColumn, styles.trainerName)}>
        {trainerName}
      </div>
    );
  };

  const renderClients = () => {
    return (
      <div className={styles.trainerColumn}>
        {`${trainer.memberIds.length} Clients`}
      </div>
    );
  };

  const handleDeleteTrainer = async () => {
    handleCloseMenuOptions();
    await deleteTrainer(trainer);
    setIsDeleteTrainerConfirmationOpen(false);
  };

  const renderMoreOptions = () => {
    const menuOptions = [
      {
        onClick: () => setIsDeleteTrainerConfirmationOpen(true),
        label: 'Delete Trainer',
        icon: <DeleteForeverOutlinedIcon color="primary" />,
      },
    ];
    return (
      <div className={classNames(styles.trainerColumn, styles.options)}>
        <PendingOutlinedIcon
          // @ts-ignore
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={styles.optionsBtn}
          color="primary"
        />
        <Menu
          anchorEl={anchorEl}
          isOpen={open}
          onClose={handleCloseMenuOptions}
          options={menuOptions}
        />
      </div>
    );
  };

  const renderDeleteTrainerConfirmationDialog = () => (
    <ConfirmationDialog
      text="Are you sure you want to delete this trainer?"
      onSubmit={handleDeleteTrainer}
      submitText="Yes"
      cancelText="No"
      isOpen={isDeleteTrainerConfirmationOpen}
      onCancel={() => setIsDeleteTrainerConfirmationOpen(false)}
      symbol={<DeleteForeverOutlinedIcon />}
    />
  );

  return (
    <div
      onClick={onSelect}
      className={classNames(styles.trainerRow, styles.listGrid, {
        [styles.selected]: selected,
      })}
    >
      {renderTrainerName()}
      {renderClients()}
      {renderMoreOptions()}
      {renderDeleteTrainerConfirmationDialog()}
    </div>
  );
};

export default TrainerRow;
