import React, { useState } from 'react';
import styles from './ExerciseRow.module.scss';
import classNames from 'classnames';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { getFullName, isMotivisionTrainer } from '../../utils/dashboardUtils';
import ConfirmationDialog from '../DialogWrappers/ConfirmationDialog';
import Menu from '../atoms/Menu';
import { getUniqueMuscleGroupsNames } from '../../utils/utils';
import { useGlobalData, useLoginData } from '../../providers';

import type { Exercise, Trainer } from '../../types';

interface ExerciseRowProps {
  exercise: Exercise;
  selected: boolean;
  onSelect: () => void;
  exerciseTrainer: Trainer | undefined;
  openEditExerciseModal: () => void;
  deleteExercise: (exercise: Exercise) => Promise<void>;
  addExerciseFromBaseExercise: (baseExercise: Exercise) => void;
}

const ExerciseRow: React.FC<ExerciseRowProps> = ({
  exercise,
  exerciseTrainer,
  onSelect,
  selected,
  deleteExercise,
  openEditExerciseModal,
  addExerciseFromBaseExercise,
}) => {
  const { muscles } = useGlobalData();
  const { trainerData } = useLoginData();
  const [
    isDeleteExerciseConfirmationOpen,
    setIsDeleteExerciseConfirmationOpen,
  ] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const handleEditExercise = () => {
    openEditExerciseModal();
    handleCloseMenuOptions();
  };

  const handleDeleteExercise = async () => {
    handleCloseMenuOptions();
    await deleteExercise(exercise);
    setIsDeleteExerciseConfirmationOpen(false);
  };

  const renderExerciseNameAndImage = () => (
    <div
      className={classNames(styles.exerciseColumn, styles.exerciseNameAndImage)}
    >
      <div
        className={styles.exerciseImage}
        style={{ backgroundImage: `url(${exercise.imageUrl})` }}
      />
      <div className={styles.exerciseName}>{exercise.name}</div>
    </div>
  );
  const renderModality = () => (
    <div className={styles.exerciseColumn}>{exercise.modality}</div>
  );
  const renderMuscleGroup = () => {
    return (
      <div className={styles.exerciseColumn}>
        {getUniqueMuscleGroupsNames([exercise], muscles)}
      </div>
    );
  };

  const renderCreatedBy = () => {
    const createdBy = exerciseTrainer
      ? getFullName(exerciseTrainer)
      : 'Motivision';
    return <div className={styles.exerciseColumn}>{createdBy}</div>;
  };

  const renderMovementPattern = () => (
    <div className={styles.exerciseColumn}>{exercise.movementPattern}</div>
  );

  const renderDeleteExerciseConfirmationDialog = () => (
    <ConfirmationDialog
      text="Are you sure you want to delete this exercise?"
      onSubmit={handleDeleteExercise}
      submitText="Yes"
      cancelText="No"
      isOpen={isDeleteExerciseConfirmationOpen}
      onCancel={() => setIsDeleteExerciseConfirmationOpen(false)}
      symbol={<DeleteForeverOutlinedIcon />}
    />
  );

  const renderMoreOptions = () => {
    const allowedToEditGlobalExercises = isMotivisionTrainer(
      trainerData?.trainer,
    );
    const options =
      exerciseTrainer || allowedToEditGlobalExercises
        ? [
            {
              label: 'Edit Exercise Details',
              onClick: handleEditExercise,
              icon: <BorderColorOutlinedIcon color="primary" />,
            },
            {
              label: 'Delete Exercise',
              onClick: () => setIsDeleteExerciseConfirmationOpen(true),
              icon: <DeleteForeverOutlinedIcon color="primary" />,
            },
          ]
        : [
            {
              label: 'Create a personalized exercise',
              onClick: () => addExerciseFromBaseExercise(exercise),
              icon: <ContentCopyOutlinedIcon color="primary" />,
            },
          ];
    return (
      <div className={classNames(styles.exerciseColumn, styles.options)}>
        <PendingOutlinedIcon
          // @ts-ignore
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={styles.optionsBtn}
          color="primary"
        />
        <Menu
          anchorEl={anchorEl}
          isOpen={open}
          onClose={handleCloseMenuOptions}
          options={options}
        />
      </div>
    );
  };

  return (
    <div
      onClick={onSelect}
      className={classNames(styles.exerciseRow, styles.listGrid, {
        [styles.selected]: selected,
      })}
    >
      {renderExerciseNameAndImage()}
      {renderModality()}
      {renderMuscleGroup()}
      {renderMovementPattern()}
      {renderCreatedBy()}
      {renderMoreOptions()}
      {renderDeleteExerciseConfirmationDialog()}
    </div>
  );
};

export default ExerciseRow;
