import React, { useMemo, useState } from 'react';
import SearchBar from '../atoms/SearchBar';
import styles from './EditGroup.module.scss';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import InputField from '../atoms/InputField';
import MultiSelectList from '../composites/MultiSelectList';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { getFormalDateString, getFullName } from '../../utils/dashboardUtils';

import type { Group, Member, Trainer, WithOptionalId } from '../../types';

export interface EditGroupDialogProps {
  onSubmit: (
    group: WithOptionalId<Group>,
  ) => Promise<{ success: boolean; errorMessage?: string }>;
  group: WithOptionalId<Group>;
  setGroup: React.Dispatch<React.SetStateAction<WithOptionalId<Group>>>;
  onClose: () => void;
  members: Member[];
  allGymTrainers: Trainer[];
}

const EditGroup: React.FC<EditGroupDialogProps> = ({
  onClose,
  onSubmit,
  group,
  members,
  allGymTrainers,
}) => {
  const [groupName, setGroupName] = useState(group.name || '');
  const [nameError, setNameError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedMembers, setSelectedMembers] = useState<Member[]>(
    members.filter((member) => group.memberIds.includes(member.id)),
  );

  const handleSubmit = async () => {
    setLoading(true);
    const { success, errorMessage } = await onSubmit({
      ...group,
      name: groupName,
      memberIds: selectedMembers.map(({ id }) => id),
    });
    if (success) {
      onClose();
    } else {
      setErrorMessage(errorMessage || 'An error occurred. Please try again.');
      setLoading(false);
    }
  };

  const filteredMembers = useMemo(() => {
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return members
      .filter((member) =>
        searchQueryRegex.test(getFullName(member).toLowerCase()),
      )
      .sort(
        (a, b) =>
          a.firstName
            ?.toLowerCase()
            .localeCompare(b.firstName?.toLowerCase() || '') || -1,
      );
  }, [searchQuery, members]);

  return (
    <div className={styles.editGroup}>
      <InputField
        placeholder="Group Name"
        icon={<GroupsOutlinedIcon color="primary" />}
        title="Group name"
        inputName="groupName"
        value={groupName}
        onChange={(value) => {
          setGroupName(value);
          setNameError(false);
        }}
        error={nameError}
      />
      <div className={styles.searchHeading}>
        <PersonOutlineOutlinedIcon color="primary" />
        <div className={styles.memberName}>Search Members</div>
      </div>
      <SearchBar
        className={styles.search}
        placeholder="Search Members"
        setSearchQuery={setSearchQuery}
      />
      <MultiSelectList
        listClassName={styles.list}
        items={filteredMembers}
        selectedItems={selectedMembers}
        setSelectedItems={setSelectedMembers}
        EmptyStateComponent={() => null}
        ItemComponent={({ data }) => (
          <div className={styles.groupMultiSelectItem}>
            <div className={styles.groupNameWrapper}>
              <PersonOutlineOutlinedIcon color="primary" />
              <div className={styles.groupName}>{getFullName(data)}</div>
            </div>
            <div>
              {`${data.createdDate ? getFormalDateString(new Date(data.createdDate)) : ''}`}
            </div>
            <div>
              {getFullName(
                allGymTrainers.find(({ id }) => id === data.trainerIds[0]),
              )}
            </div>
          </div>
        )}
      />
      <Button
        className={styles.submit}
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          <span className={styles.submitText}>Save Group</span>
        )}
      </Button>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default EditGroup;
