import { EMAIL_TEMPLATE, EmailSubjects, MOTIVISION_LINK } from '../constants';
import { getUniqueExerciseIdsListFromRoutine } from './utils';
import {
  getPersonalRecords,
  getTrainingSessionPerformedVolume,
} from './statisticsUtils';

import type { Exercise, Gym, Member, TrainingRoutineSession } from '../types';
import type {
  NewTrackingMemberEmailPayload,
  CompleteWorkoutEmailPayload,
} from '../types';
import { secondsToDurationString } from './timerUtils';

export const getNewTrackingMemberEmailPayload = (
  member: Member,
  gym: Gym,
): NewTrackingMemberEmailPayload => {
  const template = EMAIL_TEMPLATE.E2_NEW_TRACKING_MEMBER;
  return {
    email: member.email,
    subject: EmailSubjects[template],
    template,
    context: {
      gymLink: gym.scheduleMeetingLink || MOTIVISION_LINK,
      pin: member.pin,
      email: member.email,
      name: member.firstName || 'Trainer',
      gymName: gym.name,
      gymLogoUrl: gym.blackLogoUrl,
    },
  };
};

const getCompleteWorkoutTemplate = (
  member: Member,
  isFirstWorkout: boolean,
): CompleteWorkoutEmailPayload['template'] => {
  const memberHasTrainer = member.trainerIds?.length > 0;
  if (isFirstWorkout) {
    return memberHasTrainer
      ? EMAIL_TEMPLATE.E4_FIRST_TRACKING_MEMBER_COMPLETE_WORKOUT
      : EMAIL_TEMPLATE.E5_FIRST_GUIDED_MEMBER_COMPLETE_WORKOUT;
  } else {
    return memberHasTrainer
      ? EMAIL_TEMPLATE.E6_COMPLETE_TRACKING_WORKOUT
      : EMAIL_TEMPLATE.E7_COMPLETE_GUIDED_WORKOUT;
  }
};

export const getCompleteWorkoutEmailPayload = (
  member: Member,
  gym: Gym,
  trainingRoutineSession: TrainingRoutineSession,
  historyTrainingSession: TrainingRoutineSession[],
  exercises: Record<string, Exercise>,
  isFirstWorkout: boolean,
): CompleteWorkoutEmailPayload => {
  const template = getCompleteWorkoutTemplate(member, isFirstWorkout);
  return {
    email: member.email,
    subject: EmailSubjects[template],
    template,
    context: {
      gymLink: gym.scheduleMeetingLink || MOTIVISION_LINK,
      email: member.email,
      name: member.firstName || 'Trainer',
      gymName: gym.name,
      gymLogoUrl: gym.blackLogoUrl,
      workoutDate: new Date(trainingRoutineSession.createdDate).toDateString(),
      exercisesPerformed: `${
        getUniqueExerciseIdsListFromRoutine(
          trainingRoutineSession.performedRoutine,
        ).length
      }`,
      workoutDuration: secondsToDurationString(
        trainingRoutineSession.performedRoutine.performedDuration,
      ),
      totalVolume: `${getTrainingSessionPerformedVolume(
        exercises,
        trainingRoutineSession,
      )}`,
      personalRecordsSet: `${getPersonalRecords(
        trainingRoutineSession,
        historyTrainingSession,
      )}`,
    },
  };
};
