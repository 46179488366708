import { Program, WithOptionalId, AssignedProgram } from '../../types';
import { apiBaseUrl } from '../apiConsts';
import { getDataWithRetry } from '../fetchUtils';
import { isValidProgramResponse } from '../apiValidators';
import { updateMemberData } from './members-controller';
import { fetchMemberById } from './members-controller';

import type { BaseApiResponse } from '../apiTypes';

export const createNewProgram = async (
  program: WithOptionalId<Program>,
  trainerId: string,
): Promise<BaseApiResponse> => {
  try {
    if (!trainerId) {
      throw new Error('Trainer id must be provided');
    }
    const createdProgram = await fetch(`${apiBaseUrl}programs`, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(program),
    }).then((x) => x.json());
    await fetch(`${apiBaseUrl}trainers/${trainerId}/associate/program`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify([createdProgram.id]),
    });
    return { success: true, content: createdProgram };
  } catch (error) {
    const e = error as unknown as Error;
    const errorMessage = `Error in createNewProgram: ${e?.message}`;
    console.error(errorMessage);
    return { success: false, errorMessage };
  }
};

export const updateProgramData = async (
  program: Program,
): Promise<BaseApiResponse> => {
  try {
    const response = await fetch(`${apiBaseUrl}programs`, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(program),
    });
    if (response.status !== 200) {
      throw new Error(`Server response: ${response.status}`);
    }
    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    const errorMessage = `Error in updateProgramData: ${e?.message}`;
    console.error(errorMessage);
    return { success: false, errorMessage };
  }
};

export const removeProgram = async (
  programId: string,
): Promise<BaseApiResponse> => {
  try {
    const response = await fetch(`${apiBaseUrl}programs/${programId}`, {
      method: 'DELETE',
    });
    if (response.status !== 200) {
      throw new Error(`Server response: ${response.status}`);
    }
    return { success: true };
  } catch (error) {
    console.error('Error in removeProgram:', error);
    return { success: false };
  }
};

export const fetchTrainerPrograms = async (
  trainerId: string,
): Promise<Program[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}programs/getByTrainerIds`,
      {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify([trainerId]),
      },
      isValidProgramResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchTrainerPrograms:', error);
    return [];
  }
};

export const assignProgramToMembers = async (
  memberIds: string[],
  program: AssignedProgram,
): Promise<BaseApiResponse> => {
  try {
    await Promise.all(
      memberIds.map(async (memberId) => {
        const member = await fetchMemberById(memberId);
        if (!member) return;
        await updateMemberData(member, { ...member, program });
      }),
    );
    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    console.error('Error in assignProgramToMembers:', e);
    return {
      success: false,
      errorMessage: e?.message || 'Something went wrong',
    };
  }
};
