import React from 'react';
import styles from './MultiSelectList.module.scss';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';

export interface MultiSelectListProps<T extends { id: string }> {
  items: T[];
  selectedItems: T[];
  listClassName?: string;
  setSelectedItems: React.Dispatch<React.SetStateAction<T[]>>;
  singleSelect?: boolean; // Added singleSelect prop
  EmptyStateComponent: React.FC;
  ItemComponent: React.FC<{ data: T; selected: boolean }>;
}

const MultiSelectList = <T extends { id: string }>({
  items,
  ItemComponent,
  listClassName,
  selectedItems,
  setSelectedItems,
  singleSelect = false, // Default to false if not provided
  EmptyStateComponent,
}: MultiSelectListProps<T>) => {
  const handleCheckboxChange = (item: T) => {
    setSelectedItems((prevSelectedItems) => {
      if (singleSelect) {
        // In singleSelect mode, select only one item
        return prevSelectedItems.find((i) => i.id === item.id) ? [] : [item];
      } else {
        // In multi-select mode, toggle selection
        if (prevSelectedItems.find((i) => i.id === item.id)) {
          return prevSelectedItems.filter((i) => i.id !== item.id);
        } else {
          return [...prevSelectedItems, item];
        }
      }
    });
  };

  if (!items.length) {
    return <EmptyStateComponent />;
  }

  return (
    <div className={classNames(styles.list, listClassName)}>
      {items.map((item) => {
        const isSelected = !!selectedItems.find((i) => i.id === item.id);
        const isDisabled =
          singleSelect && selectedItems.length === 1 && !isSelected;

        return (
          <div className={styles.itemWrapper} key={item.id}>
            <Checkbox
              checked={isSelected}
              onChange={() => handleCheckboxChange(item)}
              disabled={isDisabled} // Disable other items if singleSelect is active
            />
            <div className={styles.item}>
              <ItemComponent data={item} selected={isSelected} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MultiSelectList;
