import { GymType, Product, SubscriptionSource } from '../constants';
import { getAllTrainingSets, strToOptionObj } from './utils';

import type {
  Exercise,
  Gym,
  Manager,
  Member,
  MemberSubscription,
  Program,
  Trainer,
  TrainingRoutine,
  TrainingSet,
} from '../types';

export const getFullName = (account?: Member | Trainer | Manager | null) => {
  if (!account) return '';
  return `${account.firstName || ''} ${account.lastName || ''}`;
};

export const getAccountRole = (
  manager?: Manager,
  trainer?: Trainer,
): string => {
  if (manager) {
    return 'Administrator';
  }
  if (trainer) {
    return 'Trainer';
  }
  return 'Member';
};

export function getFormalDateString(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
}

export const createDashboardSubscription = (
  product: Product,
): MemberSubscription => ({ product, source: SubscriptionSource.Gym });

export const createTrainingSetFromExercise = (
  exercise: Exercise,
): TrainingSet => {
  return {
    exerciseId: exercise.id,
    reps: 10,
    weight: 0,
    rest: 0,
    duration: 0,
    metrics: {},
  };
};

export const getSubscriptionOptions = () =>
  ['', ...Object.values(Product)].map((value) => strToOptionObj(value, 'None'));

export const wrapProxy = <T>(object: T): T => {
  if (!object) return object;
  const handler = {
    get(obj: any, prop: any) {
      return wrapProxy(obj[prop]);
    },
    set(obj: any, prop: any, value: any) {
      debugger;
      return Reflect.set(obj, prop, value);
    },
  };
  return typeof object === 'object' ? new Proxy(object, handler) : object;
};

export const getNumOfRoutineExercises = ({
  exerciseGroups,
}: TrainingRoutine): number => {
  return [
    ...new Set(
      getAllTrainingSets(exerciseGroups).map(({ exerciseId }) => exerciseId),
    ),
  ].length;
};

const calculateSetDuration = (set: TrainingSet): number => {
  if (set.duration) return set.duration;
  const reps = set.reps || 0;
  const rest = set.rest || 0;
  return reps * 2 + rest;
};

export const calculateRoutineDurationMin = ({
  exerciseGroups,
}: TrainingRoutine): number => {
  const totalMinutes = Math.ceil(
    exerciseGroups.reduce(
      (exerciseGroupsDurationAcc, { sets: performedCircuit }) =>
        exerciseGroupsDurationAcc +
        performedCircuit.reduce(
          (groupSetsDurationAcc, sets) =>
            groupSetsDurationAcc +
            sets.reduce(
              (setsAccDuration, set) =>
                setsAccDuration + calculateSetDuration(set),
              0,
            ),
          0,
        ),
      0,
    ) / 60,
  );

  // Round up to the nearest multiple of 5
  return totalMinutes === 0 ? 0 : Math.ceil(totalMinutes / 5) * 5;
};

export const calculateProgramWeeks = ({ programItems }: Program): number => {
  return programItems.length;
};

export const calculateTemplateExercises = (
  routine: TrainingRoutine,
): number => {
  const allExerciseIds = routine.exerciseGroups.reduce<string[]>(
    (acc, { sets }) => [
      ...acc,
      ...sets.reduce<string[]>(
        (superSetAcc, superSet) => [
          ...superSetAcc,
          ...superSet.map(({ exerciseId }) => exerciseId),
        ],
        [],
      ),
    ],
    [],
  );

  return [...new Set(allExerciseIds)].length;
};

export const getProgramImage = (
  { programItems }: Program,
  exercises: Record<string, Exercise>,
): string => {
  const firstWorkout = programItems.find((week) => week?.length > 0)?.[0];
  if (!firstWorkout) return '';
  return (
    exercises[firstWorkout.routine.exerciseGroups[0].sets[0]?.[0]?.exerciseId]
      ?.imageUrl || ''
  );
};

export const getTrainerMergedExercises = (
  globalExercises: Exercise[],
  personalizedExercises: Exercise[],
): Exercise[] => {
  const trainerOriginalExercisesIdsSet = new Set(
    personalizedExercises.map(({ originalExerciseId }) => originalExerciseId),
  );
  const globalExercisesWithoutPersonalizedDuplicates = globalExercises.filter(
    ({ id }) => !trainerOriginalExercisesIdsSet.has(id),
  );
  return [
    ...personalizedExercises,
    ...globalExercisesWithoutPersonalizedDuplicates,
  ];
};

export const isHighSchool = (gym?: Gym): boolean =>
  gym?.type === GymType.School;

export const isMotivisionTrainer = (trainer?: Trainer) =>
  Boolean(trainer?.email.endsWith('@motivision.us'));
