import { getDataWithRetry } from '../fetchUtils';
import {
  isValidGymMembersResponse,
  isValidMemberResponse,
  isValidTrainingRoutineSessionsResponse,
} from '../apiValidators';
import { apiBaseUrl } from '../apiConsts';
import { isTrainingRoutineSession } from '../../utils/typeUtils';
import { getNewTrackingMemberEmailPayload } from '../../utils/emailUtils';

import type {
  EmailPayload,
  Gym,
  Member,
  TrainingRoutineSession,
} from '../../types';
import type { BaseApiResponse } from '../apiTypes';
import _ from 'lodash';

export const notifyUserByEmail = async (emailPayload: EmailPayload) => {
  try {
    if (!emailPayload.email) {
      throw new Error('cannot notify user by email, no email provided');
    }
    await fetch(`${apiBaseUrl}notifications/notify`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(emailPayload),
    });
  } catch (e) {
    const error = e as unknown as Error;
    console.error('error in notifyUserByEmail:', error.message);
  }
};

export const fetchMemberById = async (
  memberId: string,
): Promise<Member | null> => {
  try {
    const member = await getDataWithRetry(
      `${apiBaseUrl}members/${memberId}`,
      {},
      isValidMemberResponse,
    );
    return member;
  } catch (error) {
    console.error('Error in fetchMemberById:', error);
    return null;
  }
};

export const fetchAllMembers = async (): Promise<Member[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}members`,
      {},
      isValidGymMembersResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchAllMembers:', error);
    return [];
  }
};

export const loadGymMembers = async (gymId: string): Promise<Member[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}members/gym/${gymId}`,
      {},
      isValidGymMembersResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in loadGymMembers:', error);
    return [];
  }
};

export const fetchMemberSessions = async (
  memberId: string,
): Promise<TrainingRoutineSession[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}training-routine-sessions/member/${memberId}?page=0&size=150&sort=createdDate`,
      {},
      isValidTrainingRoutineSessionsResponse,
    );
    return content.filter(isTrainingRoutineSession);
  } catch (error) {
    console.error('Error in fetchMemberSessions:', error);
    return [];
  }
};

export interface CreateMemberResponse extends BaseApiResponse {
  member: Member | null;
}

export const createNewMember = async (
  member: Member,
  gym: Gym,
  trainerId?: string,
): Promise<CreateMemberResponse> => {
  try {
    const { id, ...memberData } = member;
    const createdMember = await fetch(`${apiBaseUrl}members`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(memberData),
    }).then((x) => x.json());
    if (createdMember.error) {
      throw new Error(createdMember.message);
    }
    await fetch(`${apiBaseUrl}gyms/${gym.id}/associate/members`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify([createdMember.id]),
    });
    if (trainerId) {
      await fetch(`${apiBaseUrl}trainers/${trainerId}/associate/members`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify([createdMember.id]),
      });
    }
    await notifyUserByEmail(
      getNewTrackingMemberEmailPayload(createdMember, gym),
    );
    return {
      success: true,
      member: {
        ...createdMember,
        gymIds: [gym.id],
        trainerIds: [trainerId],
      },
    };
  } catch (e) {
    const error = e as unknown as Error;
    console.error('Error in createNewMember:', error);
    return {
      member: null,
      success: false,
      errorMessage: error?.message || 'Something went wrong',
    };
  }
};

const unassociateTrainers = async (memberId: string, trainerIds: string[]) => {
  await Promise.all(
    trainerIds.map((trainerId) =>
      fetch(`${apiBaseUrl}trainers/${trainerId}/unassociate/members`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify([memberId]),
      }),
    ),
  );
};

const associateTrainers = async (memberId: string, trainerIds: string[]) => {
  await Promise.all(
    trainerIds.map((trainerId) =>
      fetch(`${apiBaseUrl}trainers/${trainerId}/associate/members`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify([memberId]),
      }),
    ),
  );
};

export const updateMemberData = async (
  oldMemberData: Member,
  newMemberData: Member,
): Promise<BaseApiResponse> => {
  try {
    if (!newMemberData?.id) {
      throw new Error('No member id provided');
    }
    const response = await fetch(`${apiBaseUrl}members`, {
      headers: { 'content-type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify(newMemberData),
    });
    const trainerIdsToUnAssociate = _.difference(
      oldMemberData.trainerIds,
      newMemberData.trainerIds,
    );
    const trainerIdsToAssociate = _.difference(
      newMemberData.trainerIds,
      oldMemberData.trainerIds,
    );
    if (trainerIdsToUnAssociate.length > 0) {
      await unassociateTrainers(newMemberData.id, trainerIdsToUnAssociate);
    }
    if (trainerIdsToAssociate.length > 0) {
      await associateTrainers(newMemberData.id, trainerIdsToAssociate);
    }
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    console.error('Error in updateMemberData:', e);
    return {
      success: false,
      errorMessage: e?.message || 'Something went wrong',
    };
  }
};
