import React from 'react';
import { isExercise } from '../../utils/typeUtils';
import { Exercise, ExerciseGroup } from '../../types';

export const extractExerciseDragData = (e: React.DragEvent): Exercise => {
  const exerciseData = e.dataTransfer.getData('application/json');
  const { exercise } = JSON.parse(exerciseData);
  if (!isExercise(exercise)) {
    throw new Error('onDrop data is not an exercise.');
  }
  return exercise;
};

export const extractExerciseGroupDragData = (e: React.DragEvent): ExerciseGroup => {
  const exerciseData = e.dataTransfer.getData('application/json');
  const { exerciseGroup } = JSON.parse(exerciseData);
  return exerciseGroup;
};

// export const extractExerciseGroupDragData = (e: React.DragEvent): Exercise => {
//   const exerciseData = e.dataTransfer.getData('application/json');
//   const exerciseGroup = JSON.parse(exerciseData);
//   return exerciseGroup;
// };
