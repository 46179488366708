import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import styles from './LoginSuccessAnimation.module.scss';

const LoginSuccessAnimation: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
      className={styles.successAnimation}
    >
      <CircularProgress />
      <Typography color="primary" variant="h6" sx={{ mt: 2 }}>
        Logging you in...
      </Typography>
    </Box>
  );
};

export default LoginSuccessAnimation;
