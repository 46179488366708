import { Product, SubscriptionSource } from '../constants';
import { updateMemberData } from './api-controllers/members-controller';
import {
  refundSubscription,
  updateMemberSubscription,
} from './api-controllers/subscription-controller';

import type { BaseApiResponse } from './apiTypes';
import type { GymProduct, Member, MemberSubscription } from '../types';

const isSameSubscription = (
  activeSubscription: MemberSubscription,
  product: Product | '',
): boolean =>
  Boolean(activeSubscription && activeSubscription.product === product);

export const cancelMemberSubscription = async (
  subscriptionId: string,
): Promise<void> => {
  return updateMemberSubscription(subscriptionId, 'canceled');
};

const getProductPrice = (
  gymProducts: GymProduct[],
  product: Product,
): number => {
  return gymProducts.find(({ name }) => name === product)?.price || 0;
};

const isActiveExpirationDate = (ISOStringDate: string): boolean => {
  const currentDate = new Date();
  const expirationDate = new Date(ISOStringDate);

  currentDate.setHours(0, 0, 0, 0);
  expirationDate.setHours(0, 0, 0, 0);

  return expirationDate >= currentDate;
};

const createNewGymSubscription = (product: Product): MemberSubscription => {
  return {
    product,
    source: SubscriptionSource.Gym,
    startDate: new Date().toISOString(),
  };
};

const shouldUpgradeSubscription = (
  gymProducts: GymProduct[],
  activeSubscription: MemberSubscription,
  newProduct: Product,
) => {
  const activeSubscriptionPrice = getProductPrice(
    gymProducts,
    activeSubscription.product,
  );
  if (!activeSubscriptionPrice) return false;
  const newProductPrice = getProductPrice(gymProducts, newProduct);
  if (!newProductPrice) return false;
  return newProductPrice > activeSubscriptionPrice;
};

export const getActiveSubscription = (
  subscriptions?: MemberSubscription[],
): MemberSubscription | null => {
  if (!subscriptions) return null;
  return (
    subscriptions.find(({ source, expirationDate }) => {
      return (
        source === SubscriptionSource.Gym ||
        (expirationDate &&
          new Date(expirationDate).getTime() > new Date().getTime())
      );
    }) || null
  );
};

const updateSubscriptionAndReturnMember = async (
  member: Member,
  newSelectedProduct: Product | '',
  gymProducts: GymProduct[],
): Promise<Member> => {
  const subscriptions = member.subscriptions || [];
  const activeSubscription = getActiveSubscription(subscriptions);

  if (!activeSubscription) {
    if (newSelectedProduct) {
      const newSubscription = createNewGymSubscription(newSelectedProduct);
      member.subscriptions = [...subscriptions, newSubscription];
      return member;
    }
    return member;
  }

  if (isSameSubscription(activeSubscription, newSelectedProduct)) {
    return member;
  }

  if (!newSelectedProduct) {
    // Need to cancel the active.
    if (activeSubscription.source === SubscriptionSource.Gym) {
      member.subscriptions = subscriptions.filter(
        (s) => s.source !== SubscriptionSource.Gym,
      );
      return member;
    }
    if (
      activeSubscription.subscriptionId &&
      activeSubscription.expirationDate &&
      isActiveExpirationDate(activeSubscription.expirationDate)
    ) {
      activeSubscription.shouldRenewal = false;
      await refundSubscription(activeSubscription.subscriptionId);
      await cancelMemberSubscription(activeSubscription.subscriptionId);
    }
    return member;
  }

  if (activeSubscription.source === SubscriptionSource.Gym) {
    const subscriptionsWithoutGymSource = subscriptions.filter(
      ({ source }) => source !== SubscriptionSource.Gym,
    );
    member.subscriptions = [
      ...subscriptionsWithoutGymSource,
      createNewGymSubscription(newSelectedProduct),
    ];
    return member;
  } else if (activeSubscription.source === SubscriptionSource.Motivision) {
    // Need to cancel motivision subscription
    if (activeSubscription.subscriptionId) {
      activeSubscription.shouldRenewal = false;
      await cancelMemberSubscription(activeSubscription.subscriptionId);
      const shouldUpgrade = shouldUpgradeSubscription(
        gymProducts,
        activeSubscription,
        newSelectedProduct,
      );
      if (shouldUpgrade) {
        await refundSubscription(activeSubscription.subscriptionId);
      }
    }

    member.subscriptions = [
      ...subscriptions,
      createNewGymSubscription(newSelectedProduct),
    ];
    return member;
  }

  return member;
};

export const updateMemberAndSubscriptions = async (
  oldMemberData: Member,
  newMemberData: Member,
  newSelectedProduct: Product | '',
  gymProducts: GymProduct[],
): Promise<BaseApiResponse & { updatedMember: Member }> => {
  const updatedMember = await updateSubscriptionAndReturnMember(
    newMemberData,
    newSelectedProduct,
    gymProducts,
  );
  const updateMemberResponse = await updateMemberData(
    oldMemberData,
    updatedMember,
  );
  return {
    ...updateMemberResponse,
    updatedMember,
  };
};
