import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import type { AppState } from '../appState/appInitialState';

interface ProtectedRouteProps {
  exact?: boolean;
  isLoggedIn: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isLoggedIn }) => {
  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: Boolean(
    state.loginMember || state.loginTrainer || state.loginManager,
  ),
});

export default connect(mapStateToProps)(ProtectedRoute);
