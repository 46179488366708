import React, { useRef, useState } from 'react';
import { Button, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import styles from './FileUpload.module.scss';
import classNames from 'classnames';

type SupportedFileUpload = 'Image' | 'Video';

interface FileUploadProps {
  title: string;
  description: string;
  onChange: (file: File | null) => void;
  error: boolean;
  label: string;
  type: SupportedFileUpload;
  url?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  title,
  description,
  label,
  onChange,
  error,
  type,
  url,
}) => {
  const [fileBase64, setFileBase64] = useState<string | null | undefined>(url);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileType = file.type;

      if (type === 'Image' && fileType.startsWith('image/')) {
        const url = URL.createObjectURL(file);
        setFileBase64(url);
        onChange(file);
      } else if (type === 'Video' && fileType.startsWith('video/')) {
        const url = URL.createObjectURL(file);
        setFileBase64(url);
        onChange(file);
      } else {
        console.error('Unsupported file type:', fileType);
      }
    }
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setFileBase64(null);
    onChange(null);
    if (fileInputRef?.current?.value) {
      fileInputRef.current.value = '';
    }
  };

  const displayFileUrl = url || fileBase64;

  return (
    <div className={styles.fileUploadContainer}>
      <Typography color="primary" variant="h6">
        {title}
      </Typography>
      <span className={styles.description}>{description}</span>
      <div
        className={classNames(styles.uploadBox, {
          [styles.uploadBoxError]: error,
        })}
      >
        <input
          ref={fileInputRef}
          type="file"
          accept={type === 'Image' ? 'image/*' : 'video/*'}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id={`${title}-upload`}
        />
        <label
          htmlFor={`${title}-upload`}
          className={styles.uploadLabelContainer}
        >
          {!displayFileUrl && (
            <>
              <UploadFileIcon className={styles.uploadIcon} />
              <span className={styles.uploadLabel}>{label}</span>
            </>
          )}
          {displayFileUrl && type === 'Video' && (
            <video className={styles.media} src={displayFileUrl} controls />
          )}
          {displayFileUrl && type === 'Image' && (
            <img className={styles.media} src={displayFileUrl} alt="uploaded" />
          )}
          {displayFileUrl && (
            <>
              <Button className={styles.clearButton} onClick={handleClear}>
                <CloseIcon />
              </Button>
              <Button className={styles.editButton}>
                <ChangeCircleOutlinedIcon className={styles.changeMedia} />
                {`Edit ${type}`}
              </Button>
            </>
          )}
        </label>
      </div>
    </div>
  );
};

export default FileUpload;
