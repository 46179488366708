import { Muscle } from '../../types';
import { getDataWithRetry } from '../fetchUtils';
import { isValidMuscleGroupResponse } from '../apiValidators';
import { apiBaseUrl } from '../apiConsts';

export const fetchMuscles = async (): Promise<Muscle[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}muscles`,
      {},
      isValidMuscleGroupResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchMuscles:', error);
    return [];
  }
};
