import React, { useEffect } from 'react';
import { CircularProgress, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { DashboardRoute } from '../router/routes';
import {
  clearLoginStorageData,
  fetchAndSetStateData,
  getLoginStorageData,
  hasValidLoginStorageData,
  LoginStorageData,
} from '../api/loginApiHelper';

import type { Dispatch } from 'redux';

export interface LoginRedirectDispatchProps {
  logInWithStorageData: (
    loginStorageData: LoginStorageData,
  ) => Promise<{ success: boolean }>;
}

export interface LoginRedirectProps extends LoginRedirectDispatchProps {}

const LoginRedirectPure: React.FC<LoginRedirectProps> = ({
  logInWithStorageData,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    const initializeSession = async () => {
      const loginStorageData = getLoginStorageData();
      if (loginStorageData && hasValidLoginStorageData(loginStorageData)) {
        const { success } = await logInWithStorageData(loginStorageData);
        if (success) {
          navigate(DashboardRoute.Dashboard);
          return;
        }
      }
      clearLoginStorageData();
      navigate(DashboardRoute.Home);
    };
    initializeSession();
  }, []);

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
        <Typography color="primary" variant="h6" sx={{ mt: 2 }}>
          Loading dashboard data, please wait...
        </Typography>
      </Box>
    </Container>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
): LoginRedirectDispatchProps => ({
  logInWithStorageData: async (loginStorageData: LoginStorageData) => {
    return fetchAndSetStateData(
      dispatch,
      loginStorageData.managerId,
      loginStorageData.trainerId,
      loginStorageData.memberId,
    );
  },
});

const LoginRedirect = connect(null, mapDispatchToProps)(LoginRedirectPure);

export default LoginRedirect;
