import React from 'react';
import styles from './LoginPage.module.scss';
import { Typography } from '@mui/material';

export const LoginPageDescription: React.FC<{ text: string }> = ({ text }) => (
  <Typography
    className={styles.loginDescription}
    component="h2"
    variant="h5"
    sx={{
      fontWeight: 'bold',
      fontSize: '1.3rem',
      textAlign: 'left',
    }}
  >
    {text}
  </Typography>
);
