import React, { useState } from 'react';
import NavigationBar from '../components/NavigationBar';
import ClientsContent from '../components/Clients/ClientsContent';
import styles from './Dashboard.module.scss';
import EmptyState from '../components/composites/EmptyState';
import { NavigationRoutes } from '../constants/navigationConsts';
import TrainerExercisesContent from '../components/Exercises/TrainerExercisesContent';
import Programs from '../components/Programs/Programs';
import TrainerWorkouts from '../components/Workouts/TrainerWorkouts';
import Trainers from '../components/Trainers/Trainers';
import Groups from '../components/Groups/Groups';
import Managers from '../components/Managers/Managers';
import { isHighSchool } from '../utils/dashboardUtils';
import { useLoginData, useSelectedGymData } from '../providers';

import type { Manager, Trainer } from '../types';

const getManagersRoutes = (): NavigationRoutes[] => {
  return [
    NavigationRoutes.Clients,
    NavigationRoutes.Managers,
    NavigationRoutes.Trainers,
  ];
};

const getTrainersRoutes = (): NavigationRoutes[] => {
  return [
    NavigationRoutes.Clients,
    NavigationRoutes.Programs,
    NavigationRoutes.Workouts,
    NavigationRoutes.Exercises,
    NavigationRoutes.Groups,
  ];
};

const getRouteTitle = (
  navigationRoute: NavigationRoutes,
  isHighSchool: boolean,
): string => {
  switch (navigationRoute) {
    case NavigationRoutes.Clients:
      return isHighSchool ? 'Athletes' : 'Clients';
    default:
      return navigationRoute;
  }
};

const getRoutes = (
  manager: Manager | undefined,
  trainer: Trainer | undefined,
  isHighSchool: boolean,
): Array<{ navigation: NavigationRoutes; title: string }> => {
  const managerRoutes = manager ? getManagersRoutes() : [];
  const trainerRoutes = trainer ? getTrainersRoutes() : [];
  const combinedRoutes = new Set([...managerRoutes, ...trainerRoutes]);
  return Array.from(combinedRoutes).map((navigation) => ({
    navigation,
    title: getRouteTitle(navigation, isHighSchool),
  }));
};

const Dashboard: React.FC = () => {
  const { selectedGymId } = useSelectedGymData();
  const { trainerData, managerData, relatedGyms } = useLoginData();
  const gym = relatedGyms.find(({ id }) => id === selectedGymId);
  const manager = managerData?.manager;
  const trainer = trainerData?.trainer;
  const navigationRoutes = getRoutes(manager, trainer, isHighSchool(gym));
  const [navigationRoute, setNavigationRoute] =
    useState<NavigationRoutes | null>(navigationRoutes[0]?.navigation || null);

  const getMainContent = () => {
    switch (navigationRoute) {
      case NavigationRoutes.Clients:
        return <ClientsContent />;
      case NavigationRoutes.Exercises:
        return <TrainerExercisesContent />;
      case NavigationRoutes.Workouts:
        return <TrainerWorkouts />;
      case NavigationRoutes.Programs:
        return <Programs />;
      case NavigationRoutes.Groups:
        return <Groups />;
      case NavigationRoutes.Trainers:
        return <Trainers />;
      case NavigationRoutes.Managers:
        return <Managers />;
      default:
        return null;
    }
  };

  if (!gym) {
    return (
      <EmptyState
        text="It looks like you don't have a gym yet. Please contact your gym
        administrator for help."
      />
    );
  }

  return (
    <div className={styles.dashboard}>
      <NavigationBar
        navigationRoutes={navigationRoutes}
        navigationRoute={navigationRoute}
        setNavigationRoute={setNavigationRoute}
      />
      <div className={styles.mainContent}>{getMainContent()}</div>
    </div>
  );
};

export default Dashboard;
