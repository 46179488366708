import React from 'react';
import styles from './AccountIcon.module.scss';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { getAccountRole, getFullName } from '../../utils/dashboardUtils';
import { LoginDataContextProps, useLoginData } from '../../providers';

const getAccountData = ({
  trainerData,
  memberData,
  managerData,
}: LoginDataContextProps): {
  accountName: string;
  accountRole: string;
} => {
  const manager = managerData?.manager;
  const trainer = trainerData?.trainer;
  const member = memberData?.member;
  return {
    accountName: getFullName(manager || trainer || member),
    accountRole: getAccountRole(manager, trainer),
  };
};

const AccountIcon: React.FC = () => {
  const loginData = useLoginData();
  const { accountName, accountRole } = getAccountData(loginData);
  return (
    <div className={styles.accountWrapper}>
      <AccountCircleRoundedIcon
        color="primary"
        className={styles.accountIcon}
      />
      <div className={styles.accountDetails}>
        <div className={styles.accountName}>{accountName}</div>
        <div className={styles.accountDescription}>{accountRole}</div>
      </div>
      <KeyboardArrowDownOutlinedIcon color="primary" />
    </div>
  );
};

export default AccountIcon;
