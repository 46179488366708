import _ from 'lodash';
import React from 'react';
import styles from './InputField.module.scss';
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
} from '@mui/material';
import classNames from 'classnames';

interface Option {
  value: string | number;
  label: string;
}

interface DropDownFieldProps<T> {
  title: string;
  inputName: string;
  placeholder?: string;
  value: T;
  options: Array<Option>;
  icon?: React.ReactNode;
  onChange: (value: T) => void;
  error?: boolean;
  multiple?: boolean;
}

const MultiSelectInputField = <
  T extends string | number | (string | number)[],
>({
  options,
  value,
  icon,
  onChange,
  inputName,
  title,
  placeholder,
  error,
  multiple = true,
}: DropDownFieldProps<T>) => {
  const handleChange = (event: SelectChangeEvent<T>) => {
    const {
      target: { value },
    } = event;

    onChange(
      multiple
        ? ((typeof value === 'string' ? value.split(',') : value) as T)
        : (value as T),
    );
  };

  const isSelected = (val: string | number) =>
    multiple && Array.isArray(value) ? value.includes(val) : value === val;

  return (
    <div className={styles.inputFieldItem}>
      <div className={styles.fieldTitleWrapper}>
        {icon}
        <span className={styles.fieldTitle}>{title}</span>
      </div>
      <div className={styles.inputWrapper}>
        <FormControl fullWidth variant="outlined" margin="dense" error={error}>
          {_.isEmpty(value) && <InputLabel>{placeholder}</InputLabel>}
          <Select
            name={inputName}
            multiple={multiple}
            value={value}
            onChange={handleChange}
            label={placeholder}
            className={classNames(styles.inputField, {
              [styles.multiSelectInputField]: multiple,
              [styles.inputFieldDropDown]: !!options?.length,
              [styles.inputFieldError]: error,
            })}
            renderValue={(selected) =>
              Array.isArray(selected)
                ? selected
                    .map(
                      (val) => options.find((opt) => opt.value === val)?.label,
                    )
                    .join(', ')
                : options.find((opt) => opt.value === selected)?.label
            }
          >
            {options.map((option, index) => (
              <MenuItem
                className={styles.dropDownItem}
                key={index}
                value={option.value}
              >
                {multiple && <Checkbox checked={isSelected(option.value)} />}
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default MultiSelectInputField;
