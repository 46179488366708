const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export async function getDataWithRetry(
  url: string,
  requestOptions: RequestInit,
  isValid: (data: any) => boolean,
  numOfRetries: number = 10,
): Promise<any> {
  for (let i = 0; i < numOfRetries; i++) {
    try {
      const response = await fetch(url, requestOptions);
      if (response.status === 200) {
        const data = await response.json();
        if (isValid(data)) {
          return data;
        } else {
          console.warn('invalid data for url: ', url);
        }
      }
    } catch (e) {
      console.error(`${url} retry num ${i}`);
      await sleep(2000);
    }
  }
  throw new Error('could not fetch data for URL: ' + url);
}
