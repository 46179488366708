import _ from 'lodash';
import { Dispatch } from 'redux';
import {
  fetchExercises,
  fetchGymsByIds,
  fetchManagerById,
  fetchMemberById,
  fetchMuscles,
  fetchTrainerById,
  fetchTrainerPrograms,
  fetchTrainerExercises,
  fetchTrainerTemplates,
  loadGymMembers,
  loadGymProducts,
  loadGymTrainers,
  fetchMergedExercises,
  fetchGroupsByIds,
  loadGymManagers,
} from './index';
import {
  setExercises,
  setGymData,
  setLoginManager,
  setLoginMember,
  setLoginTrainer,
  setMergedExercises,
  setMuscles,
  setRelatedGyms,
  setSelectedGymId,
} from '../appState/stateActions';

import type { Manager, Member, Trainer } from '../types';
import type { AppState } from '../appState/appInitialState';

export interface LoginStorageData {
  managerId: string | undefined;
  trainerId: string | undefined;
  memberId: string | undefined;
  loginExpiration: number | undefined;
}

const SAVED_LOGIN_DATA_KEY = 'motivision-login-user';

const getUniqueGymIds = (
  manager?: Manager | null,
  trainer?: Trainer | null,
  member?: Member | null,
): string[] => {
  return [
    ...new Set([
      ...(manager?.gymIds || []),
      ...(member?.gymIds || []),
      ...(trainer?.gymIds || []),
    ]),
  ];
};

const getUniqueGroupIds = (trainers: Trainer[]): string[] => {
  return [...new Set(trainers.map(({ groupIds }) => groupIds).flat())];
};

const fetchAndSetMuscles = async (dispatch: Dispatch): Promise<void> => {
  await fetchMuscles().then((muscles) => {
    dispatch(setMuscles(_.keyBy(muscles, 'id')));
  });
};

const fetchAndSetExercises = async (dispatch: Dispatch): Promise<void> => {
  await fetchExercises().then((exercises) => {
    dispatch(setExercises(_.keyBy(exercises, 'id')));
  });
};

const fetchAndSetMergedExercises = async (
  dispatch: Dispatch,
): Promise<void> => {
  await fetchMergedExercises().then((mergedExercises) => {
    dispatch(setMergedExercises(_.keyBy(mergedExercises, 'id')));
  });
};

const fetchAndSetManager = async (
  dispatch: Dispatch,
  managerId?: string,
): Promise<Manager | null> => {
  if (!managerId) return null;
  return fetchManagerById(managerId).then((manager) => {
    if (manager) {
      dispatch(setLoginManager(manager));
    }
    return manager;
  });
};

const fetchAndSetMember = async (
  dispatch: Dispatch,
  memberId?: string,
): Promise<Member | null> => {
  if (!memberId) return null;
  return fetchMemberById(memberId).then((member) => {
    if (member) {
      dispatch(setLoginMember(member));
    }
    return member;
  });
};

const fetchAndSetTrainer = async (
  dispatch: Dispatch,
  trainerId?: string,
): Promise<Trainer | null> => {
  if (!trainerId) return null;
  return fetchTrainerById(trainerId).then(async (trainer) => {
    if (trainer) {
      const [personalizedExercises, templates, programs] = await Promise.all([
        fetchTrainerExercises([trainer.id]),
        fetchTrainerTemplates(trainer.id),
        fetchTrainerPrograms(trainer.id),
      ]);
      dispatch(
        setLoginTrainer(trainer, personalizedExercises, templates, programs),
      );
    }
    return trainer;
  });
};

export const fetchAndSetStateData = async (
  dispatch: Dispatch,
  managerId?: string,
  trainerId?: string,
  memberId?: string,
) => {
  const [manager, trainer, member] = await Promise.all([
    fetchAndSetManager(dispatch, managerId),
    fetchAndSetTrainer(dispatch, trainerId),
    fetchAndSetMember(dispatch, memberId),
  ]);
  const allGymIds = getUniqueGymIds(manager, trainer, member);
  const [relatedGyms] = await Promise.all([
    fetchGymsByIds(allGymIds),
    fetchAndSetMuscles(dispatch),
    fetchAndSetExercises(dispatch),
    fetchAndSetMergedExercises(dispatch),
    ...allGymIds.map(async (gymId) => {
      const [members, { trainers, groups }, managers, gymProducts] =
        await Promise.all([
          loadGymMembers(gymId),
          loadGymTrainers(gymId).then(async (_trainers) => {
            const groupIds = getUniqueGroupIds(_trainers);
            const groups = await fetchGroupsByIds(groupIds);
            return {
              groups,
              trainers: _trainers,
            };
          }),
          loadGymManagers(gymId),
          loadGymProducts(gymId),
        ]);
      const activeMembers = members.filter(({ isActive }) => isActive);
      const activeTrainers = trainers.filter(({ isActive }) => isActive);
      const activeManagers = managers.filter(({ isActive }) => isActive);
      dispatch(
        setGymData(
          gymId,
          activeMembers,
          activeTrainers,
          activeManagers,
          gymProducts,
          groups,
        ),
      );
    }),
  ]);
  dispatch(setRelatedGyms(relatedGyms));
  dispatch(setSelectedGymId(relatedGyms[0]?.id || null));
  return {
    success: Boolean(member || manager || trainer),
    member,
    trainer,
    manager,
  };
};

export const refreshStateAction = () => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const { loginManager, loginTrainer, loginMember } = getState();
    await fetchAndSetStateData(
      dispatch,
      loginManager?.manager?.id,
      loginTrainer?.trainer?.id,
      loginMember?.member?.id,
    );
  };
};

export const setLoginStorageData = (loginStorageData: LoginStorageData) => {
  localStorage.setItem(SAVED_LOGIN_DATA_KEY, JSON.stringify(loginStorageData));
};

export const getLoginStorageData = (): LoginStorageData | null => {
  const loginData = localStorage.getItem(SAVED_LOGIN_DATA_KEY);
  if (!loginData) {
    return null;
  }
  try {
    return JSON.parse(loginData);
  } catch {
    return null;
  }
};

export const clearLoginStorageData = (): void => {
  localStorage.setItem(SAVED_LOGIN_DATA_KEY, '');
};

export const isValidExpirationDate = (
  loginExpiration: number | undefined,
): boolean => {
  return _.isNumber(loginExpiration) && loginExpiration > Date.now();
};

export const hasValidLoginStorageData = (
  loginStorageData: LoginStorageData | null,
): boolean => {
  return Boolean(
    loginStorageData &&
      // isValidExpirationDate(loginStorageData.loginExpiration) &&
      (loginStorageData.memberId ||
        loginStorageData.managerId ||
        loginStorageData.trainerId),
  );
};
