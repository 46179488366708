import React, { createContext, useContext, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../appState/appInitialState';
import { Exercise, Muscle, TrainingRoutineTemplate } from '../types';

export interface GlobalDataContextProps {
  exercises: Record<string, Exercise>;
  mergedExercises: Record<string, Exercise>;
  muscles: Record<string, Muscle>;
  templates: TrainingRoutineTemplate[];
}

const GlobalDataContext = createContext<GlobalDataContextProps>({
  exercises: {},
  muscles: {},
  mergedExercises: {},
  templates: [],
});

export const GlobalDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const exercises = useSelector((state: AppState) => state.exercises);
  const mergedExercises = useSelector(
    (state: AppState) => state.mergedExercises,
  );
  const muscles = useSelector((state: AppState) => state.muscles);
  const templates = useSelector((state: AppState) => state.templates);

  return (
    <GlobalDataContext.Provider
      value={{ exercises, muscles, mergedExercises, templates }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = () => {
  const context = useContext(GlobalDataContext);
  if (!context) {
    throw new Error('useGlobalData must be used within a GlobalDataProvider');
  }
  return context;
};
