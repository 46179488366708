import React from 'react';
import styles from './ListContent.module.scss';
import classNames from 'classnames';

export interface ListContentProps<T extends { id: string }> {
  items: T[];
  titles: string[];
  EmptyStateComponent: React.FC;
  ItemComponent: React.FC<{ data: T }>;
  listHeaderClassName?: string;
}

const ListContent = <T extends { id: string }>({
  items,
  titles,
  ItemComponent,
  EmptyStateComponent,
  listHeaderClassName,
}: ListContentProps<T>) => {
  if (!items.length) {
    return <EmptyStateComponent />;
  }

  return (
    <div className={styles.list}>
      <div className={classNames(styles.listHeader, listHeaderClassName)}>
        {titles.map((title, index) => (
          <div
            key={title}
            className={classNames(styles.tableTitle, {
              [styles.leftTableTitle]: index === 0,
            })}
          >
            {title}
          </div>
        ))}
      </div>
      {items.map((item) => (
        <ItemComponent data={item} key={item.id} />
      ))}
    </div>
  );
};

export default ListContent;
