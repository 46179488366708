import { getDataWithRetry } from '../fetchUtils';
import { isGym } from '../../utils/typeUtils';
import {
  isValidGymProductResponse,
  isValidGymsResponse,
} from '../apiValidators';
import { apiBaseUrl } from '../apiConsts';

import type { Gym, GymProduct } from '../../types';
import type { BaseApiResponse } from '../apiTypes';

export const fetchGym = async (gymId: string): Promise<Gym | null> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}gyms/${gymId}`,
      {},
      isGym,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchGym:', error);
    return null;
  }
};

export const fetchGymsByIds = async (gymIds: string[]): Promise<Gym[]> => {
  try {
    if (gymIds.length === 0) return [];
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}gyms/getByIds`,
      {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(gymIds),
      },
      isValidGymsResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchGymsByIds:', error);
    return [];
  }
};

export const updateGymData = async (gymData: Gym): Promise<BaseApiResponse> => {
  try {
    if (!gymData.id) {
      throw new Error('no gym id found');
    }
    const response = await fetch(`${apiBaseUrl}gyms`, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(gymData),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return {
      success: true,
    };
  } catch (error) {
    const e = error as unknown as Error;
    const errorMessage = `Error in updateGymData: ${e?.message}`;
    console.error(errorMessage);
    return { success: false, errorMessage };
  }
};

export const loadGymProducts = async (gymId: string): Promise<GymProduct[]> => {
  try {
    const gymProducts = await getDataWithRetry(
      `${apiBaseUrl}products/gym/${gymId}`,
      {},
      isValidGymProductResponse,
    );
    return gymProducts;
  } catch (error) {
    console.error('Error in loadGymProducts:', error);
    return [];
  }
};
