import React, { createContext, useContext, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { AppState, GymDashboardData } from '../appState/appInitialState';

const getEmptyGymDashboardData = () => ({
  groups: [],
  managers: [],
  members: [],
  trainers: [],
  gymProducts: [],
});

interface SelectedGymDataProviderProps extends GymDashboardData {
  selectedGymId: string;
}

const SelectedGymDataContext = createContext<SelectedGymDataProviderProps>({
  ...getEmptyGymDashboardData(),
  selectedGymId: '',
});

export const SelectedGymDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { groups, gymProducts, managers, trainers, members } = useSelector(
    (state: AppState) =>
      state.gymDataMap[state.selectedGymId] || getEmptyGymDashboardData(),
  );
  const selectedGymId = useSelector((state: AppState) => state.selectedGymId);

  return (
    <SelectedGymDataContext.Provider
      value={{
        groups,
        gymProducts,
        managers,
        trainers,
        members,
        selectedGymId,
      }}
    >
      {children}
    </SelectedGymDataContext.Provider>
  );
};

export const useSelectedGymData = () => {
  const context = useContext(SelectedGymDataContext);
  if (!context) {
    throw new Error(
      'useSelectedGymData must be used within a SelectedGymDataProvider',
    );
  }
  return context;
};
