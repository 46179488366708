import { getDataWithRetry } from '../fetchUtils';
import {
  isValidGymTrainersResponse,
  isValidTrainerResponse,
} from '../apiValidators';
import { apiBaseUrl } from '../apiConsts';

import type { BaseApiResponse } from '../apiTypes';
import type { Trainer, WithOptionalId } from '../../types';

export const fetchAllTrainers = async (): Promise<Trainer[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}trainers`,
      {},
      isValidGymTrainersResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchAllTrainers:', error);
    return [];
  }
};

export const fetchTrainerById = async (
  trainerId: string,
): Promise<Trainer | null> => {
  try {
    const trainer = await getDataWithRetry(
      `${apiBaseUrl}trainers/${trainerId}`,
      {},
      isValidTrainerResponse,
    );
    return trainer;
  } catch (error) {
    console.error('Error in fetchTrainerById:', error);
    return null;
  }
};

export const createNewTrainer = async (
  trainer: WithOptionalId<Trainer>,
  gymId: string,
): Promise<BaseApiResponse> => {
  try {
    if (!gymId) throw new Error('No Gym Provided');
    const createdTrainer = await fetch(`${apiBaseUrl}trainers`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(trainer),
    }).then((x) => x.json());
    if (createdTrainer.error) {
      throw new Error(createdTrainer.message);
    }
    await fetch(`${apiBaseUrl}gyms/${gymId}/associate/trainers`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify([createdTrainer.id]),
    });
    return {
      success: true,
    };
  } catch (e) {
    const error = e as unknown as Error;
    console.error('Error in createNewTrainer:', error);
    return {
      success: false,
      errorMessage: error?.message || 'Something went wrong',
    };
  }
};

export const updateTrainerData = async (
  trainer: Trainer,
): Promise<BaseApiResponse> => {
  try {
    if (!trainer?.id) {
      throw new Error('No trainer id provided');
    }
    const response = await fetch(`${apiBaseUrl}trainers`, {
      headers: { 'content-type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify(trainer),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    console.error('Error in updateTrainerData:', e);
    return {
      success: false,
      errorMessage: e?.message || 'Something went wrong',
    };
  }
};

export const loadGymTrainers = async (gymId: string): Promise<Trainer[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}trainers/gym/${gymId}`,
      {},
      isValidGymTrainersResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in loadGymTrainers:', error);
    return [];
  }
};
