import React from 'react';
import styles from './ExerciseThumbnail.module.scss';

import type { Exercise } from '../../types';

export interface ExerciseThumbnailProps {
  exercise: Exercise;
  onDragStart: (event: React.DragEvent) => void;
}

const ExerciseThumbnail: React.FC<ExerciseThumbnailProps> = ({
  exercise,
  onDragStart,
}) => {
  return (
    <div
      className={styles.exerciseThumbnailContainer}
      draggable
      onDragStart={onDragStart}
    >
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${exercise.imageUrl})` }}
      />
      <div className={styles.exerciseNameWrapper}>
        <span className={styles.exerciseName}>{exercise.name}</span>
      </div>
    </div>
  );
};

export default ExerciseThumbnail;
