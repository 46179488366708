import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import styles from './AddTrainerDialog.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import { isValidEmail } from '../../utils/utils';
import InputField from '../atoms/InputField';

import type { Trainer, WithOptionalId } from '../../types';
import { useSelectedGymData } from '../../providers';

export interface AddTrainerDialogProps {
  onClose: () => void;
  onSubmit: (
    newTrainer: WithOptionalId<Trainer>,
  ) => Promise<{ success: boolean; errorMessage?: string }>;
  isOpen: boolean;
}

const initialTrainerData = {
  firstName: '',
  lastName: '',
  email: '',
  emailVerification: '',
  phoneNumber: '',
  subscription: '',
};

const AddTrainerDialog: React.FC<AddTrainerDialogProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const { selectedGymId } = useSelectedGymData();
  const [trainerData, setTrainerData] = useState(initialTrainerData);

  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailVerificationError, setEmailVerificationError] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setTrainerData(initialTrainerData);
      setFirstNameError(false);
      setLastNameError(false);
      setPhoneNumberError(false);
      setEmailError(false);
      setEmailVerificationError(false);
      setLoading(false);
      setErrorMessage(null);
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    setErrorMessage(null);
    if (!trainerData.firstName) return setFirstNameError(true);
    if (!trainerData.lastName) return setLastNameError(true);
    if (!isValidEmail(trainerData.email)) return setEmailError(true);
    // if (!trainerData.phoneNumber) return setPhoneNumberError(true);
    if (trainerData.email !== trainerData.emailVerification)
      return setEmailVerificationError(true);
    setEmailVerificationError(false);

    setLoading(true);

    const newTrainer: WithOptionalId<Trainer> = {
      phoneNumber: trainerData.phoneNumber,
      email: trainerData.email,
      firstName: trainerData.firstName,
      lastName: trainerData.lastName,
      gymIds: [selectedGymId],
      isActive: true,
      groupIds: [],
      isManager: false,
      trainingRoutineTemplateIds: [],
      exerciseIds: [],
      memberIds: [],
    };

    try {
      const { success, errorMessage } = await onSubmit(newTrainer);

      if (success) {
        setTrainerData(initialTrainerData);
        onClose();
      } else {
        setErrorMessage(errorMessage || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BaseDialog
        onClose={onClose}
        isOpen={isOpen}
        title="Add A Trainer"
        actions={
          <Button
            className={styles.submit}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              <>
                <AddBoxOutlinedIcon color="primary" />
                <span className={styles.submitText}>Add Trainer</span>
              </>
            )}
          </Button>
        }
      >
        <DialogContent className={styles.trainerDataDialog}>
          <InputField
            icon={<PersonOutlineOutlinedIcon color="primary" />}
            title="First Name"
            inputName="firstName"
            autoComplete="firstName"
            value={trainerData.firstName}
            onChange={(firstName) => {
              setFirstNameError(false);
              setTrainerData((prevTrainerData) => ({
                ...prevTrainerData,
                firstName,
              }));
            }}
            error={firstNameError}
          />
          <InputField
            icon={<PersonOutlineOutlinedIcon color="primary" />}
            title="Last Name"
            inputName="lastName"
            autoComplete="lastName"
            value={trainerData.lastName}
            onChange={(lastName) => {
              setLastNameError(false);
              setTrainerData((prevTrainerData) => ({
                ...prevTrainerData,
                lastName,
              }));
            }}
            error={lastNameError}
          />
          <InputField
            icon={<MailOutlineRoundedIcon color="primary" />}
            title="Email"
            inputName="email"
            autoComplete="email"
            value={trainerData.email}
            onChange={(email) => {
              setEmailError(false);
              setEmailVerificationError(false);
              setTrainerData((prevTrainerData) => ({
                ...prevTrainerData,
                email,
              }));
            }}
            onBlur={() => setEmailError(!isValidEmail(trainerData.email))}
            error={emailError}
          />
          <InputField
            icon={<MailOutlineRoundedIcon color="primary" />}
            title="Email Verification"
            inputName="emailVerification"
            autoComplete="email"
            value={trainerData.emailVerification}
            onChange={(emailVerification) => {
              setEmailVerificationError(false);
              setTrainerData((prevTrainerData) => ({
                ...prevTrainerData,
                emailVerification,
              }));
            }}
            onBlur={() =>
              setEmailVerificationError(
                trainerData.email !== trainerData.emailVerification,
              )
            }
            error={emailVerificationError}
          />
          <InputField
            icon={<LocalPhoneOutlinedIcon color="primary" />}
            title="Phone Number"
            inputName="phoneNumber"
            autoComplete="phone"
            value={trainerData.phoneNumber}
            onChange={(phoneNumber) => {
              setPhoneNumberError(false);
              setTrainerData((prevTrainerData) => ({
                ...prevTrainerData,
                phoneNumber,
              }));
            }}
            error={phoneNumberError}
          />
        </DialogContent>
      </BaseDialog>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
        >
          <Alert onClose={() => setErrorMessage(null)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AddTrainerDialog;
