import React, { useMemo, useState } from 'react';
import managerRowStyles from './ManagerRow.module.scss';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import EmptyState from '../composites/EmptyState';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import ManagerRow from './ManagerRow';
import ListContent from '../composites/ListContent';
import { createNewManager, updateManagerData } from '../../api';
import { getFullName } from '../../utils/dashboardUtils';
import AddManagerDialog from './AddManagerDialog';
import {
  useLoginData,
  useRefreshState,
  useSelectedGymData,
} from '../../providers';

import type { Manager, WithOptionalId } from '../../types';

const managerListTitles = ['Manager Name', 'Options'];

const Managers: React.FC = () => {
  const { managers: allGymManagers, selectedGymId } = useSelectedGymData();
  const refreshState = useRefreshState();
  const { managerData } = useLoginData();
  const manager = managerData?.manager;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedManager, setSelectedManager] = useState<string>('');
  const [isAddManagerModalOpened, setIsAddManagerModalOpened] = useState(false);

  const filteredManagers = useMemo(() => {
    if (!allGymManagers) return [];
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return allGymManagers
      .filter((manager) =>
        searchQueryRegex.test(getFullName(manager).toLowerCase()),
      )
      .sort(
        (a, b) =>
          a.firstName
            ?.toLowerCase()
            .localeCompare(b.firstName?.toLowerCase() || '') || 0,
      );
  }, [searchQuery, allGymManagers]);

  if (!manager || !selectedGymId) {
    return (
      <EmptyState icon={<Person4OutlinedIcon />} text="No managers found." />
    );
  }

  const renderEmptyState = () => (
    <EmptyState
      icon={<Person4OutlinedIcon />}
      cta={{
        icon: <Person4OutlinedIcon color="primary" />,
        label: 'Add A Manager',
        onClick: () => setIsAddManagerModalOpened(true),
      }}
      text="No Managers Have Been Added"
    />
  );

  const addManager = async (manager: WithOptionalId<Manager>) => {
    const { success, errorMessage } = await createNewManager(
      manager,
      selectedGymId,
    );
    if (!success) {
      return { success, errorMessage };
    }
    await refreshState();
    setIsAddManagerModalOpened(false);
    return { success: true };
  };

  const renderAddProgramDialog = () => (
    <AddManagerDialog
      onSubmit={addManager}
      onClose={() => setIsAddManagerModalOpened(false)}
      isOpen={isAddManagerModalOpened}
    />
  );

  const deleteManager = async (manager: Manager) => {
    await updateManagerData({ ...manager, isActive: false });
    await refreshState();
  };

  const renderContent = () => {
    return (
      <ListContent
        items={filteredManagers}
        listHeaderClassName={managerRowStyles.listGrid}
        EmptyStateComponent={renderEmptyState}
        titles={managerListTitles}
        ItemComponent={({ data }) => (
          <ManagerRow
            manager={data}
            deleteManager={deleteManager}
            selected={selectedManager === data.id}
            onSelect={() => setSelectedManager(data.id)}
            key={data.id}
          />
        )}
      />
    );
  };

  return (
    <DashboardContentWrapper
      searchPlaceholder="Search for Managers"
      title="Managers"
      setSearchQuery={setSearchQuery}
      actions={[
        {
          icon: <AddBoxOutlinedIcon />,
          label: 'Add A Manager',
          onClick: () => setIsAddManagerModalOpened(true),
        },
      ]}
    >
      {renderContent()}
      {renderAddProgramDialog()}
    </DashboardContentWrapper>
  );
};

export default Managers;
