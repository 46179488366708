import React from 'react';
import { Button } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button/Button';

export const LoginPageButton: React.FC<ButtonProps> = (props) => (
  <Button
    type="button"
    fullWidth
    variant="contained"
    color="info"
    sx={{
      position: 'relative',
      borderRadius: 4,
      marginTop: 2,
      height: 50,
      textTransform: 'none',
      fontWeight: 'bold',
    }}
    {...props}
  >
    {props.children}
  </Button>
);
