import React, { useRef, useState } from 'react';
import styles from './ProgramItemRow.module.scss';
import classNames from 'classnames';
import Menu from '../atoms/Menu';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import ContentCopy from '@mui/icons-material/ContentCopy';

import {
  calculateTemplateExercises,
  getFullName,
} from '../../utils/dashboardUtils';
import { useSelectedGymData } from '../../providers';

import type { TrainingRoutine } from '../../types';

interface ProgramItemRowProps {
  routine: TrainingRoutine;
  onDuplicate: () => void;
  onRemove: () => void;
  onEdit: () => void;
  onDragStart: (event: React.DragEvent, index: number) => void;
  onDragOver: (event: React.DragEvent) => void;
  onDrop: (event: React.DragEvent, index: number) => void;
  index: number;
}

const ProgramItemRow: React.FC<ProgramItemRowProps> = ({
  routine,
  onRemove,
  onEdit,
  onDuplicate,
  onDragStart,
  onDragOver,
  onDrop,
  index,
}) => {
  const { trainers } = useSelectedGymData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const programItemRowRef = useRef<HTMLDivElement>(null);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const handleDuplicateWorkout = () => {
    handleCloseMenuOptions();
    onDuplicate();
  };

  const handleEditWorkout = () => {
    handleCloseMenuOptions();
    onEdit();
  };

  const handleDeleteWorkout = async () => {
    handleCloseMenuOptions();
    onRemove();
  };

  const handleDragStart = (e: React.DragEvent) => {
    const programItemEl = programItemRowRef.current;
    if (programItemEl) {
      e.dataTransfer.setDragImage(programItemEl, 0, 0);
    }
    onDragStart(e, index);
  };

  const renderDragIndication = () => (
    <div
      className={classNames(styles.programItemColumn, styles.dragIndication)}
      onDragStart={handleDragStart}
      draggable
      onDragOver={(e) => e.preventDefault()}
    >
      <DragIndicatorOutlinedIcon />
    </div>
  );

  const renderNameAndDescription = () => (
    <div
      className={classNames(
        styles.programItemColumn,
        styles.programNameAndDescription,
      )}
    >
      <div className={styles.routineName}>{routine.name}</div>
      <div className={styles.routineDescription}>{routine.description}</div>
    </div>
  );

  const renderTrainerName = () => {
    const trainer = trainers.find(
      ({ id }) => id === routine.templateInfo?.trainerId,
    );
    const trainerName = getFullName(trainer);
    return (
      <div className={classNames(styles.programItemColumn)}>{trainerName}</div>
    );
  };

  const renderNumExercises = () => {
    const routineExercisesCount = calculateTemplateExercises(routine);
    return (
      <div className={classNames(styles.programItemColumn)}>
        {`${routineExercisesCount} ${
          routineExercisesCount === 1 ? 'Exercise' : 'Exercises'
        }`}
      </div>
    );
  };

  const getProgramOptions = () => [
    {
      onClick: handleDuplicateWorkout,
      label: 'Duplicate Workout',
      icon: <ContentCopy color="primary" />,
    },
    {
      onClick: handleEditWorkout,
      label: 'Edit Workout',
      icon: <BorderColorOutlinedIcon color="primary" />,
    },
    {
      onClick: handleDeleteWorkout,
      label: 'Delete Workout',
      icon: <DeleteForeverOutlinedIcon color="primary" />,
    },
  ];

  const renderMoreOptions = () => (
    <div className={classNames(styles.programItemColumn, styles.options)}>
      <PendingOutlinedIcon
        // @ts-ignore
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={styles.optionsBtn}
        color="primary"
      />
      <Menu
        anchorEl={anchorEl}
        isOpen={open}
        onClose={handleCloseMenuOptions}
        options={getProgramOptions()}
      />
    </div>
  );

  return (
    <div
      draggable
      ref={programItemRowRef}
      onDragOver={onDragOver}
      onDrop={(event) => onDrop(event, index)}
      className={classNames(styles.ProgramItemRow, styles.listGrid)}
    >
      {renderDragIndication()}
      {renderNameAndDescription()}
      {renderNumExercises()}
      {renderTrainerName()}
      {renderMoreOptions()}
    </div>
  );
};

export default ProgramItemRow;
