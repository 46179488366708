import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, CircularProgress, DialogContent } from '@mui/material';
import styles from './AssignProgramDialog.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import SearchBar from '../atoms/SearchBar';
import ProgramRow from './ProgramRow';
import { useLoginData } from '../../providers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { addDays, subDays } from 'date-fns';

import type { Program } from '../../types';

export interface AssignProgramDialogProps {
  onClose: () => void;
  onSubmit: (program: Program) => Promise<void>;
  isOpen: boolean;
}

const AssignProgramDialog: React.FC<AssignProgramDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { trainerData } = useLoginData();
  const trainerPrograms = trainerData?.programs || [];
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  useEffect(() => {
    if (!isOpen) {
      setSelectedProgram(null);
      setSearchQuery('');
      setSelectedDate(new Date());
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    if (!selectedProgram) return;
    setLoading(true);
    const assignedProgram = {
      ...selectedProgram,
      startDate: selectedDate.toISOString(),
    };
    await onSubmit(assignedProgram);
    setLoading(false);
  };

  const filteredPrograms = trainerPrograms.filter(({ name }) =>
    new RegExp(searchQuery, 'i').test(name),
  );

  const renderSelectionView = () => {
    return (
      <DialogContent className={styles.assignProgramDialog}>
        <SearchBar
          className={styles.assignProgramSearch}
          placeholder="Search"
          setSearchQuery={setSearchQuery}
        />
        <div className={styles.programItems}>
          {filteredPrograms.map((program, index) => {
            const isSelected = selectedProgram?.id === program.id;
            return (
              <div
                onClick={() => setSelectedProgram(program)}
                className={classNames(styles.programItemWrapper, {
                  [styles.selectedProgramItem]: isSelected,
                })}
                key={`programItem-${program.name}-${index}`}
              >
                <ProgramRow
                  minimalDetails={true}
                  program={program}
                  selected={isSelected}
                />
              </div>
            );
          })}
        </div>
      </DialogContent>
    );
  };

  const renderCalendarView = () => {
    return (
      <DialogContent className={styles.calendarDialog}>
        <DatePicker
          selected={selectedDate}
          onChange={(date: Date | null) => {
            if (date) setSelectedDate(date);
          }}
          inline
          // minDate={subDays(new Date(), 0)} // Optional: Disable past dates
          // maxDate={addDays(new Date(), 365)} // Optional: Restrict future dates
          calendarClassName={styles.customCalendar} // Link to custom styling
        />
      </DialogContent>
    );
  };

  const renderContentView = () => {
    return selectedProgram ? renderCalendarView() : renderSelectionView();
  };

  return (
    <BaseDialog
      onClose={onClose}
      isOpen={isOpen}
      title={
        selectedProgram ? 'Select Start Date' : 'Select a Program to Assign'
      }
      actions={
        <Button
          disabled={loading}
          className={styles.submit}
          onClick={handleSubmit}
        >
          {loading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            <span className={styles.submitText}>Assign Program</span>
          )}
        </Button>
      }
    >
      {renderContentView()}
    </BaseDialog>
  );
};

export default AssignProgramDialog;
