import React, { useState, useRef } from 'react';
import styles from './InputField.module.scss';
import { TextField } from '@mui/material';
import classNames from 'classnames';

interface TimeInputFieldProps {
  title?: string;
  inputName: string;
  value: number;
  icon?: React.ReactNode;
  error?: boolean;
  className?: string;
  autoComplete?: string;
  onBlur: (timeValue: number) => void;
}

const MAX_TIME_IN_SECONDS = 59 * 60 + 59; // Maximum time allowed (59:59)

const formatTimeValue = (timeInSeconds: number): string => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

function sanitizeTimeValue(input: string): string {
  return input.replace(/[^0-9:]/g, '');
}

const parseTimeValue = (timeString: string): number => {
  if (timeString.includes(':')) {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return minutes * 60 + seconds;
  }
  return Number(timeString);
};

const TimeInputField = ({
  value,
  icon,
  onBlur,
  inputName,
  autoComplete,
  title,
  error,
  className,
}: TimeInputFieldProps) => {
  const [displayedValue, setDisplayedValue] = useState<string>(
    formatTimeValue(value),
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBlur = () => {
    let numericValue = parseTimeValue(displayedValue);
    if (numericValue > MAX_TIME_IN_SECONDS) {
      numericValue = MAX_TIME_IN_SECONDS;
    }
    setDisplayedValue(formatTimeValue(numericValue));
    onBlur(numericValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      inputRef.current?.blur(); // Trigger blur when Enter key is pressed
    }
  };

  return (
    <div className={classNames(styles.inputFieldItem, className)}>
      {(icon || title) && (
        <div className={styles.fieldTitleWrapper}>
          {icon}
          {title && <span className={styles.fieldTitle}>{title}</span>}
        </div>
      )}
      <div className={styles.inputWrapper}>
        <TextField
          inputRef={inputRef}
          name={inputName}
          margin="dense"
          fullWidth
          autoComplete={autoComplete}
          variant="outlined"
          value={displayedValue}
          error={error}
          onChange={(e) => setDisplayedValue(sanitizeTimeValue(e.target.value))}
          className={classNames(styles.inputField, {
            [styles.inputFieldError]: error,
          })}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default TimeInputField;
