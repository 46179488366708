import React from 'react';
import RouterWrapper from './router/RouterWrapper';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import styles from './App.module.scss';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './appTheme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={styles.app}>
        <Router>
          <RouterWrapper />
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
