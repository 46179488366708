// import _ from 'lodash';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './SetValues.module.scss';
import InputField from '../atoms/InputField';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import TimeInputField from '../atoms/TimeInputField';

import type { TrainingSet } from '../../types';

export interface SetValuesProps {
  index: number;
  set: TrainingSet;
  updateSet: (sets: TrainingSet) => void;
  deleteSet: () => void;
  canBeAutomatic: boolean;
}

const SetValues: React.FC<SetValuesProps> = ({
  set,
  updateSet,
  index,
  deleteSet,
  canBeAutomatic,
}) => {
  const [weight, setWeight] = useState(set.weight || '');
  const [reps, setReps] = useState(set.reps || '');
  const [rest, setRest] = useState(set.rest || 0);
  const [minConcentric, setMinConcentric] = useState(
    set.metrics?.minConcentric !== null ? set.metrics?.minConcentric : '',
  );
  const [maxConcentric, setMaxConcentric] = useState(
    set.metrics?.maxConcentric !== null ? set.metrics?.maxConcentric : '',
  );
  const [minEccentric, setMinEccentric] = useState(
    set.metrics?.minEccentric !== null ? set.metrics?.minEccentric : '',
  );
  const [maxEccentric, setMaxEccentric] = useState(
    set.metrics?.maxEccentric !== null ? set.metrics?.maxEccentric : '',
  );

  const renderAutomaticSetInputs = () => {
    return (
      <>
        <InputField
          // Hack because I didn't manage to center the placeholder
          placeholder="&nbsp;&nbsp;&nbsp;-"
          type="number"
          className={styles.metricInput}
          inputName="minConcentric"
          value={minConcentric}
          onChange={(newValue) => {
            setMinConcentric(newValue);
            updateSet({
              ...set,
              metrics: {
                ...set.metrics,
                minConcentric: Number(newValue) || null,
              },
            });
          }}
        />
        <InputField
          // Hack because I didn't manage to center the placeholder
          placeholder="&nbsp;&nbsp;&nbsp;-"
          type="number"
          className={styles.metricInput}
          inputName="maxConcentric"
          value={maxConcentric}
          onChange={(newValue) => {
            setMaxConcentric(newValue);
            updateSet({
              ...set,
              metrics: {
                ...set.metrics,
                maxConcentric: Number(newValue) || null,
              },
            });
          }}
        />
        <InputField
          // Hack because I didn't manage to center the placeholder
          placeholder="&nbsp;&nbsp;&nbsp;-"
          type="number"
          className={styles.metricInput}
          inputName="minEccentric"
          value={minEccentric}
          onChange={(newValue) => {
            setMinEccentric(newValue);
            updateSet({
              ...set,
              metrics: {
                ...set.metrics,
                minEccentric: Number(newValue) || null,
              },
            });
          }}
        />
        <InputField
          // Hack because I didn't manage to center the placeholder
          placeholder="&nbsp;&nbsp;&nbsp;-"
          type="number"
          className={styles.metricInput}
          inputName="maxEccentric"
          value={maxEccentric}
          onChange={(newValue) => {
            setMaxEccentric(newValue);
            updateSet({
              ...set,
              metrics: {
                ...set.metrics,
                maxEccentric: Number(newValue) || null,
              },
            });
          }}
        />
      </>
    );
  };

  return (
    <div
      className={classNames(
        styles.setValues,
        canBeAutomatic ? styles.automaticSetProperties : styles.setProperties,
      )}
    >
      <div className={styles.setTitle}>SET {index + 1}</div>
      <InputField
        // Hack because I didn't manage to center the placeholder
        placeholder="&nbsp;&nbsp;&nbsp;-"
        type="number"
        className={styles.setInput}
        inputName="weight"
        value={weight}
        onChange={(newWeight) => {
          setWeight(newWeight);
          updateSet({ ...set, weight: Number(newWeight) });
        }}
      />
      <InputField
        // Hack because I didn't manage to center the placeholder
        placeholder="&nbsp;&nbsp;&nbsp;-"
        type="number"
        className={styles.setInput}
        inputName="reps"
        value={reps}
        onChange={(newReps) => {
          setReps(newReps);
          updateSet({ ...set, reps: Number(newReps) });
        }}
      />
      <TimeInputField
        className={styles.setInput}
        inputName="rest"
        value={rest}
        onBlur={(newRest) => {
          setRest(newRest);
          updateSet({ ...set, rest: Number(newRest) });
        }}
      />
      {canBeAutomatic && renderAutomaticSetInputs()}
      <span onClick={deleteSet} className={styles.deleteSet}>
        <DeleteForeverOutlinedIcon />
      </span>
    </div>
  );
};

SetValues.displayName = 'SetValues';

export default SetValues;
