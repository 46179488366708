import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { refreshStateAction } from '../api/loginApiHelper';

const RefreshStateContext = createContext<() => Promise<void>>(() =>
  Promise.resolve(),
);

export const RefreshStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const refreshState = async () => {
    // @ts-ignore
    await dispatch(refreshStateAction());
  };

  return (
    <RefreshStateContext.Provider value={refreshState}>
      {children}
    </RefreshStateContext.Provider>
  );
};

export const useRefreshState = () => {
  return useContext(RefreshStateContext);
};
