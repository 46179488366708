import React, { createContext, useContext, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import {
  AppState,
  ManagerStateData,
  MemberStateData,
  TrainerStateData,
} from '../appState/appInitialState';
import type { Gym } from '../types';

export interface LoginDataContextProps {
  trainerData?: TrainerStateData | null;
  memberData?: MemberStateData | null;
  managerData?: ManagerStateData | null;
  relatedGyms: Gym[];
}

const LoginDataContext = createContext<LoginDataContextProps>({
  relatedGyms: [],
});

export const LoginDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const trainerData = useSelector((state: AppState) => state.loginTrainer);
  const memberData = useSelector((state: AppState) => state.loginMember);
  const managerData = useSelector((state: AppState) => state.loginManager);
  const relatedGyms = useSelector((state: AppState) => state.relatedGyms);

  return (
    <LoginDataContext.Provider
      value={{ trainerData, memberData, managerData, relatedGyms }}
    >
      {children}
    </LoginDataContext.Provider>
  );
};

export const useLoginData = () => {
  const context = useContext(LoginDataContext);
  if (!context) {
    throw new Error('useLoginData must be used within a LoginDataProvider');
  }
  return context;
};
