import React from 'react';
import styles from './SearchBar.module.scss';
import classNames from 'classnames';

export interface SearchBarProps {
  placeholder: string;
  setSearchQuery: (query: string) => void;
  className?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  setSearchQuery,
  placeholder,
  className,
}) => (
  <div className={classNames(styles.searchBar, className)}>
    <input
      onChange={(e) => setSearchQuery(e.target.value)}
      type="text"
      placeholder={placeholder}
    />
  </div>
);

export default SearchBar;
