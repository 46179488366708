export default {
  SET_RELATED_GYMS: 'SET_RELATED_GYMS',
  SET_EXERCISES: 'SET_EXERCISES',
  SET_MERGED_EXERCISES: 'SET_MERGED_EXERCISES',
  SET_TEMPLATES: 'SET_TEMPLATES',
  SET_MUSCLES: 'SET_MUSCLES',
  SET_STATIONS: 'SET_STATIONS',
  SET_GYM_DATA: 'SET_GYM_DATA',
  RESET_ALL_STATE: 'RESET_ALL_STATE',
  SET_LOGIN_MEMBER: 'SET_LOGIN_MEMBER',
  SET_LOGIN_MANAGER: 'SET_LOGIN_MANAGER',
  SET_LOGIN_TRAINER: 'SET_LOGIN_TRAINER',
  SET_SELECTED_GYM_ID: 'SET_SELECTED_GYM_ID',
};
