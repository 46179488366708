import _ from 'lodash';
import { getDataWithRetry } from '../fetchUtils';
import { isValidExercisesResponse } from '../apiValidators';
import { apiBaseUrl } from '../apiConsts';

import type { Exercise, WithOptionalId } from '../../types';
import type { BaseApiResponse, FileResponse } from '../apiTypes';

export const fetchExercises = async (): Promise<Exercise[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}exercises`,
      {},
      isValidExercisesResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchExercises:', error);
    return [];
  }
};

export const fetchMergedExercises = async (): Promise<Exercise[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}exercises/merged`,
      {},
      isValidExercisesResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchMergedExercises:', error);
    return [];
  }
};

const associateExerciseMuscle = async (
  exerciseId: string,
  muscleGroupType: string,
  ids: string[],
) => {
  await fetch(
    `${apiBaseUrl}exercises/${exerciseId}/associate/${muscleGroupType}`,
    {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(ids),
    },
  );
};

const unassociateExerciseMuscle = async (
  exerciseId: string,
  muscleGroupType: string,
  ids: string[],
) => {
  await fetch(
    `${apiBaseUrl}exercises/${exerciseId}/unassociate/${muscleGroupType}`,
    {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(ids),
    },
  );
};

export const updateExerciseData = async (
  oldExerciseData: Exercise,
  newExerciseData: Exercise,
): Promise<BaseApiResponse> => {
  if (!newExerciseData?.id) {
    throw new Error('No exercise id provided');
  }
  try {
    const response = await fetch(`${apiBaseUrl}exercises`, {
      headers: { 'content-type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify(newExerciseData),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const primaryMuscleIdsToUnassociate = _.difference(
      oldExerciseData.primaryMuscleGroupIds,
      newExerciseData.primaryMuscleGroupIds,
    );
    const primaryMuscleIdsToAssociate = _.difference(
      newExerciseData.primaryMuscleGroupIds,
      oldExerciseData.primaryMuscleGroupIds,
    );
    const secondaryMuscleIdsToUnassociate = _.difference(
      oldExerciseData.primaryMuscleGroupIds,
      newExerciseData.primaryMuscleGroupIds,
    );
    const secondaryMuscleIdsToAssociate = _.difference(
      newExerciseData.secondaryMuscleGroupIds,
      oldExerciseData.secondaryMuscleGroupIds,
    );
    if (primaryMuscleIdsToUnassociate.length > 0) {
      await unassociateExerciseMuscle(
        newExerciseData.id,
        'primary-muscles',
        primaryMuscleIdsToUnassociate,
      );
    }
    if (primaryMuscleIdsToAssociate.length > 0) {
      await associateExerciseMuscle(
        newExerciseData.id,
        'primary-muscles',
        primaryMuscleIdsToAssociate,
      );
    }
    if (secondaryMuscleIdsToUnassociate.length > 0) {
      await unassociateExerciseMuscle(
        newExerciseData.id,
        'secondary-muscles',
        secondaryMuscleIdsToUnassociate,
      );
    }
    if (secondaryMuscleIdsToAssociate.length > 0) {
      await associateExerciseMuscle(
        newExerciseData.id,
        'secondary-muscles',
        secondaryMuscleIdsToAssociate,
      );
    }
    return {
      success: true,
    };
  } catch (e) {
    const error = e as unknown as Error;
    console.error('Error in updateExerciseData:', error);
    return {
      success: false,
      errorMessage: error?.message || 'Something went wrong',
    };
  }
};

export const createPersonalizedExercise = async (
  exercise: WithOptionalId<Exercise>,
): Promise<BaseApiResponse> => {
  try {
    if (exercise.id) {
      throw new Error('cannot create an exercise with id');
    }
    await fetch(`${apiBaseUrl}exercises/personalized`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(exercise),
    });
    return {
      success: true,
    };
  } catch (e) {
    const error = e as unknown as Error;
    console.error('Error in createPersonalizedExercise:', error);
    return {
      success: false,
      errorMessage: error?.message || 'Something went wrong',
    };
  }
};

export const createGlobalExercise = async (
  exercise: WithOptionalId<Exercise>,
): Promise<BaseApiResponse> => {
  try {
    if (exercise.id) {
      throw new Error('cannot create an exercise with id');
    }
    await fetch(`${apiBaseUrl}exercises`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(exercise),
    });
    return {
      success: true,
    };
  } catch (e) {
    const error = e as unknown as Error;
    console.error('Error in createGlobalExercise:', error);
    return {
      success: false,
      errorMessage: error?.message || 'Something went wrong',
    };
  }
};

export const removeExercise = async (exerciseId: string): Promise<void> => {
  try {
    if (!exerciseId) throw new Error('exerciseId is required');
    await fetch(`${apiBaseUrl}exercises/${exerciseId}`, {
      method: 'DELETE',
    });
  } catch (e) {
    console.error('Error in removeExercise:', e);
  }
};

export const uploadExerciseImages = async (
  files: File[],
  trainerId: string,
): Promise<FileResponse[]> => {
  if (!files?.length || !trainerId) {
    throw new Error('wrong parameters in uploadImage');
  }
  const filesFormData = new FormData();
  files.forEach((file) => {
    filesFormData.append('files', file);
  });
  try {
    const response = await fetch(
      `${apiBaseUrl}exercises/uploadBulkImages?trainerId=${trainerId}&compress=true`,
      {
        mode: 'cors',
        method: 'POST',
        body: filesFormData,
      },
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData: Record<string, string> = await response.json();
    return Object.entries(responseData).map(([fileName, fileUrl]) => ({
      fileName,
      fileUrl,
    }));
  } catch (e) {
    console.error('error in uploadImage:', e);
    return [];
  }
};

export const uploadExerciseVideos = async (
  files: File[],
  trainerId: string,
): Promise<FileResponse[]> => {
  if (!files?.length || !trainerId) {
    throw new Error('wrong parameters in uploadExerciseVideos');
  }
  const filesFormData = new FormData();
  files.forEach((file) => {
    filesFormData.append('files', file);
  });
  try {
    const response = await fetch(
      `${apiBaseUrl}exercises/uploadBulkVideos?trainerId=${trainerId}&compress=true`,
      {
        mode: 'cors',
        method: 'POST',
        body: filesFormData,
      },
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData: Record<string, string> = await response.json();
    return Object.entries(responseData).map(([fileName, fileUrl]) => ({
      fileName,
      fileUrl,
    }));
  } catch (e) {
    console.error('error in uploadExerciseVideos:', e);
    return [];
  }
};

export const fetchTrainerExercises = async (
  trainerIds: string[],
): Promise<Exercise[]> => {
  try {
    const trainerExercises = await Promise.all(
      trainerIds.map((trainerId) =>
        getDataWithRetry(
          `${apiBaseUrl}exercises/trainer/${trainerId}`,
          {},
          isValidExercisesResponse,
        ),
      ),
    );
    return trainerExercises
      .map(({ content }) => content)
      .flat()
      .filter(Boolean);
  } catch (error) {
    console.error('Error in fetchTrainerExercises:', error);
    return [];
  }
};
