import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Home from '../screens/LoginPage/LoginPage';
import Dashboard from '../screens/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import { getLoginStorageData } from '../api/loginApiHelper';
import LoginRedirect from '../screens/LoginRedirect';
import { DashboardRoute } from './routes';

export interface RouterWrapperProps {}

const pathNotExist = (pathName: string) =>
  !Object.values(DashboardRoute).includes(pathName as DashboardRoute);

const RouterWrapper: React.FC<RouterWrapperProps> = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const loginStorageData = getLoginStorageData();
    if (loginStorageData) {
      navigate(DashboardRoute.LoginRedirect);
      return;
    }
    if (pathNotExist(location.pathname)) {
      navigate(DashboardRoute.Home);
    }
  }, []);
  return (
    <Routes>
      <Route path={DashboardRoute.Home} element={<Home />} />
      <Route path={DashboardRoute.LoginRedirect} element={<LoginRedirect />} />
      <Route element={<ProtectedRoute />}>
        <Route path={DashboardRoute.Dashboard} element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default RouterWrapper;
