import React, { useMemo, useState } from 'react';
import templateRowStyles from './TemplateRow.module.scss';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import EmptyState from '../composites/EmptyState';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import TemplateRow from './TemplateRow';
import ListContent from '../composites/ListContent';
import AddTemplateDialog from './AddTemplateDialog';
import EditTemplate from './EditTemplate';
import {
  createNewTemplate,
  removeTemplate,
  updateTemplateData,
} from '../../api';
import { useRefreshState, useLoginData } from '../../providers';

import type { TrainingRoutineTemplate, WithOptionalId } from '../../types';

const templateListTitles = [
  'Workout Name',
  'Exercises',
  'Created By',
  'Options',
];

const TrainerTemplatesContent: React.FC = () => {
  const { trainerData } = useLoginData();
  const refreshState = useRefreshState();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [isAddTemplateModalOpen, setIsAddTemplateModalOpen] = useState(false);
  const [editedTemplate, setEditedTemplate] =
    useState<WithOptionalId<TrainingRoutineTemplate> | null>(null);

  const trainer = trainerData?.trainer;
  const templates = trainerData?.templates;

  // TODO: Fix filtered templates. For some reason it filters out
  //some templates unless write the whole name.
  const filteredTemplates = useMemo(() => {
    if (!templates) return [];
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return templates
      .filter(({ name }) => searchQueryRegex.test(name.toLowerCase()))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [searchQuery, templates]);

  const renderEmptyState = () => (
    <EmptyState
      icon={<FitnessCenterOutlinedIcon />}
      cta={{
        icon: <AddBoxOutlinedIcon color="primary" />,
        label: 'Add A Workout',
        onClick: () => setIsAddTemplateModalOpen(true),
      }}
      text="No Workouts Have Been Added"
    />
  );

  if (!trainer) {
    return (
      <EmptyState
        icon={<FitnessCenterOutlinedIcon />}
        text="No workouts for your role."
      />
    );
  }

  const handleEditTemplate = async (
    newTemplateData: WithOptionalId<TrainingRoutineTemplate>,
  ) => {
    const { success, errorMessage } = newTemplateData.id
      ? await updateTemplateData(newTemplateData as TrainingRoutineTemplate)
      : await createNewTemplate(newTemplateData, trainer.id);
    if (!success) {
      return { success, errorMessage };
    }
    await refreshState();
    setIsAddTemplateModalOpen(false);
    return { success: true };
  };

  const renderAddTemplateDialog = () => (
    <AddTemplateDialog
      title="Create A Template"
      trainerId={trainer.id}
      addTemplate={setEditedTemplate}
      onClose={() => setIsAddTemplateModalOpen(false)}
      isOpen={isAddTemplateModalOpen}
    />
  );

  const deleteTemplate = async (template: TrainingRoutineTemplate) => {
    await removeTemplate(template.id);
    await refreshState();
  };

  const duplicateTemplate = async (template: TrainingRoutineTemplate) => {
    const newTemplate = {
      ...template,
      name: `${template.name} - Copy`,
      routine: {
        ...template.routine,
        name: `${template.name} - Copy`,
      },
      id: undefined,
    };
    await createNewTemplate(newTemplate, trainer.id);
    await refreshState();
  };

  const renderContent = () => {
    if (editedTemplate) return null;
    return (
      <ListContent
        items={filteredTemplates}
        listHeaderClassName={templateRowStyles.listGrid}
        EmptyStateComponent={renderEmptyState}
        titles={templateListTitles}
        ItemComponent={({ data }) => (
          <TemplateRow
            template={data}
            deleteTemplate={deleteTemplate}
            duplicateTemplate={duplicateTemplate}
            openEditTemplateModal={() => setEditedTemplate(data)}
            selected={selectedTemplate === data.id}
            onSelect={() => setSelectedTemplate(data.id)}
            key={data.id}
            trainer={trainer}
          />
        )}
      />
    );
  };

  const getDrillInContent = () => {
    if (!editedTemplate) return null;
    return (
      <EditTemplate
        onSubmit={handleEditTemplate}
        template={editedTemplate}
        setTemplate={
          setEditedTemplate as React.Dispatch<
            React.SetStateAction<WithOptionalId<TrainingRoutineTemplate>>
          >
        }
        onClose={() => setEditedTemplate(null)}
      />
    );
  };

  return (
    <DashboardContentWrapper
      drillInContent={getDrillInContent()}
      searchPlaceholder="Search for Workouts"
      title={
        editedTemplate
          ? `Edit Workout ${editedTemplate.name}`
          : 'Workout Library'
      }
      setSearchQuery={setSearchQuery}
      actions={[
        {
          icon: <AddBoxOutlinedIcon />,
          label: 'Add A Workout',
          onClick: () => setIsAddTemplateModalOpen(true),
        },
      ]}
      onBack={() => setEditedTemplate(null)}
    >
      {renderContent()}
      {renderAddTemplateDialog()}
    </DashboardContentWrapper>
  );
};

export default TrainerTemplatesContent;
