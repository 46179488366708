import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import { LoginPageTitle } from './LoginPageTitle';
import { LoginPageDescription } from './LoginPageDescription';
import { LoginPageButton } from './LoginPageButton';
import styles from './LoginPageVerificationCode.module.scss';
import { type VerificationCodeResponse, verifyLoginCode } from '../../api';

export interface LoginPageVerificationCodeProps {
  onVerified: (verifyResponse: VerificationCodeResponse) => Promise<void>;
  email: string;
}

export const LoginPageVerificationCode: React.FC<
  LoginPageVerificationCodeProps
> = ({ email, onVerified }) => {
  const [verificationCode, setVerificationCode] = React.useState<string[]>([
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [verificationError, setVerificationError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleVerifyCode = async () => {
    const verificationCodeStr = verificationCode.join('');
    if (verificationCodeStr.length !== 6) {
      setVerificationError(true);
      return;
    }
    setLoading(true);
    const verifyResponse = await verifyLoginCode(email, verificationCodeStr);
    setLoading(false);
    if (verifyResponse.success) {
      onVerified(verifyResponse);
    } else {
      setVerificationError(true);
    }
  };

  useEffect(() => {
    const handleEnterKey = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleVerifyCode();
      }
    };

    document.addEventListener('keydown', handleEnterKey);
    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, [verificationCode]);

  const handleChange = (index: number, value: string) => {
    if (!value || value.length > 1) return;
    setVerificationError(false);

    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);

    if (index < 5) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    const pasteData = event.clipboardData.getData('text');
    if (pasteData.length !== 6 || !/^\d+$/.test(pasteData)) return;

    const newVerificationCode = pasteData.split('');
    setVerificationCode(newVerificationCode);

    inputRefs.current[5]?.focus();
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent) => {
    if (event.key === 'Backspace' && !verificationCode[index]) {
      setVerificationError(false);
      if (index > 0) {
        const prevInput = inputRefs.current[index - 1];
        if (prevInput) {
          const newVerificationCode = [...verificationCode];
          newVerificationCode[index - 1] = '';
          setVerificationCode(newVerificationCode);
          prevInput.focus();
        }
      }
    }
  };

  return (
    <Box sx={{ width: '100%', marginBottom: 2 }}>
      <LoginPageTitle text="Enter your Verification Code" />
      <LoginPageDescription text="We've sent you a 6 digit verification code to your email" />
      {verificationError && (
        <Typography color="error" sx={{ marginTop: 2 }}>
          Verification failed. Please try again.
        </Typography>
      )}
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}
        onPaste={handlePaste}
      >
        {verificationCode.map((code, index) => (
          <TextField
            key={index}
            inputRef={(el) => (inputRefs.current[index] = el)}
            value={code}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
            className={styles.verificationCodeInput}
            sx={{ marginRight: index < 5 ? 1 : 0 }}
          />
        ))}
      </Box>
      <LoginPageButton onClick={handleVerifyCode} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Verify Code'}
      </LoginPageButton>
    </Box>
  );
};
