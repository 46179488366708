import React, { useState } from 'react';
import { Button, DialogContent } from '@mui/material';
import styles from './AddWorkoutsDialog.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import SearchBar from '../atoms/SearchBar';
import MultiSelectList from '../composites/MultiSelectList';
import { calculateTemplateExercises } from '../../utils/dashboardUtils';
import { useGlobalData, useLoginData } from '../../providers';

import type { TrainingRoutineTemplate } from '../../types';

export interface AddWorkoutsDialogProps {
  onSubmit: (workouts: TrainingRoutineTemplate[]) => void;
  onClose: () => void;
  singleSelect?: boolean; // Added singleSelect prop
}

const AddWorkoutsDialog: React.FC<AddWorkoutsDialogProps> = ({
  onClose,
  onSubmit,
  singleSelect = false, // Default to false if not provided
}) => {
  const { templates: globalTemplates } = useGlobalData();
  const { trainerData } = useLoginData();
  const trainerTemplates = trainerData?.templates || [];
  const workouts = [...trainerTemplates, ...globalTemplates];
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedWorkouts, setSelectedWorkouts] = useState<
    TrainingRoutineTemplate[]
  >([]);

  const filteredWorkouts = workouts.filter(({ name }) =>
    name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <BaseDialog
      onClose={onClose}
      isOpen={true}
      title="Choose or Search for a Workout"
      actions={
        <Button
          className={styles.submit}
          onClick={() => onSubmit(selectedWorkouts)}
        >
          <span className={styles.submitText}>Add</span>
        </Button>
      }
    >
      <DialogContent className={styles.addWorkoutsDialog}>
        <SearchBar
          className={styles.search}
          placeholder="Search"
          setSearchQuery={setSearchQuery}
        />
        <MultiSelectList
          items={filteredWorkouts}
          selectedItems={selectedWorkouts}
          setSelectedItems={setSelectedWorkouts}
          singleSelect={singleSelect} // Pass singleSelect to MultiSelectList
          EmptyStateComponent={() => null}
          ItemComponent={({ data }) => (
            <div className={styles.templateMultiSelectItem}>
              <div className={styles.templateName}>{data.name}</div>
              <div className={styles.templateExercises}>
                {calculateTemplateExercises(data.routine)} Exercises
              </div>
            </div>
          )}
        />
      </DialogContent>
    </BaseDialog>
  );
};

export default AddWorkoutsDialog;
