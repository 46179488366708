import { jwtDecode } from 'jwt-decode';
import { apiBaseUrl } from '../apiConsts';
import { BaseApiResponse } from '../apiTypes';

export const sendVerificationCode = async (
  email: string,
): Promise<BaseApiResponse> => {
  try {
    if (!email) {
      throw new Error('No email provided');
    }
    const { status } = await fetch(`${apiBaseUrl}auth/send-code`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ email }),
    });
    if (status === 200) {
      return { success: true };
    }
    return { success: false };
  } catch (e) {
    const error = e as unknown as Error;
    console.error('error in sendVerificationCode:', error);
    return {
      success: false,
      errorMessage: error?.message || 'Something went wrong',
    };
  }
};

export interface VerificationCodeResponse extends BaseApiResponse {
  managerId?: string;
  trainerId?: string;
  memberId?: string;
  loginExpiration?: number;
}

enum LoginRole {
  MEMBER = 'ROLE_MEMBER',
  TRAINER = 'ROLE_TRAINER',
  MANAGER = 'ROLE_MANAGER',
}

interface LoginRoleData {
  role: LoginRole;
  id: string;
}

interface VerifyLoginJwtPayload {
  sub: string; //the email
  roles: LoginRoleData[];
  iat: number;
  exp: number;
}

export const verifyLoginCode = async (
  email: string,
  code: string,
): Promise<VerificationCodeResponse> => {
  try {
    if (!email || !code) {
      throw new Error('No email or code provided');
    }
    const { jwtToken } = await fetch(`${apiBaseUrl}auth/verify-code`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ email, code }),
    }).then((x) => x.json());
    const { roles, exp }: VerifyLoginJwtPayload = jwtDecode(jwtToken);
    const managerId = roles.find(({ role }) => role === LoginRole.MANAGER)?.id;
    const trainerId = roles.find(({ role }) => role === LoginRole.TRAINER)?.id;
    const memberId = roles.find(({ role }) => role === LoginRole.MEMBER)?.id;
    return {
      success: true,
      managerId,
      trainerId,
      memberId,
      loginExpiration: exp,
    };
  } catch (e) {
    console.error('error in sendVerificationCode:', e);
    return { success: false };
  }
};
