import React from 'react';
import styles from './IconWithText.module.scss';

interface IconWithTextProps {
  icon?: React.ReactNode;
  text?: string;
}

const IconWithText: React.FC<IconWithTextProps> = ({ text, icon }) => {
  return (
    <div className={styles.iconWithText}>
      {icon}
      {text && <span className={styles.text}>{text}</span>}
    </div>
  );
};

export default IconWithText;
