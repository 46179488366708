import React, { useMemo, useState } from 'react';
import userRowStyles from './UserRow.module.scss';
import UserRow from './UserRow';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ConnectWithoutContactRoundedIcon from '@mui/icons-material/ConnectWithoutContactRounded';
import {
  getFullName,
  isHighSchool as _isHighSchool,
  canImpersonate,
} from '../../utils';
import { Box, TextField, Typography, Button } from '@mui/material';
import { Email as EmailIcon } from '@mui/icons-material';
import AddClientDialog from './AddClientDialog';
import {
  createNewMember,
  updateMemberData,
  updateMemberAndSubscriptions,
  createNewProgram,
  assignProgramToMembers,
  fetchAllTrainers,
  fetchAllManagers,
  fetchAllMembers,
} from '../../api';
import EditClientDialog from './EditClientDialog';
import EmptyState from '../composites/EmptyState';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import ListContent from '../composites/ListContent';
import { Product, UserType } from '../../constants';
import {
  useLoginData,
  useRefreshState,
  useSelectedGymData,
} from '../../providers';
import BaseDialog from '../../components/DialogWrappers/BaseDialog';
import Programs from '../Programs/Programs';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  fetchAndSetStateData,
  setLoginStorageData,
} from '../../api/loginApiHelper';

import type { Member, Program, WithOptionalId } from '../../types';
import type { VerificationCodeResponse } from '../../api';

const getClientContentTitles = (isHighSchool: boolean): string[] => {
  return isHighSchool
    ? ['Athlete Name', 'Current Program', 'Trainer', 'Groups', 'Pin', 'Options']
    : [
        'Client Name',
        'Signup Date',
        'Current Program',
        'Trainer',
        'Subscription',
        'Pin',
        'Options',
      ];
};

interface ClientsContentDispatchProps {
  onLogin: (verifyResponse: VerificationCodeResponse) => Promise<void>;
}

const ClientsContentPure: React.FC<ClientsContentDispatchProps> = ({
  onLogin,
}) => {
  const { groups, members, trainers, gymProducts, selectedGymId } =
    useSelectedGymData();
  const { relatedGyms, trainerData } = useLoginData();
  const refreshState = useRefreshState();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const [isImpersonateModalOpen, setIsImpersonateModalOpen] = useState(false);
  const [isEditClientModalOpen, setIsEditClientModalOpen] = useState(false);
  const [editedMember, setEditedMember] = useState<Member | null>(null);
  const [editedProgram, setEditedProgram] =
    useState<WithOptionalId<Program> | null>(null);
  const [impersonateEmail, setImpersonateEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const filteredMembers = useMemo(() => {
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return members
      .filter((member) =>
        searchQueryRegex.test(getFullName(member).toLowerCase()),
      )
      .sort(
        (a, b) =>
          `${a.firstName}${a.lastName}`?.localeCompare(b.firstName || '') || -1,
      );
  }, [searchQuery, members]);

  const gym = relatedGyms.find(({ id }) => id === selectedGymId);
  if (!selectedGymId || !gym) {
    return (
      <EmptyState
        icon={<AccountCircleRoundedIcon />}
        text="No Clients Found For Gym"
      />
    );
  }

  const isHighSchool = _isHighSchool(gym);
  const userType: UserType = isHighSchool ? UserType.Athlete : UserType.Client;

  const handleAddClient = async (newMember: Member, trainerId?: string) => {
    const { success, errorMessage } = await createNewMember(
      newMember,
      gym,
      trainerId,
    );
    if (!success) {
      return { success, errorMessage };
    }
    await refreshState();
    setIsAddClientModalOpen(false);
    return { success: true };
  };

  const handleEditClient = async (
    oldMemberData: Member,
    newMemberData: Member,
    selectedProduct: Product | '',
  ) => {
    // Need to first update member data without subscriptions since it can harm subscription if didn't update (like email validation)
    const { success, errorMessage } = await updateMemberData(
      oldMemberData,
      newMemberData,
    );
    if (!success) {
      return { success, errorMessage };
    }
    const {
      success: subscriptionUpdateSuccess,
      errorMessage: subscriptionUpdateErrorMessage,
    } = await updateMemberAndSubscriptions(
      oldMemberData,
      newMemberData,
      selectedProduct,
      gymProducts,
    );
    if (!subscriptionUpdateSuccess) {
      return {
        success: subscriptionUpdateSuccess,
        errorMessage: subscriptionUpdateErrorMessage,
      };
    }
    await refreshState();
    setIsAddClientModalOpen(false);
    return { success: true };
  };

  const renderAddClientDialog = () => {
    return (
      <AddClientDialog
        userType={userType}
        gymMembers={members}
        trainers={trainers.map((trainer) => ({
          id: trainer.id,
          name: getFullName(trainer),
        }))}
        onSubmit={handleAddClient}
        isOpen={isAddClientModalOpen}
        onClose={() => setIsAddClientModalOpen(false)}
      />
    );
  };

  const renderEditClientDialog = () => {
    if (!editedMember || !isEditClientModalOpen) return null;
    return (
      <EditClientDialog
        userType={userType}
        trainers={trainers.map((trainer) => ({
          id: trainer.id,
          name: getFullName(trainer),
        }))}
        member={editedMember}
        onSubmit={(newMemberData, newSelectedProduct) =>
          handleEditClient(editedMember, newMemberData, newSelectedProduct)
        }
        isOpen={!!editedMember}
        onClose={() => {
          setIsEditClientModalOpen(false);
          setEditedMember(null);
        }}
      />
    );
  };

  const renderImpersonateDialog = () => {
    const renderImpersonateDialogContent = () => {
      const renderSubmitImpersonateButton = () => {
        return (
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                if (!impersonateEmail) {
                  setEmailError('Email is required');
                  return;
                }
                const allTrainers = await fetchAllTrainers();
                const trainer = allTrainers.find(
                  (m) =>
                    m.email?.toLowerCase() === impersonateEmail.toLowerCase(),
                );
                if (!trainer) {
                  setEmailError('Email not found');
                  return;
                }

                const verificationCodeResponse = {
                  trainerId: trainer.id,
                } as VerificationCodeResponse;

                const allManagers = await fetchAllManagers();
                const manager = allManagers.find(
                  (m) =>
                    m.email?.toLowerCase() === impersonateEmail.toLowerCase(),
                );
                if (manager) {
                  verificationCodeResponse.managerId = manager.id;
                }

                const allMembers = await fetchAllMembers();
                const member = allMembers.find(
                  (m) =>
                    m.email?.toLowerCase() === impersonateEmail.toLowerCase(),
                );
                if (member) {
                  verificationCodeResponse.memberId = member.id;
                }

                await onLogin(verificationCodeResponse);
                setIsImpersonateModalOpen(false);
              }}
            >
              Impersonate
            </Button>
          </Box>
        );
      };

      return (
        <div
          style={{
            padding: 20,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <EmailIcon sx={{ marginRight: 2 }} color="primary" />
            <Typography color="primary">Email Address</Typography>
          </Box>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            color="primary"
            placeholder="your@email.com"
            name="email"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '15px',
              },
            }}
            autoComplete="email"
            autoFocus
            value={impersonateEmail}
            onChange={(e) => {
              setImpersonateEmail(e.target.value);
              setEmailError('');
            }}
            error={Boolean(emailError)}
            helperText={emailError ? emailError : ''}
          />
          {renderSubmitImpersonateButton()}
        </div>
      );
    };

    return (
      <BaseDialog
        isOpen={isImpersonateModalOpen}
        onClose={() => setIsImpersonateModalOpen(false)}
        title="Impersonate"
        children={renderImpersonateDialogContent()}
        actions={<div />}
      />
    );
  };

  const handleEditMemberProgram = async (member: Member) => {
    setEditedMember(member);
    const currentProgram = member.program;
    if (!currentProgram?.id) {
      return;
    } else {
      setEditedProgram(currentProgram);
    }
  };

  const handleCreateNewProgram = async (member: Member) => {
    setEditedMember(member);
    setEditedProgram({
      id: undefined,
      name: '',
      trainerId: member.trainerIds[0],
      level: 0,
      fitnessGoal: '',
      programItems: [],
      isPrivate: true,
    });
  };

  const renderContent = () => {
    if (editedMember) return null;
    return (
      <ListContent
        items={filteredMembers}
        titles={getClientContentTitles(isHighSchool)}
        listHeaderClassName={
          isHighSchool
            ? userRowStyles.athleteListGrid
            : userRowStyles.clientListGrid
        }
        ItemComponent={({ data }) => (
          <UserRow
            groups={groups}
            userType={userType}
            archiveMember={async (member) => {
              await handleEditClient(
                member,
                { ...member, isActive: false },
                '',
              );
            }}
            openEditClientModal={() => {
              setEditedMember(data);
              setIsEditClientModalOpen(true);
            }}
            selected={selectedClient === data.id}
            onSelect={() => setSelectedClient(data.id)}
            key={data.id}
            member={data}
            trainer={trainers.find(({ id }) => data.trainerIds.includes(id))}
            onEditProgram={() => handleEditMemberProgram(data)}
            onCreateNewProgram={() => handleCreateNewProgram(data)}
          />
        )}
        EmptyStateComponent={() => (
          <EmptyState
            icon={<AccountCircleRoundedIcon />}
            cta={{
              icon: <AddBoxOutlinedIcon color="primary" />,
              label: `Add ${userType === UserType.Client ? `A Client` : `An Athlete`}`,
              onClick: () => setIsAddClientModalOpen(true),
            }}
            text="No Clients Have Been Added"
          />
        )}
      />
    );
  };

  const clearEditingProgram = () => {
    setEditedProgram(null);
    setEditedMember(null);
  };

  const handleSubmitEditedProgram = async (
    newProgramData: WithOptionalId<Program>,
  ) => {
    if (!editedMember || !editedProgram) return;
    const { success, errorMessage, content } = !newProgramData.id
      ? await createNewProgram(newProgramData, editedMember.trainerIds[0])
      : { success: true, content: newProgramData, errorMessage: '' };
    if (!success) {
      return { success, errorMessage };
    } else if (content.id) {
      if (!content.startDate) {
        content.startDate = new Date().toISOString();
      }
      await assignProgramToMembers([editedMember.id], content as Program);
      clearEditingProgram();
      await refreshState();
    }
  };

  const getDrillInContent = () => {
    if (!editedMember || !editedProgram) return null;
    return (
      <Programs
        isEditMode={true}
        program={editedProgram}
        onClose={() => clearEditingProgram()}
        onSubmit={handleSubmitEditedProgram}
        isContentWrapped={true}
      />
    );
  };

  const handleDashboardBack = () => {
    if (editedMember && editedProgram) {
      clearEditingProgram();
    } else if (editedMember) {
      clearEditingProgram();
    }
  };

  const getImpersonateAction = () => {
    return {
      icon: <ConnectWithoutContactRoundedIcon />,
      label: `Impersonate`,
      onClick: () => setIsImpersonateModalOpen(true),
    };
  };

  const getDashboardContentActions = () => {
    const actions = [
      {
        icon: <AccountCircleRoundedIcon />,
        label: `Add ${userType === UserType.Client ? `A Client` : `An Athlete`}`,
        onClick: () => setIsAddClientModalOpen(true),
      },
    ];

    if (canImpersonate(trainerData?.trainer?.id)) {
      actions.push(getImpersonateAction());
    }
    return actions;
  };

  return (
    <DashboardContentWrapper
      drillInContent={getDrillInContent()}
      onBack={() => handleDashboardBack()}
      searchPlaceholder={`Search for ${userType}s`}
      isEditMode={!!editedMember && !!editedProgram}
      title={
        editedProgram
          ? `Edit Program ${editedMember?.program?.name}`
          : `${userType} List`
      }
      setSearchQuery={setSearchQuery}
      actions={getDashboardContentActions()}
    >
      {renderContent()}
      {renderAddClientDialog()}
      {renderEditClientDialog()}
      {renderImpersonateDialog()}
    </DashboardContentWrapper>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
): ClientsContentDispatchProps => ({
  onLogin: async ({
    memberId,
    managerId,
    trainerId,
    loginExpiration,
  }: VerificationCodeResponse) => {
    const loginData = { managerId, trainerId, memberId, loginExpiration };
    setLoginStorageData(loginData);
    await fetchAndSetStateData(
      dispatch,
      loginData.managerId,
      loginData.trainerId,
      loginData.memberId,
    );
  },
});

const ClientsContent = connect(null, mapDispatchToProps)(ClientsContentPure);

export default ClientsContent;
