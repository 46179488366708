import { apiBaseUrl } from '../apiConsts';
import { getDataWithRetry } from '../fetchUtils';
import { isValidTemplateResponse } from '../apiValidators';

import type { BaseApiResponse } from '../apiTypes';
import type { TrainingRoutineTemplate, WithOptionalId } from '../../types';

export const createNewTemplate = async (
  template: WithOptionalId<TrainingRoutineTemplate>,
  trainerId: string,
): Promise<BaseApiResponse> => {
  try {
    if (!trainerId) {
      throw new Error('Trainer id must be provided');
    }
    const createdTemplate = await fetch(
      `${apiBaseUrl}training-routine-templates`,
      {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(template),
      },
    ).then((x) => x.json());
    await fetch(
      `${apiBaseUrl}trainers/${trainerId}/associate/training-routine-template`,
      {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify([createdTemplate.id]),
      },
    );
    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    const errorMessage = `Error in createNewTemplate: ${e?.message}`;
    console.error(errorMessage);
    return { success: false, errorMessage };
  }
};

export const updateTemplateData = async (
  template: TrainingRoutineTemplate,
): Promise<BaseApiResponse> => {
  try {
    const response = await fetch(`${apiBaseUrl}training-routine-templates`, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(template),
    });
    if (response.status !== 200) {
      throw new Error(`Server response: ${response.status}`);
    }
    return { success: true };
  } catch (error) {
    const e = error as unknown as Error;
    const errorMessage = `Error in updateTemplateData: ${e?.message}`;
    console.error(errorMessage);
    return { success: false, errorMessage };
  }
};

export const removeTemplate = async (
  templateId: string,
): Promise<BaseApiResponse> => {
  try {
    const response = await fetch(
      `${apiBaseUrl}training-routine-templates/${templateId}`,
      {
        method: 'DELETE',
      },
    );
    if (response.status !== 200) {
      throw new Error(`Server response: ${response.status}`);
    }
    return { success: true };
  } catch (error) {
    console.error('Error in removeTemplate:', error);
    return { success: false };
  }
};

export const fetchTrainerTemplates = async (
  trainerId: string,
): Promise<TrainingRoutineTemplate[]> => {
  try {
    const { content } = await getDataWithRetry(
      `${apiBaseUrl}training-routine-templates/trainer/${trainerId}`,
      {},
      isValidTemplateResponse,
    );
    return content;
  } catch (error) {
    console.error('Error in fetchTrainerTemplates:', error);
    return [];
  }
};
