import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import InputField from '../atoms/InputField';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import styles from './ClientDataDialogCommon.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import { generateNewPin, getNewMember, isValidEmail } from '../../utils/utils';
import {
  createDashboardSubscription,
  getSubscriptionOptions,
} from '../../utils/dashboardUtils';
import { Product, UserType } from '../../constants';
import { useLoginData } from '../../providers';

import type { Member } from '../../types';

export interface AddClientDialogProps {
  gymMembers: Member[];
  onClose: () => void;
  onSubmit: (
    newMember: Member,
    trainerId?: string,
  ) => Promise<{ success: boolean; errorMessage?: string }>;
  isOpen: boolean;
  trainers: { id: string; name: string }[];
  userType: UserType;
}

const getInitialClientData = (userType: UserType, trainerId: string) => ({
  firstName: '',
  lastName: '',
  email: '',
  emailVerification: '',
  phoneNumber: '',
  trainerId,
  subscription: userType === UserType.Athlete ? Product.Programmed : '',
});

const AddClientDialog: React.FC<AddClientDialogProps> = ({
  gymMembers,
  isOpen,
  onSubmit,
  onClose,
  trainers,
  userType,
}) => {
  const { trainerData } = useLoginData();
  const trainer = trainerData?.trainer;

  const [clientData, setClientData] = useState(
    getInitialClientData(userType, trainer?.id || ''),
  );

  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailVerificationError, setEmailVerificationError] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setClientData(getInitialClientData(userType, clientData.trainerId));
      setFirstNameError(false);
      setLastNameError(false);
      setPhoneNumberError(false);
      setEmailError(false);
      setEmailVerificationError(false);
      setLoading(false);
      setErrorMessage(null);
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    setErrorMessage(null);
    if (!clientData.firstName) return setFirstNameError(true);
    if (!clientData.lastName) return setLastNameError(true);
    if (!isValidEmail(clientData.email)) return setEmailError(true);
    // if (!clientData.phoneNumber) return setPhoneNumberError(true);
    if (clientData.email !== clientData.emailVerification)
      return setEmailVerificationError(true);
    setEmailVerificationError(false);

    setLoading(true);

    const newMember = getNewMember({
      phoneNumber: clientData.phoneNumber,
      email: clientData.email,
      pin: generateNewPin(gymMembers),
      firstName: clientData.firstName,
      lastName: clientData.lastName,
      subscriptions: clientData.subscription
        ? [createDashboardSubscription(clientData.subscription as Product)]
        : [],
    });

    try {
      const { success, errorMessage } = await onSubmit(
        newMember,
        clientData.trainerId,
      );

      if (success) {
        setClientData(getInitialClientData(userType, clientData.trainerId));
        onClose();
      } else {
        setErrorMessage(errorMessage || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BaseDialog
        onClose={onClose}
        isOpen={isOpen}
        title={`Add ${userType === UserType.Client ? `A Client` : `An Athlete`}`}
        actions={
          <Button
            className={styles.submit}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              <>
                <AddBoxOutlinedIcon color="primary" />
                <span className={styles.submitText}>{`Add ${userType}`}</span>
              </>
            )}
          </Button>
        }
      >
        <DialogContent className={styles.clientDataDialog}>
          <div className={styles.row}>
            <div className={styles.rowColumn}>
              <InputField
                icon={<PersonOutlineOutlinedIcon color="primary" />}
                title="First Name"
                inputName="firstName"
                autoComplete="firstName"
                value={clientData.firstName}
                onChange={(firstName) => {
                  setFirstNameError(false);
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    firstName,
                  }));
                }}
                error={firstNameError}
              />
            </div>
            <div className={styles.rowColumn}>
              <InputField
                icon={<PersonOutlineOutlinedIcon color="primary" />}
                title="Last Name"
                inputName="lastName"
                autoComplete="lastName"
                value={clientData.lastName}
                onChange={(lastName) => {
                  setLastNameError(false);
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    lastName,
                  }));
                }}
                error={lastNameError}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowColumn}>
              <InputField
                icon={<MailOutlineRoundedIcon color="primary" />}
                title="Email"
                inputName="email"
                autoComplete="email"
                value={clientData.email}
                onChange={(email) => {
                  setEmailError(false);
                  setEmailVerificationError(false);
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    email,
                  }));
                }}
                onBlur={() => setEmailError(!isValidEmail(clientData.email))}
                error={emailError}
              />
            </div>
            <div className={styles.rowColumn}>
              <InputField
                icon={<MailOutlineRoundedIcon color="primary" />}
                title="Email Verification"
                inputName="emailVerification"
                autoComplete="email"
                value={clientData.emailVerification}
                onChange={(emailVerification) => {
                  setEmailVerificationError(false);
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    emailVerification,
                  }));
                }}
                onBlur={() =>
                  setEmailVerificationError(
                    clientData.email !== clientData.emailVerification,
                  )
                }
                error={emailVerificationError}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowColumn}>
              <InputField
                icon={<LocalPhoneOutlinedIcon color="primary" />}
                title="Phone Number"
                inputName="phoneNumber"
                autoComplete="phone"
                value={clientData.phoneNumber}
                onChange={(phoneNumber) => {
                  setPhoneNumberError(false);
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    phoneNumber,
                  }));
                }}
                error={phoneNumberError}
              />
            </div>
            <div className={styles.rowColumn}>
              <InputField
                icon={<PersonOutlineOutlinedIcon color="primary" />}
                title="Trainer"
                inputName="trainerId"
                value={clientData.trainerId}
                options={trainers.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                onChange={(trainerId) => {
                  setClientData((prevClientData) => ({
                    ...prevClientData,
                    trainerId,
                  }));
                }}
              />
            </div>
          </div>
          {userType !== UserType.Athlete && (
            <div className={styles.row}>
              <div className={styles.rowColumn}>
                <InputField
                  icon={<SupervisorAccountOutlinedIcon color="primary" />}
                  title="Subscription"
                  inputName="subscription"
                  value={clientData.subscription}
                  options={getSubscriptionOptions()}
                  onChange={(subscription) => {
                    setClientData((prevClientData) => ({
                      ...prevClientData,
                      subscription,
                    }));
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </BaseDialog>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
        >
          <Alert onClose={() => setErrorMessage(null)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AddClientDialog;
