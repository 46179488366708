import React, { useState } from 'react';
import styles from './ProgramRow.module.scss';
import classNames from 'classnames';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ContentCopy from '@mui/icons-material/ContentCopy';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Menu from '../atoms/Menu';
import {
  calculateProgramWeeks,
  getFullName,
  getProgramImage,
} from '../../utils/dashboardUtils';
import ConfirmationDialog from '../DialogWrappers/ConfirmationDialog';
import { useGlobalData, useSelectedGymData } from '../../providers';

import type { Program } from '../../types';

interface ProgramRowProps {
  program: Program;
  selected: boolean;
  onSelect?: () => void;
  openEditProgramModal?: () => void;
  duplicateProgram?: (program: Program) => void;
  deleteProgram?: (program: Program) => Promise<void>;
  minimalDetails?: boolean;
}

const ProgramRow: React.FC<ProgramRowProps> = ({
  program,
  onSelect,
  selected,
  deleteProgram,
  minimalDetails,
  openEditProgramModal,
  duplicateProgram,
}) => {
  const { trainers, members } = useSelectedGymData();
  const { mergedExercises } = useGlobalData();
  const [isDeleteProgramConfirmationOpen, setIsDeleteProgramConfirmationOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const renderProgramNameAndImage = () => (
    <div
      className={classNames(styles.programColumn, styles.programNameAndImage)}
    >
      <div
        className={styles.programImage}
        style={{
          backgroundImage: `url(${getProgramImage(program, mergedExercises)})`,
        }}
      />
      <div className={styles.programName}>{program.name}</div>
    </div>
  );

  const renderTrainerName = () => {
    const trainer = trainers.find(({ id }) => id === program.trainerId);
    const trainerName = getFullName(trainer);
    return (
      <div className={classNames(styles.programColumn)}>{trainerName}</div>
    );
  };

  const renderClients = () => {
    if (minimalDetails) return null;
    const clientsUsingProgram =
      members?.filter((member) => member.program?.id === program.id).length ||
      0;
    let clientsStr = `${clientsUsingProgram} Clients`;
    if (clientsUsingProgram === 0) clientsStr = '';
    if (clientsUsingProgram === 1) clientsStr = `${clientsUsingProgram} Client`;
    return <div className={classNames(styles.programColumn)}>{clientsStr}</div>;
  };

  const renderWeeks = () => {
    return (
      <div className={classNames(styles.programColumn)}>
        {`${calculateProgramWeeks(program)} ${minimalDetails ? 'Weeks' : ''}`}
      </div>
    );
  };

  const handleEditProgram = () => {
    if (!openEditProgramModal) return;
    openEditProgramModal();
    handleCloseMenuOptions();
  };

  const handleDuplicateProgram = () => {
    if (!duplicateProgram) return;
    duplicateProgram(program);
    handleCloseMenuOptions();
  };

  const handleDeleteProgram = async () => {
    if (!deleteProgram) return;
    handleCloseMenuOptions();
    await deleteProgram(program);
    setIsDeleteProgramConfirmationOpen(false);
  };

  const renderMoreOptions = () => {
    if (minimalDetails || !openEditProgramModal || !deleteProgram) return null;

    const menuOptions = [
      {
        onClick: handleEditProgram,
        label: 'Edit Program Details',
        icon: <BorderColorOutlinedIcon color="primary" />,
      },
      {
        onClick: handleDuplicateProgram,
        label: 'Duplicate Program',
        icon: <ContentCopy color="primary" />,
      },
      {
        onClick: () => setIsDeleteProgramConfirmationOpen(true),
        label: 'Delete Program',
        icon: <DeleteForeverOutlinedIcon color="primary" />,
      },
    ];
    return (
      <div className={classNames(styles.programColumn, styles.options)}>
        <PendingOutlinedIcon
          // @ts-ignore
          onClick={(event) => {
            event.stopPropagation();
            return setAnchorEl(event.currentTarget as any);
          }}
          className={styles.optionsBtn}
          color="primary"
        />
        <Menu
          anchorEl={anchorEl}
          isOpen={open}
          onClose={handleCloseMenuOptions}
          options={menuOptions}
        />
      </div>
    );
  };

  const renderDeleteProgramConfirmationDialog = () => (
    <ConfirmationDialog
      text="Are you sure you want to delete this program?"
      onSubmit={handleDeleteProgram}
      submitText="Yes"
      cancelText="No"
      isOpen={isDeleteProgramConfirmationOpen}
      onCancel={() => setIsDeleteProgramConfirmationOpen(false)}
      symbol={<DeleteForeverOutlinedIcon />}
    />
  );

  return (
    <div
      onClick={handleEditProgram}
      className={classNames(styles.programRow, styles.listGrid, {
        [styles.selected]: selected,
        [styles.minimalList]: minimalDetails,
      })}
    >
      {renderProgramNameAndImage()}
      {renderTrainerName()}
      {renderClients()}
      {renderWeeks()}
      {renderMoreOptions()}
      {renderDeleteProgramConfirmationDialog()}
    </div>
  );
};

export default ProgramRow;
